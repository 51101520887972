.cart-item {
  $p: &;
  padding: 30px 0;
  @media screen and (max-width: 1023px) {
    padding: 20px 0 !important;
  }
  border-bottom: 1px solid $color-dark-gray;
  &:last-child {
    border-bottom: 1px solid $color-dark-gray;
  }
  &__item-of {
    padding: 20px 31px 10px 0;
    color: $slate;
    font-family: $font-regular;
    font-size: 14px;
    letter-spacing: -0.45px;
    line-height: 22px;
  }

  .child-items {
    &__wrapper {
      display: grid;
      grid-template-rows: 0fr;
      transition: all 0.5s ease-out;

      &.open {
        grid-template-rows: 1fr;
        margin-top: 30px;
      }

      #{$p}__child:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }
    }

    &__inner {
      overflow: hidden;
    }
  }

  &__child {
    padding: 15px 0 30px 0;
    border-top: 1px solid $color-light-gray;
    border-bottom: none;

    #{$p}__image {
      width: 160px;
      height: 160px;
    }

    .cart-blank-section {
      width: 160px;
    }
  }
  &__summary {
    display: flex;
  }

  .img-wrapper {
    display: inline-block;
    background: $half-white;
    margin-right: 20px;

    @media screen and (max-width: 768px) {
      margin-right: 30px;
    }
  }

  &__image {
    width: 237px;
    height: 237px;
    object-fit: contain;
    cursor: auto;
    mix-blend-mode: multiply;

    @media screen and (max-width: 768px) {
      width: 61px;
      height: 81px;
    }
  }
  &__brand {
    margin-bottom: 10px;
    @media screen and (max-width: 767px) {
      margin-bottom: 13px;
    }

    &:empty {
      margin-bottom: 0;
    }
  }
  &__brand-name {
    font-family: $font-medium;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.35px;
    color: $color-dark-gray;
    margin-right: 30px;
  }
  &__sku {
    font-family: $font-regular;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.45px;
    color: $color-slate;
  }
  &__mrp {
    font-family: $font-regular;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.4px;
    color: $color-dark-gray;
  }
  &__bundle-items {
    color: $color-slate;
    font-feature-settings: "clig" off, "liga" off;
    font-family: $font-regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.45px;
    margin-top: 15px;
    margin-bottom: 5px;

    button {
      background: none;
      border: none;
      outline: none;
      text-decoration: underline;
    }

    &.preview-tile {
      margin: 0;
    }
  }
  &__bundle-badge {
    display: inline-block;
    margin-top: 15px;
    padding: 5px 8px;
    background-color: $color-extra-light-gray;
    color: #000;
    font-feature-settings: "clig" off, "liga" off;
    font-family: $font-regular;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 10px;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    &.preview-tile {
      margin-top: 10px;
      margin-bottom: 15px;
    }
  }
  &__email {
    padding-top: 25px;
    color: $color-dark-gray;
    font-size: 14px;
    line-height: 1.5;
  }
  @media screen and (max-width: 767px) {
    &__sku {
      &.cart-item-bundle__sku {
        float: none;
      }
    }
    &__span {
      margin-right: 0;
    }
    &__specs {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  &__name {
    font-family: $font-light;
    font-size: 22px;
    line-height: 1.45;
    letter-spacing: -1.2px;
    color: $color-slate;
  }
  &__specs {
    width: 100%;
  }
  &__qty_label {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.45px;
    color: $slate;
    font-family: $font-regular;
    margin: 5px 0 0 0;
  }
  &__desc {
    font-family: $font-regular;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.55px;
    color: $color-slate;
    margin-top: 5px;
    @media screen and (max-width: 767px) {
      margin-top: 5px;
    }
    margin-bottom: 0;

    &:empty {
      margin-top: 0;
    }
  }
  &__color {
    font-family: $font-regular;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.45px;
    color: $slate;
    margin: 15px 0 0;
  }
  &__cta {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 100%;
    span {
      cursor: pointer;
      text-decoration: underline;
      font-family: $font-regular;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.45px;
      color: $charcoal;
      margin-left: 25px;
      &:first-child {
        margin-left: 0;
      }
      @media screen and (max-width: 768px) {
        margin-left: 90px;
      }
    }
    &.--loading {
      div {
        cursor: not-allowed;
      }
      span {
        pointer-events: none;
      }
    }
  }
  &__note {
    font-family: $font-regular;
    font-size: 14px;
    line-height: 1.57;
    letter-spacing: -0.65px;
    color: $color-dark-gray;
    margin-left: 160px;
    margin-bottom: 0;
    &.--mobile {
      display: none;
      @media screen and (max-width: 768px) {
        display: block;
        margin-top: 20px;
        margin-left: 0;
      }
    }
    &.--e-delivery {
      margin-left: 0;
      margin-top: 20px;
    }
  }
  &__quantity-price {
    display: block;
    @media screen and (max-width: 480px) {
      margin-top: 24px;
      display: flex;
      justify-content: space-between;
      font-family: $font-regular;
      font-size: 16px;
      line-height: 24px;
    }
  }
  &__alert {
    font-family: $font-regular;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.55px;
    color: #ffffff;
    background: $color-dark-gray;
    padding: 20px 25px;
    border-radius: 5px;
    margin: 30px 0px 0px 160px;
    @media screen and (max-width: 768px) {
      margin-left: 0px;
    }
  }
  .show-mobile {
    display: none;
    @media screen and (max-width: 767px) {
      display: block;
    }
  }
  &__qty-mobile {
    display: none;
    @media screen and (max-width: 767px) {
      display: block;
      width: 61px;
      margin-right: 30px;
      float: left;
    }
  }
  &__qty-price {
    margin-bottom: 30px;
  }
  &__qty {
    text-align: center;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 767px) {
      display: block;
      float: left;
      width: auto !important;
      margin-left: 90px;
      &.-mt-24 {
        margin-top: -24px;
      }
    }

    .quantity-selector.small {
      max-width: 100px;
      @media screen and (max-width: 767px) {
        margin-top: -10px;
      }
    }
    .quantity-selector__count {
      width: 40%;
    }
  }
  &__price {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.055px;
    color: $color-charcoal;
  }
  &__display-discount-cart {
    margin: 10px 0 0 0;
  }
  &__unit-price {
    display: block;
  }

  &__unit-price {
    font-family: $font-regular;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.8px;
    color: $color-charcoal;
  }

  &__total-price,
  &__span {
    font-family: $font-regular;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.55px;
    color: $color-charcoal;
  }
  &__total-price {
    font-family: $font-medium;
  }
  &__span {
    color: $dark-gray;
    margin-left: 4px;
    text-decoration: line-through;
  }
  &__display-discount {
    @media screen and (max-width: 480px) {
      display: grid;
    }
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 6px;
    margin-top: 5px;

    &.--mobile {
      display: none;
      @media screen and (max-width: 480px) {
        display: flex;
        margin-bottom: 20px;
      }
    }
    .cart-item__span {
      text-decoration: line-through;
      font-family: $font-regular;
      font-size: 16px;
      line-height: 1.38;
      letter-spacing: -0.8px;
      color: $color-dark-gray;
    }
  }
  &__discount-price {
    text-decoration: line-through;
    font-family: $font-regular;
    font-size: 16px;
    line-height: 1.38;
    letter-spacing: -0.8px;
    color: $color-dark-gray;
    @media screen and (max-width: 767px) {
      text-align: right;
      text-transform: lowercase;
    }
    &.--offPercent,
    &.--offPercentCart {
      text-decoration: none;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.48px;
      color: $color-slate;
      font-family: $font-regular;
      margin-right: 0;
    }

    &.--offPercentage {
      text-decoration: none;
      font-size: 12px;
      color: $color-slate;
      font-family: $font-medium;
      margin-right: 0;
    }

    &.--mobile {
      font-size: 14px;
    }
  }
  &__display-discount-order {
    @media screen and (max-width: 480px) {
      display: flex;
    }
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 6px;
    margin-top: 5px;

    &.--mobile {
      display: none;
      @media screen and (max-width: 480px) {
        display: flex;
        margin-bottom: 20px;
      }
    }
    .cart-item__span {
      text-decoration: line-through;
      font-family: $font-regular;
      font-size: 16px;
      line-height: 1.38;
      letter-spacing: -0.8px;
      color: $color-dark-gray;
    }
  }

  &__stock-msg {
    font-family: $helvetica-medium;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.3px;
    color: $color-charcoal;
    margin-top: 0px;
    @media screen and (max-width: 767px) {
      margin-bottom: 24px;
    }
    &.--error {
      display: flex;
      justify-content: flex-end;
      color: $color-error-red;
      @media screen and (max-width: 767px) {
        padding: 0;
        justify-content: flex-start;
      }
    }
    img {
      width: 20px;
      height: 20px;
      cursor: pointer;
      margin-right: 10px;
    }
    .cartItemTooltip {
      z-index: 9;
      position: absolute;
      top: -60px;
      text-align: left;
      @media screen and (max-width: 1023px) {
        top: -80px;
        .tooltip__container {
          width: 160px;
        }
      }
    }
  }
  &__label {
    font-family: $font-regular;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.55px;
    color: $color-dark-gray;
    margin-bottom: 20px;
    &.--mobile {
      display: none;
      margin-bottom: 0;
      font-size: 14px;
      @media screen and (max-width: 767px) and (orientation: portrait) {
        display: inline-flex;
      }
    }
    &.--quantity {
      display: block;
      font-size: 20px;
      margin-left: 25px;
      @media screen and (max-width: 480px) {
        //margin-left: 44px;
        font-size: 16px !important;
        text-align: left;
      }
      .--desktop {
        display: none;
        @media screen and (min-width: 768px) {
          display: inline;
        }
      }
      @media screen and (min-width: 768px) {
        display: inline;
        text-align: center;
      }
    }
    &.--sum {
      display: none;
      margin-bottom: 0;
      @media screen and (max-width: 480px) {
        display: inline;
        font-family: $font-bold;
        color: $color-charcoal;
      }
    }
    &.--count {
      margin-bottom: 0;
    }
  }
  &__info {
    position: relative;
  }
  &__radio-group {
    position: absolute;
    right: 0;
    bottom: 0;
    @media screen and (max-width: 768px) {
      margin-top: 30px;
      position: static;
      display: flex;
      flex-direction: row-reverse;
    }
    @media screen and (max-width: 480px) {
      margin-top: 10px !important;
    }
  }
  &__links {
    text-align: right;
  }
  &__link {
    margin-bottom: 10px;
    @media print {
      display: none;
    }
    &:last-child {
      margin-bottom: 0;
    }
    a,
    span {
      cursor: pointer;
      text-decoration: underline;
      color: $color-slate;
      font-size: 14px;
      letter-spacing: -0.45px;
      line-height: 22px;
      font-family: $font-regular;
    }
  }
}

.combo-cart-items {
  &__note {
    padding-bottom: 10px;
    display: block;
    border-bottom: 1px solid $light-gray;
    color: $color-charcoal;
    font-family: $font-medium;
    font-size: 14px;
    letter-spacing: -0.45px;
    line-height: 22px;
  }
  .cart-item {
    padding-top: 0;
    border-top: none;
    &__image {
      width: 90px;
      height: 120px;
    }
  }
  @media only screen and (max-width: 767px) {
    &__note {
      letter-spacing: -0.3px;
    }
    .cart-item {
      &__image {
        width: 60px;
        height: 80px;
      }
      &__qty-price {
        margin-bottom: 0 !important;
      }
      &__desc {
        color: $color-slate;
      }
    }
  }
}

.cart-item__shimmer {
  display: flex;
  column-gap: 40px;
  margin-top: 50px;
  .cart-item__image-shimmer {
    width: 120px;
    height: 160px;
    @media only screen and (max-width: 991px) {
      width: 60px;
      height: 90px;
    }
  }
  .cart-item__details-shimmer {
    width: 100%;
    p:last-child {
      width: 50%;
    }
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1120px) {
  .cart-item {
    &__image {
      width: 90px;
      height: 120px;
      margin-right: 10px;
    }
    &__note {
      margin-left: 100px;
    }
  }
  .cart-blank-section {
    width: 90px !important;
    margin-right: 10px !important;
  }
  .cart-item__cta-wrapper {
    margin: 10px 0 0 0;
  }
}

@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  .cart-item {
    &__image {
      width: 90px;
      height: 120px;
      margin-right: 10px;
    }
    &__note {
      margin-left: 100px;
    }
  }
  .cart-blank-section {
    width: 90px !important;
    margin-right: 10px !important;
  }
}

@page {
  margin: 2cm;
}

@media print {
  .cart-item {
    .print-left,
    .print-right {
      float: left;
      width: 50%;
    }
  }
}
.cart-blank-section {
  width: 237px;
  margin-right: 20px;
  visibility: visible;
  float: left;
}
.cart-stock-msg {
  float: left;
}
.remove-sec-cart {
  float: right;
}
.cart-stock-message-mobile {
  display: none;
  @media screen and (max-width: 767px) {
    display: block;
    margin-bottom: 5px;
  }
}
.mobile-total-price,
.car-total-qty-mobile {
  display: none;
}
@media screen and (max-width: 767px) {
  .car-total-qty-desktop {
    display: none;
  }
  .car-total-qty-mobile {
    display: block;
    float: right;
    text-align: right;
    width: 100%;
  }
  .mobile-full-width,
  .cart-stock-message-mobile,
  .cart-item__info {
    width: 100% !important;
    max-width: 100% !important;
    flex: 100% !important;
  }
  .desktop-cart-stock-msg,
  .hide-mobile-price {
    display: none;
  }
  .cart-blank-section {
    width: 90px;
    margin-right: 10px;
  }
  .mobile-total-price {
    display: block;
    width: 100% !important;
  }
  .checkout-cart-section-lists,
  .checkout-cart-section {
    .cart-item {
      border-top: unset;
      @media only screen and (max-width: 767px) {
        padding: 25px 0 20px 0 !important;
        &__quantity-price,
        .hide-mobile-checkout-section {
          display: block !important;
        }
        &__total {
          display: inline-block;
          width: auto;
          &-price {
            &.hide-mobile-price {
              display: block;
            }
          }
        }
        &__brand-name {
          margin-right: 0;
        }
        &__desc {
          margin-top: 15px;
        }
        &__specs {
          margin-bottom: 10px;
        }
        &__label {
          line-height: 22px;
          letter-spacing: -0.055px;
          color: $charcoal;
        }
      }
      &__image,
      &__qty-mobile {
        margin-right: 15px;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .ml-full-width {
    max-width: 100% !important;
    flex: 100% !important;
  }
  .cart-blank-section {
    width: 120px;
    margin-right: 40px;
  }
  .cart-item__cta-wrapper {
    margin: 20px 0 0 0;
    @media screen and (max-width: 767px) {
      margin: 0;
    }
  }
}
@media screen and (max-width: 768px) {
  .cart-blank-section {
    width: 61px;
    margin-right: 30px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1600px) {
  .cart-item__cta-wrapper {
    margin: 10px 0 0 0;
  }
}

.order-confirmation,
.checkout-cart-section {
  .combo-cart-items {
    padding: 0 0 30px 160px;
    @media screen and (max-width: 767px) {
      padding: 0 0 30px 76px;
      &__qty-mobile {
        display: block;
      }
    }
    @media only screen and (min-width: 1024px) and (max-width: 1120px) {
      padding: 0 0 30px 100px;
    }
    border-bottom: 1px solid $light-gray;
    .cart-item {
      padding: 0 !important;
      &__info {
        display: none;
      }
      .cart-blank-section {
        width: 90px;
      }
    }
  }
}
.combo-sku-with-cart {
  .cart-item {
    border: unset;
    padding-bottom: 4px !important;
  }
}
.kf-react-container {
  .combo-sku-with-cart {
    .cart-item {
      @media screen and (max-width: 767px) {
        &__info {
          display: none !important;
        }
      }
      padding-bottom: 0px !important;
    }
  }
}
.combo-total-product-qty {
  display: none;
  @media screen and (max-width: 767px) {
    display: flex;
    justify-content: space-between;
    .cart-item__label {
      letter-spacing: -0.8px !important;
      margin-bottom: 0;
      color: $charcoal !important;
    }
  }
}
.cart-item__span {
  overflow-wrap: break-word;
}
.car-total-qty-desktop {
  display: inline-block;
  @media screen and (max-width: 767px) {
    display: none;
  }
}
.cart-item-qty-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: $color-dark-gray;
  @media screen and (max-width: 767px) {
    display: none;
  }
}
.cart-item__qty-price {
  @media screen and (min-width: 768px) and (max-width: 1100px) {
    display: flex;
    justify-content: space-between;
  }
  @media screen and (min-width: 1101px) and (max-width: 1440px) {
    display: flex;
    justify-content: space-between;
  }
}
.cart-item__image {
  @media screen and (min-width: 768px) and (max-width: 1100px) {
    width: 150px;
    height: 150px;
  }
}
