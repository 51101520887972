.quantity-selector {
  border-radius: 5px;
  border: 1px solid $color-dark-gray;
  text-align: center;
  display: flex;
  height: 60px;

  button {
    border: none;
    background: transparent;
    width: 30%;
    flex: 1;
    padding: 0px;
    &:disabled {
      .plus {
        .line {
          background-color: $color-dark-gray;
        }
      }
    }
  }

  &.read-only {
    button {
      visibility: hidden;
    }
  }

  /* Hide arrows for number field */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }

  &__count {
    font-family: $font-light;
    width: 30%;
    text-align: center;
    border: none;
    font-size: 26px;
    line-height: 1;
    letter-spacing: -1px;
    padding: 0px;
    color: $color-charcoal;
    &:disabled {
      cursor: not-allowed;
      background-color: transparent;
    }
  }

  .plus {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    .line {
      background: $color-dark-gray;
      &.line-1 {
        width: 100%;
        height: 1px;
      }
      &.line-2 {
        width: 1px;
        height: 100%;
        transform: translateX(-10px);
      }
    }
  }
  &.small {
    height: 40px;
    min-width: 90px;
    .quantity-selector__count {
      font-family: $font-regular;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: -0.7px;
      text-align: center;
      color: $color-slate;
    }
    .plus {
      width: 10px;
      height: 10px;
      .line {
        &.line-2 {
          transform: translateX(-5px);
        }
      }
    }
  }
}
