.address-tile {
  cursor: pointer;
  position: relative;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid $dark-gray;
  background-color: $color-white;
  @media screen and (max-width: 767px) {
    padding: 20px;
  }
  &__header {
    label {
      margin: 0;
    }
  }
  &__label {
    display: inline-block;
    font-family: $font-medium;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.35px;
    color: $color-charcoal;
    text-transform: capitalize;
    @media screen and (max-width: 1023px) {
      letter-spacing: -0.0344rem;
    }
    @media screen and (max-width: 767px) {
      letter-spacing: -0.35px;
    }
  }
  .payment-address-name {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    width: 100%;
  }
  .payment-address-info-section {
    display: flex;
  }
  &.--new {
    .instruction,
    .shipping-form__required-fields {
      display: none;
    }
    .shipping-form__set-default-check label,
    .shipping-form__label-input {
      margin: 0 !important;
    }
  }
  .radio__custom {
    display: flex;
  }
  &__text {
    font-family: $font-regular;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: -0.0344rem;
    color: $slate;
  }

  &__cta {
    cursor: pointer;
    font-family: $font-medium;
    font-size: 14px;
    letter-spacing: -0.3px;
    color: $color-slate;
    float: right;
    border-bottom: 1px solid $color-slate;
    padding-bottom: 2px;
    line-height: 22px;
  }

  &__name {
    margin-bottom: 10px;
  }

  &__address {
    max-width: 85%;
  }

  &__default {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
  &__icon {
    cursor: pointer;
    display: inline-block;
    .plus {
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      .line {
        background: $color-charcoal;
        &.line-1 {
          width: 100%;
          height: 1px;
        }
        &.line-2 {
          width: 1px;
          height: 100%;
          transform: translateX(-10px);
        }
      }
    }
  }
}

.edit-address {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);

  &__modal {
    background: $color-white;
    margin: auto;
    position: relative;
    width: 900px;
    padding: 35px 60px 50px 60px;
    height: 90vh;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__close-icon {
    display: flex;
    justify-content: flex-end;
    img {
      cursor: pointer;
      width: 16px;
      height: 16px;
      color: $color-charcoal;
      position: absolute;
      top: 32.37px;
      right: 40.37px;
    }
  }
  &__title {
    margin-top: 5px;
    margin-bottom: 15px;
    font-family: $font-bold;
    font-size: 20px;
    line-height: 1.22;
    letter-spacing: 1.818px;
    color: $color-slate;
    text-transform: uppercase;
    @media screen and (max-width: 1023px) {
      font-size: 2rem;
      line-height: 2.5rem;
      letter-spacing: -0.1375rem;
      color: $slate;
      margin: 0 0 28px 0;
    }
    @media screen and (max-width: 1023px) {
      margin: 0 0 15px 0;
    }
  }
  @media screen and (max-width: 1024px) {
    &__modal {
      width: 100%;
      height: 100%;
      padding: 20px 32px 150px;
      &__close-icon {
        position: absolute;
        right: 40px;
        top: 35px;
        img {
          cursor: pointer;
          width: 16px;
          height: 16px;
        }
      }
      &__title {
        font-size: 36px;
        line-height: 1.25;
        letter-spacing: -2.5px;
        margin-top: 15px;
        margin-bottom: 15px;
      }
    }
  }
  @media screen and (max-width: 576px) {
    &__modal {
      padding: 20px 18px 50px;
    }
    .left-half-field {
      padding-right: 0;
    }
    .right-half-field {
      padding-left: 20px;
    }
    #add-address-label0-wrapper {
      margin-bottom: 22px;
    }
    #edit-address-label-wrapper {
      margin-bottom: 40px;
    }
    .edit-address__modal {
      height: 100vh;
    }
    .edit-address__close-icon {
      img {
        top: 33.37px;
        right: 17.37px;
      }
    }
  }
}

.payment-address-name {
  .radio {
    margin: 0;
  }
}
.payment-address-all {
  margin: 5px 0 0 30px;
  text-transform: capitalize;
}
.check-out__shipping-inline-section {
  margin: 20px 0 10px 0;
  .shipping-form__toggle {
    .checkbox_default {
      letter-spacing: -0.55px !important;
      color: $slate !important;
    }
  }
  #kf-shipping-city-wrapper, #kf-shipping-state-wrapper, #kf-shipping-zipcode-wrapper{
    margin-bottom: 0;
    @media screen and (max-width: 767px) {
      margin-bottom: 20px;
    }
  }
  #kf-shipping-zipcode-wrapper {
    @media screen and (max-width: 767px) {
      margin-bottom: 0;
    }
  }
  .mb-20 {
    margin-bottom: 0 !important;
  }
}
.check-out__shipping-modal {
  .shipping-form__label-input {
    margin-top: 0;
  }
}
.modal-address-instruction {
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: -0.0344rem;
  color: $color-dark-gray;
  margin-bottom: 30px;
}
