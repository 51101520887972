.order-summary-wrapper {
  margin: 89px 0 0 0;

  &.sticky-order {
    position: sticky;
    top: 0;

    @media screen and (min-width: 1024px) {
      top: 40px;
    }
  }

  @media screen and (max-width: 1380px) {
    margin: 80px 0 0 0;
  }

  @media screen and (max-width: 1150px) {
    margin: 75px 0 0 0;
  }

  @media screen and (max-width: 1023px) {
    margin: 0;

    &.sticky-order {
      position: relative;
    }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1380px) {
    margin: 93px 0 0 0;
  }

  .cart-accordian {
    margin: 40px 0 0 0 !important;

    .collapsible-accordion {
      .collapsible--open {
        .Collapsible__contentOuter {
          height: auto !important;
        }
      }

      .Collapsible {
        border-color: $dark-gray;

        &__trigger {
          padding: 10px 0;
          line-height: 24px;
          font-family: $font-regular;
          border-top: 1px solid $dark-gray;
        }

        &__contentOuter {
          .Collapsible__contentInner {
            margin: 10px 0 20px 0;

            .kf-react-button,
            .button {
              padding: 0;
              width: 6.0625rem;
              float: right;
              margin-left: 15px;

              @media screen and (max-width: 767px) {
                min-width: 6.0625rem;
              }
            }
          }
        }

        .is-open {
          .plus {
            &::after {
              transform: rotate(90deg);
            }
          }
        }

        .plus {
          position: relative;

          .line {
            display: none;
          }

          &::after {
            content: "\e92c";
            display: flex;
            font-family: icomoon;
            font-size: 1pc;
            opacity: 1;
            transform: rotate(-90deg);
            transition: all 0.5s ease;
            top: 0px;
            position: absolute;
            color: $charcoal;
          }
        }
      }
    }
  }
}

.cart-accordion-section {
  .order-summary__input-group {
    .kf-react-button,
    .button {
      padding: 0;
      width: 97px;
      float: right;
      margin-left: 15px;

      @media screen and (max-width: 767px) {
        min-width: 97px;
      }
    }
  }
}

.full-width {
  width: 100%;
}

.cart-accordian {
  .cart-accordion-section {
    .order-summary {
      &__input {
        margin: 0;

        .input-field {
          &__border {
            width: 13.6875rem;

            @media screen and (min-width: 1920px) {
              width: 17.6875rem;
            }

            @media only screen and (min-width: 1300px) and (max-width: 1599px) {
              width: 14.6875rem;
            }

            padding: 0 15px;
            border-radius: 4px;
            border: solid 1px $dark-gray;
            background-color: $color-white;
            height: 50px;

            input {
              font-family: $font-regular;
              font-size: 14px;
              line-height: 22px;
              letter-spacing: -0.45px;
              color: $slate;
            }

            @media only screen and (min-width: 1024px) and (max-width: 1340px) {
              label {
                font-size: 10px;
                line-height: 2;
              }
            }

            &.--error {
              border-color: $color-error-red;
            }
          }
        }
      }
    }
  }
}

.checkout-btn-group {
  margin-top: 10px;
  height: 3.125rem !important;
}
.checkout-btn-group-sticky {
  margin-top: 0px;
  height: 3.125rem !important;
}
.checkoutScroll {
  margin-top: 10px;
}
.order-summary {
  &__title {
    font-family: $font-bold;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 1.82px;
    color: $slate;
    margin-bottom: 20px;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
  }

  &__cart-number {
    font-family: $font-regular;
    font-size: 14px;
    line-height: 1.57;
    letter-spacing: -0.65px;
    color: $color-slate;
    margin-top: 10px;
  }

  &__shared-cart-number {
    font-family: $font-regular;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.45px;
    color: $color-slate;
    margin-bottom: 10px;
  }

  &__separator {
    border-top: 1px solid $color-dark-gray;
    margin: 25px auto;

    &.cart_custom_margin_hr {
      margin: 10px 0;
      opacity: 1;
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;

    @media screen and (max-width: 767px) {
      position: relative;
    }

    &.order-font-medium {
      .order-summary__item--label,
      .order-summary__item--value {
        font-family: $font-medium;
        color: $charcoal;
      }
    }

    &.order-font-medium-value {
      .order-summary__item--value {
        font-family: $font-medium;
        color: $charcoal;
      }
    }

    &.estimated-total {
      margin-bottom: 0;
      align-items: baseline;
      // min-height: 50px;

      .order-summary__item--label,
      .order-summary__item--value-large {
        font-size: 18px;
        line-height: 26px;
        color: $charcoal;
        align-items: baseline;
        font-family: $helvetica-medium;
      }

      .order-summary__item--label {
        letter-spacing: -0.35px;
      }

      .order-summary__item--value-large {
        letter-spacing: -0.35px;

        @media screen and (max-width: 767px) {
          letter-spacing: -0.62px;
        }
      }
      div &.order-details-summary {
        min-height: fit-content;
      }
    }

    &--small {
      .order-summary__item--label,
      .order-summary__item--value {
        font-family: $font-regular;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.35px;
        color: $color-slate;
      }
    }

    &--label {
      font-family: $font-regular;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.4px;
      color: $slate;
      display: flex;
      align-items: center;

      @media only screen and (min-width: 1024px) and (max-width: 1100px) {
        display: block;
      }
    }

    &--value {
      font-family: $font-regular;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.4px;
      color: $charcoal;

      &-large {
        font-family: $font-light;
        font-size: 32px;
        line-height: 1.25;
        letter-spacing: -1.6px;
        color: $color-slate;

        @media screen and (min-width: 1024px) and (max-width: 1440px) {
          font-size: 22px;
        }
      }
    }
  }

  &__price-shimmer {
    p {
      width: 100px;
    }

    &.--large {
      p {
        width: 120px;
      }
    }
  }

  &__close {
    cursor: pointer;
    margin-left: 20px;
    text-decoration: underline;

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  &__input-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__promo-error {
    margin-top: 10px;
    font-family: $font-regular;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.48px;
    color: $color-error-red;
    width: 100%;
  }

  &__zip-label {
    font-family: $font-regular;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.55px;
    color: $color-slate;
  }

  &__zip-cta {
    cursor: pointer;
    text-decoration: underline;
    font-family: $font-regular;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.55px;
    color: $color-slate;
    padding-left: 10px;

    &:hover {
      text-decoration: underline;
    }
  }

  &__tooltip-wrapper {
    position: absolute;
    z-index: 9;
    top: 10px;
    right: 0px;
  }

  &__tooltip--show {
    display: block;
    position: relative;

    @media screen and (max-width: 767px) {
      position: unset;
    }
  }

  &__tooltip--hide {
    display: none;
  }

  &__tooltip-icon {
    cursor: pointer;
    margin-left: 7px;

    @media screen and (max-width: 1023px) {
      margin-left: 5px;
    }
  }

  @media screen and (max-width: 1023px) {
    margin-top: 0px;
    padding: 35px 32px;
    border-radius: 0;
    border: none;
    margin-left: -32px;
    margin-right: -32px;

    &__checkout-btn {
      display: none;
    }

    .order-summary-mobile-sticky {
      &.isSticky,
      &.is-sticky-load {
        .order-summary__item--value-large {
          letter-spacing: -0.55px;
          font-size: 16px;
          line-height: 22px;
        }

        .order-summary__item--label {
          font-size: 16px;
          letter-spacing: -0.35px;
          line-height: 22px;
        }

        .checkout-btn-group {
          a {
            margin-top: 10px;
          }
        }
        width: 100%;
        bottom: 0;
        z-index: 11;
        left: 0;
      }
    }

    &__tooltip-wrapper {
      top: 24px;
      left: 0;
      right: 0;
    }

    .tooltip__container {
      width: 300px !important;
      margin: 0 auto !important;
      padding: 15px !important;
    }
  }

  @media screen and (max-width: 767px) {
    padding: 0 18px 0;
    border-radius: 0;
    margin: 0 -18px 0 -18px;

    &__tooltip-wrapper {
      top: 24px;
      left: 0;
      right: 0;
    }

    .tooltip__container {
      width: 300px !important;
      margin: 0 auto !important;
      padding: 15px !important;
    }
  }

  .edit-cart-btn {
    border: none;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.28px;
    cursor: pointer;
    color: $slate;
    font-family: $font-medium;
    text-transform: capitalize;
    padding: 0 0 4px 0;
    border-bottom: 1px solid $slate;
  }
  .tooltip__container {
    width: 300px !important;
    margin: 0 auto !important;
    padding: 15px !important;
  }
}

.check-out__sticky-summary {
  .order-summary-mobile-sticky {
    &.isSticky {
      background-color: unset;
      position: relative;
      bottom: unset;
      z-index: unset;
      padding: unset;
      box-shadow: unset;
    }
  }
}

.download-specs {
  padding: 50px 40px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: solid 1px $color-light-gray;
  border-top: none;
  background-color: #ffffff;

  &__cta {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }

  p,
  span {
    font-family: $font-regular;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.55px;
    color: $color-dark-gray;

    img {
      margin-left: 10px;
    }
  }

  span {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1440px) {
  .download-specs {
    padding: 50px 40px 55px 40px;
  }
}

.cart-promo-code {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .promo-right-section {
    display: flex;
  }

  span {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.45px;
    color: $charcoal;
    font-family: $font-regular;
  }

  .active-promo-name {
    font-family: $font-medium;
    text-transform: uppercase;
    width: 50%;

    span.promo-code__badge {
      position: absolute;
      color: $color-charcoal;
      font-feature-settings: "liga" off, "clig" off;
      font-family: $font-medium;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 10px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      padding: 4px 7px;
      border: 1px solid $color-medium-gray;
      margin: 2px 0 0 10px;
    }
  }
}
.promo-code-description {
  font-family: $font-regular;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  letter-spacing: -0.3px;
  color: $color-slate;
}
.no-cost-emi {
  font-family: $font-medium;
  font-weight: 500;
}
.cart-promo-description {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.45px;
  color: $slate;
  font-family: $font-regular;
  font-style: italic;

  @media screen and (max-width: 767px) {
    width: 58%;
    margin: 3px 0 0 0;
  }
}

.cart-promo-code-section {
  margin: 0px 0 0 0;

  @media screen and (max-width: 767px) {
    margin: 7px 0 0 0;
  }
}

.promo-code-list {
  margin: 0 0 20px 0;
}

span.order-summary__item--label-description {
  font-family: "Helvetica Now Text W05 Regular";
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.4px;
  color: #494949;
  display: flex;
  align-items: center;
}
.order-summary__item--tax-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  .order-summary__item--label {
    &.tax-label {
      font-family: $font-regular;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.4px;
      color: $slate;
    }
  }
  span.order-summary__item--label-description {
    color: $charcoal;
  }
  span.order-summary__item--label-description--number-format {
    padding: 0 5px;
  }
}
.order-summary__item--no-cost-emi {
  font-family: $font-regular;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.4px;
  color: $color-slate;
  margin-top: 20px;
  margin-bottom: 20px;
}
.order-summary__item-hr {
  width: 100%;
}
