.breadcrumb+.teaser.plp-text-hero {
  padding: 0 3.125rem 4.375rem;

  @media screen and (max-width: 1440px) {
    padding: 0 1.625rem 3.125rem;
  }

  @media screen and (min-width: 1920px) {
    padding: 0 0 4.375rem 0;
  }

  @media screen and (max-width: 1023px) {
    padding: 0 2rem 3.125rem;
  }

  @media screen and (max-width: 639px) {
    padding: 0 4.8% 50px !important;
  }
}

.cmp-breadcrumb-slash {
  display: none;
}

.breadcrumb .cmp-breadcrumb .cmp-breadcrumb__list .cmp-breadcrumb__item {
  position: relative;
  padding: 0 0 0 13px !important;
}

.breadcrumb .cmp-breadcrumb .cmp-breadcrumb__list .cmp-breadcrumb__item:first-child {
  padding: 0 !important;
}

.breadcrumb .cmp-breadcrumb .cmp-breadcrumb__list .cmp-breadcrumb__item::before {
  position: absolute;
  content: "/";
  left: 3px;
}

.breadcrumb .cmp-breadcrumb .cmp-breadcrumb__list .cmp-breadcrumb__item:first-child::before {
  display: none;
}

@media screen and (min-width: 1201px) {
  .cmp-tabs__tabpanel--active .globalnavigation .gbh-product-menu+.productbg {
    opacity: .8;
    visibility: visible;
  }
}

.breadcrumb {
  @media only screen and (min-width:1023px)and (max-width:1440px) {
    padding: 25px 2.6% 30px !important;
  }
}

/* Collapsible Footer */
.collapsible-footer .Accordion_accordionWrapper__vZrfq .collapsible-accordion .Collapsible__trigger {
  padding: 0;
}

.collapsible-footer .Accordion_accordionWrapper__vZrfq .collapsible-accordion .Collapsible {
  border: none;
}

.collapsible-footer .Accordion_accordionWrapper__vZrfq .collapsible-accordion .Collapsible__trigger .collapsible__header {
  margin: 0;
  position: relative;
  top: 20px;
}

.collapsible-footer .Collapsible .down-arrow,
.collapsible-footer .Collapsible .up-arrow {
  display: none;
}

.collapsible-footer .Collapsible .collapsible__header {
  flex-direction: column;
}

.collapsible-footer .Collapsible .arrow {
  background: #fff;
  display: flex;
  height: 44px;
  padding: 7px;
  position: relative;
  width: 44px;
  z-index: 10
}

.collapsible-footer .Collapsible .arrow:before,
.collapsible-footer .Collapsible .arrow:after {
  background: #212121;
  content: "";
  height: 30px;
  left: 21px;
  position: absolute;
  top: 8px;
  width: 1px
}

.collapsible-footer .Collapsible .arrow:after {
  height: 1px;
  top: 22px;
  width: 30px;
  left: 7px;
}

.collapsible-footer .Collapsible .is-open .arrow:before {
  display: none;
}

.collapsible-footer .cmp-tabs__tabpanel .cmp-container .list .list-inner h3 p b,
.collapsible-footer .cmp-tabs__tabpanel .cmp-container .list .list-inner h2 p b,
.collapsible-footer .cmp-tabs__tabpanel .cmp-container .list .list-inner h1 p b,
.collapsible-footer .cmp-tabs__tabpanel .cmp-container .list .list-inner h3 p,
.collapsible-footer .cmp-tabs__tabpanel .cmp-container .list .list-inner h2 p,
.collapsible-footer .cmp-tabs__tabpanel .cmp-container .list .list-inner h1 p {
  color: #fff;
  font-family: Helvetica Now Text W05 Regular;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -.0406rem;
  line-height: 1.75rem;
  margin-bottom: 20px;
}

.collapsible-footer .Accordion_accordionWrapper__vZrfq .cmp-accordion__item {
  margin: 0;
}

/* Header nav mobile view  */
@media screen and (max-width: 1200px) {
  .cmp-container .Shared_utilityNavHeaderWrapper__8gG45 .gbh-utility-nav .gbh-utility-navbar ul li.gbh-nav__findStore a {
    top: -10px;
  }

  .globalnavigation .gbh-product-menu .gbh-pro-menu__inner {
    min-height: 750px;
  }

  .gbh-nav__region .gbh-countryselector .gbh-close,
  .gbh-nav__region #gbh_utheader .gbh-tab__panels .gbh-close {
    display: none;
  }
}

.footer-followus .cmp-container .additionalfooter.follow-us-on-sm .gbh-social__links li.gbh-social__twitter {
  display: flex;
  align-items: center;
}

.footer-followus.container .cmp-container .additionalfooter.follow-us-on-sm .gbh-social__links li.gbh-social__twitter a {
  background: url(images/twitter.svg) 50%/auto 1rem no-repeat;
  width: 16px;
  height: 16px;
}

.footer-followus.container .cmp-container .additionalfooter.follow-us-on-sm .gbh-social__links li.gbh-social__twitter a:before {
  content: none;
}

@media screen and (max-width: 1200px) {

  .globalnavigation ul.nav li a.active,
  .globalnavigation ul.nav li a:hover {
    color: #757575 !important;
    background-color: transparent;
    font-family: Helvetica Now Text W05 Light;
  }

  .transparent-menu-white .gbh-global-tab .cmp-tabs .cmp-tabs__tablist .cmp-tabs__tab:hover,
  .auth-nav__user {
    color: #757575 !important;
  }

  /* .auth-nav__sign-in-avatar {
    display: none;
  } */
}

#gbh_utheader .gbh-tab__panels .gbh-tab__content .right .gbh-tab__list>li.india {
  cursor: default;
}

#gbh_utheader .gbh-tab__panels .gbh-tab__content .right .gbh-tab__list>li.india a {
  color: #757575;
  pointer-events: none;
}

/* Header bug fixes */
@media screen and (min-width: 1200px) {
  body .utilitynavheader .utility-widgets .wishlist-widget img {
    height: 20px;
  }

  .cart-indicator {
    display: flex;
    margin-top: 0;
  }

  body .utilitynavheader .utility-widgets .wishlist-widget {
    padding-top: 2px;
  }

  .UtilityWidgets_utilityWidgetsWrapper__Wz0ZS .utility-widgets {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
  }

  .auth-widget .auth-nav .auth-nav__menu {
    position: absolute;
  }
}

.text.article-text-style-1.article-text-display-style-4 .cmp-text p {
  max-width: 73.125rem;
}

.container.tool-box-container.bg-extra-light-grey .cmp-container .cmp-teaser__title p {
  color: #212121;
}

.bg-white .cmp-container .teaser.full-bleed-video-bottom .cmp-teaser__title h1,
.bg-white .cmp-container .teaser.full-bleed-video-bottom .cmp-teaser__title h2,
.bg-white .cmp-container .teaser.full-bleed-video-bottom .cmp-teaser__title h3,
.bg-white .cmp-container .teaser.full-bleed-video-bottom .cmp-teaser__title h4,
.bg-white .cmp-container .teaser.full-bleed-video-bottom .cmp-teaser__title h5,
.bg-white .cmp-container .teaser.full-bleed-video-bottom .cmp-teaser__title h6,
.bg-white .cmp-container .teaser.full-bleed-video-bottom .cmp-teaser__title p {
  color: #212121
}

.bg-white .cmp-container .teaser.full-bleed-video-bottom .cmp-teaser__description p {
  color: #494949;
}

/* store virtual  */
.store-virtual-image-left .aem-GridColumn {
  width: 59.375%;
}

.store-virtual-image-left .cmp-container .aem-GridColumn .image {
  width: 100%;
  margin-bottom: 30px;
}

@media screen and (max-width: 640px) {
  .store-virtual-image-left .aem-GridColumn {
    width: 100%;
  }

  .store-virtual-image-left .cmp-container .aem-GridColumn .image {
    margin-bottom: 0;
  }
}

.cmp-container .hero-banner-article-bottom .cmp-teaser__title {
  width: 58.3%;
}

/* embedded form design changes */
.container-complimentary-guide.container {
  .cmp-container {
    @media (min-width: 1900px) {
      max-width: 1820px;
    }
    @media (min-width: 1200px) and (max-width: 1899px) {
      max-width: inherit;
    }
    @media (min-width: 1024px) {
      padding: 0 2.6%;
    .teaser-complimentary-guide {
      padding-top: 4.5rem;
    }
    .cmp-teaser {
      max-width: 770px;
      width: 90%;
      @media (max-width: 1439px) {
        width: 100%;
      }
      @media (min-width: 1700px) {
        width: auto;
      }

      
      .cmp-teaser__title h2 {
        font-size: 4rem;
        line-height: 6.875rem;
        @media (max-width: 1600px) {
          font-size: 3rem;
          line-height: 4rem;
        }
      }
      .cmp-teaser__image {
        max-width: 770px;
      }
      .cmp-teaser__content {
        padding: 0 4.125rem 5rem 0;
        .cmp-teaser__description {
          width: 100%;
          padding: 0 0 0 4.375rem;
          border-left: solid 1px #fff;
          p {
            font-size: 2rem;
            line-height: 2.5rem;
            letter-spacing: -0.1rem;
            color: #fff;
            font-family: Helvetica Now Text W05 light;
            @media (max-width: 1600px) {
              font-size: 1.5rem;
              line-height: 1.7rem;
            }
            a {
              font-size: 24px;
              @media (max-width: 1600px) {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
    .aem-GridColumn {
      width: 50%;
      padding: 7.5rem 0 3.125rem 7.5rem;
      @media (max-width: 1600px) {
        padding-left: 5%;
      }
    }
  }

  @media (max-width: 1023px) {
    .product-enquiry.enquiry {
      max-width: 100%;
      padding: 3.94%;
    }
  }
  @media (max-width: 575px) {
    .pr-20 {
      padding-right: 0 !important;
    }
    .pl-20 {
      padding-left: 0 !important;
    }
  }
  }
}


/* Back to top */
@media (min-width: 1024px) {
  .stickychattop {
    display: none !important;
  }
}

@media (max-width: 1023px) {
.stickychattop {
  position: fixed;
  bottom: 110px;
  right: 15px;
  background-color: #000;
  border: solid 1px #fff;
  border-radius: 5px;
  padding: 15px 14px 15px;
  width: 106px;
  z-index: 999;
  @media (max-width: 767px) {
    width: 64px;
    bottom: 90px;
  }
  .stickytop {
    background: transparent;
    border: none;
    color: #fff;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.3px;
    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 18px;
      letter-spacing: -0.25px;
    }
    .backtopimg {
      filter: brightness(0) invert(1);
      margin-bottom: 5px;
    }
    .backtoptext {
      display: block;
    }
  }
}
}

/* chat icon */
#ymPluginDivContainerInitial {
  button#ymDivBar {
    right: 14px;
    left: auto;
    background: url(images/chat.svg) no-repeat center 17px !important;
    background-size: 26px !important;
    background-color: #000 !important;
    border: solid 1px #fff;
    border-radius: 5px !important;
    padding: 6px 4px !important;
    bottom: 10px !important;
    width: 98px !important;
    height: 70px !important;
    z-index: 999 !important;
    @media (max-width: 767px) {
      width: 54px !important;
      height: 40px !important;
      background: url(images/chat.svg) no-repeat center center !important;
      bottom: 20px !important;
      background-color: #000 !important;
    }
    .ym-icon-wrapper {
      width: 100%;
      height: 100%;
      position: relative;
        &::after {
          position: absolute;
          content: "Chat";
          left: 34px;
          bottom: 14px;
        }
        @media (max-width: 767px) {
          &::after {
            content: " ";
          }
        }
      img {
        display: none;
       }
      .ym-online-box {
        display: none;
      }
    }
    .ym-title-parent {
      display: none;
    }
  }
}
