.form {
  &__input[type="text"],
  &__input[type="password"],
  &__input[type="tel"] {
    border-radius: 4px;
    background: #fff;
    padding-left: 15px;
    padding-right: 15px;
    height: 40px;
    border: solid 1px #ddd;
    -webkit-appearance: none;
    display: block;
    width: 100%;
    clear: both;
    outline: none;

    &:focus {
      border: 1px solid #66afe9;
      outline: 0;
      box-shadow: 0 0 8px rgba(102, 175, 233, 0.6);
    }
  }

  &__group {
    float: none;
    margin-bottom: 30px;
    display: block;

    label {
      display: block;
      margin-bottom: 10px;
      float: left;
    }
  }

  .error {
    display: block;
    margin-top: 10px;
    float: left;
  }
}
.ChangePassword_ChangePassword__EXQIE {
  .wrapper {
    height: unset;
    .reset-button {
      margin-top: 20px;
    }
    .header {
      padding: 0 90px;
      @include breakpoint(mobile-potrait) {
        padding: 0 30px;
      }
    }
    div.kf-react-button {
      text-align: center;
      a.kf-react-button {
        width: 238px;
        height: 40px;
      }
    }
  }
}
