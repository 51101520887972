@import "@/styles/mixins/_breakpoint";
@import "@/styles/base/_colors";
@import "@/styles/base/_fonts";
.order-history {
  padding: 0 10px;

  &__header {
    margin-bottom: 40px;
  }

  &__filter {
    padding-top: 30px;

    &--name {
      width: 58.33333%;
      display: inline-block;
    }
  }

  &__select {
    width: 41.66667%;
    display: inline-block;
    padding: 0 10px;
    position: relative;

    &__value {
      width: 100%;
      border-radius: 4px;
      border: 1px solid #ccc;
      padding: 10px;
      line-height: 30px;
    }

    &__list {
      display: none;

      &--show {
        display: block;
        position: absolute;
        left: 10px;
        width: calc(100% - 20px);
        background-color: #fff;
      }
    }

    &__options {
      border: 1px solid #ccc;
      border-top-width: 0px;
      width: 100%;
      padding: 10px;
      line-height: 30px;

      &:hover {
        background-color: #f2f2f2;
      }

      &.selected {
        background-color: #e5e5e5;

        &:hover {
          background-color: #e5e5e5;
        }
      }
    }
  }

  &__table {
    width: 100%;
  }

  &__thead,
  &__tbody {
    &__data {
      padding: 20px 10px;
      width: 50%;

      &--total,
      &--status,
      &--details {
        display: none;
      }
    }
  }

  &__thead {
    border-bottom: 1px solid #ccc;
  }
}
.customer-service-message{
  p{
    margin-bottom: 1.5625rem;
    font-size: 1.75rem;
    line-height: 2.25rem;
    color: $color-slate;
    letter-spacing: -1.4px;
    font-family: $font-light;
    .customer-services-link{
      color: $color-slate;
      font-family: $font-light;
      line-height: 1.5rem;
      text-decoration: underline;
    }
    @media screen and (max-width: 767px){
      font-size: 1rem;
      line-height: 1.5rem;
      letter-spacing: -0.55px;
    }
  }
  .contact-customer-btn{
    margin-bottom: 2.5rem;
    @media screen and (max-width: 767px){
      margin-bottom: 1.875rem;
    }
  }
}
@media only screen and (min-width: 980px), print {
  .order-history {
    &__header {
      &:after {
        content: "";
        display: block;
        clear: both;
      }
    }

    &__title {
      width: 66.6667%;
      float: left;
    }

    &__filter {
      float: right;
      width: 33.3333%;
      padding-top: 0;
    }

    &__thead,
    &__tbody {
      &__data {
        padding: 20px 10px;
        width: 16.66667%;

        &--total,
        &--status,
        &--details {
          display: table-cell;
        }
      }
    }
  }
}
// Orders page UI Deign Enhancement
.orderhistory {
  margin: 0;
  padding: 0 2.6%;
  @include breakpoint(desktop-large) {
    padding: 0 50px;
  }
  @include breakpoint(tab-landscape) {
    padding: 0;
  }
  .kf-react-container {
    max-width: 1820px !important;
    margin: 0 auto !important;
    padding: 0 !important;
    display: flex;
    justify-content: space-between;
    @include breakpoint(tab-landscape) {
      max-width: 100% !important;
    }
    aside.account-tabs__nav-wrapper {
      width: 13.02%;
      max-width: 100%;
      flex: unset;
      padding-right: 1.375rem;
      padding-top: 3.75rem;
      @include breakpoint(tab-landscape) {
        width: 100%;
        padding: 0;
      }
      @include breakpoint(mobile-landscape) {
        padding: 2.5rem 32px 0;
      }
      @include breakpoint(mobile-potrait) {
        padding: 2.5rem 18px 0;
      }
      .account-tabs {
        &__nav {
          padding: 0;
          margin: 0;
        }
        &__message {
          @include text-style($font-light, 1.75rem, 2.25rem, -1.4px);
          color: $color-slate;
        }
        &__list {
          margin-top: 2.5rem;
        }
        &__list-item {
          @include text-style($font-regular, 1rem, 1.5rem, -0.4px);
          color: $color-slate;
          &:first-child {
            padding-top: 0;
          }
          &.--active {
            @include text-style($font-medium, 1rem, 1.5rem, -0.35px);
          }
        }
        &__sign-out {
          @include text-style($font-medium, 0.875rem, 1.375rem, -0.3px);
          color: $color-slate;
          margin-top: 3.125rem;
          text-decoration: unset;
          border-bottom: 1px solid $color-slate;
          width: max-content;
        }
        &__dropdown {
          @include breakpoint(tab-landscape) {
            margin-bottom: 40px;
          }
          .select-dropdown {
            &__placeholder {
              @include breakpoint(tab-landscape) {
                @include text-style($font-regular, 12px, 20px, -0.35px);
                color: $color-dark-gray;
              }
            }
            &__value {
              @include breakpoint(tab-landscape) {
                @include text-style($font-regular, 18px, 26px, -0.7px);
                color: $color-slate;
                bottom: -0.6875rem;
              }
            }
            &__field {
              @include breakpoint(tab-landscape) {
                padding: 5px 15px 8px;
              }
            }
          }
        }
      }
    }
    main {
      width: 85.38%;
      max-width: 100%;
      flex: unset;
      padding-top: 2.8125rem;
      @include breakpoint(tab-landscape) {
        width: 100%;
      }
      @include breakpoint(mobile-landscape) {
        padding: 20px 32px 0;
      }
      @include breakpoint(mobile-potrait) {
        padding: 20px 18px 0;
      }
      .account-tabs {
        &__content {
          padding: 0;
          margin: 0;
        }
        &__title {
          @include text-style($font-light, 2.8125rem, 3.5rem, -3.187px);
          color: $color-slate;
          margin-bottom: 4.6875rem;
        }
      }
      .order-list {
        margin-bottom: 5.625rem;
        @include breakpoint(tab-landscape) {
          margin-bottom: 40px;
        }
        &__title {
          @include text-style($font-bold, 1.25rem, normal, 1.818px);
          text-transform: uppercase;
          color: $color-slate;
          margin-bottom: 0;
        }
        &__empty-state {
          border: none;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          column-gap: unset;
          margin: 0;
          padding: 0;
          @include breakpoint(mobile-landscape) {
            margin-bottom: 180px;
          }
          @include breakpoint(mobile-potrait) {
            margin-bottom: 192px;
          }
        }
        &__empty-state__desc {
          @include text-style($font-light, 28px, 40px, -1.4px);
          color: $color-slate;
          @include breakpoint(tab-landscape) {
            @include text-style($font-light, 26px, 34px, -1.5px);
            margin-bottom: 0;
          }
          & ~ div > .button {
            margin-top: 25px;
            background: transparent;
            padding: 8px 30px;
            color: $color-charcoal;
            @include text-style($font-medium, 14px, 24px, -0.25px);
            border: 1px solid $color-charcoal;
            width: auto;
            height: auto;
            @include breakpoint(tab-landscape) {
              width: 100%;
            }
          }
          & ~ div {
            @include breakpoint(tab-landscape) {
              width: 100%;
            }
          }
        }
        &.pastOrderHeader {
          margin-bottom: 7.5rem;
          @include breakpoint(tab-landscape) {
            margin-bottom: 3.75rem;
          }
        }
      }
      .order-tile {
        display: flex;
        justify-content: space-between;
        row-gap: unset;
        padding: 3.75rem 0;
        margin: 0;
        border-bottom: 1px solid $color-dark-gray;
        border-top: none;
        @include breakpoint(tab-landscape) {
          flex-direction: column;
          padding: 1.875rem 0;
        }
        &__details {
          order: unset;
          display: flex;
          justify-content: flex-start;
          width: 82.5%;
          column-gap: 5rem;
          @include breakpoint(tab-landscape) {
            width: 100%;
            flex-wrap: wrap;
            column-gap: unset;
          }
          @include breakpoint(mobile-potrait) {
            justify-content: unset;
            column-gap: unset;
            row-gap: unset;
          }
        }
        &__label {
          @include text-style($font-medium, 1rem, 1.5rem, -0.35px);
          color: $color-slate;
          margin-bottom: 5px;
        }
        &__value {
          @include text-style($font-regular, 1rem, 1.5rem, -0.4px);
          color: $color-dark-gray;
        }
        &__order-no,
        &__date,
        &__items,
        &__total,
        &__status {
          @include breakpoint(mobile-landscape) {
            flex: 0 0 50%;
            padding-bottom: 25px;
          }
          @include breakpoint(mobile-potrait) {
            flex: 0 0 100%;
            padding-bottom: 25px;
          }
        }
        &__order-no {
          @include breakpoint(desktop-small) {
            width: 8rem;
          }
          @include breakpoint(desktop-extra-sm) {
            width: 9rem;
          }
        }
        &__date {
          @include breakpoint(desktop-small) {
            width: 14.75rem;
          }
        }
        &__items {
          @include breakpoint(desktop-small) {
            width: 4.9375rem;
          }
        }
        &__total {
          @include breakpoint(desktop-small) {
            width: 14.75rem;
          }
        }
        &__status {
          @include breakpoint(desktop-small) {
            width: 14.75rem;
          }
        }
        //Oredrs changed in MP
        &__status {
          @include breakpoint(mobile-potrait) {
            order: 4;
          }
        }
        &__total {
          @include breakpoint(mobile-potrait) {
            order: 3;
          }
        }
        &__date {
          @include breakpoint(mobile-potrait) {
            order: 1;
          }
        }
        &__items {
          @include breakpoint(mobile-potrait) {
            order: 2;
          }
        }
        &__order-no {
          @include breakpoint(mobile-potrait) {
            order: 0;
          }
        }
        div.order-tile__view-order {
          order: unset;
          padding: 0 !important;
          margin: 0 !important;
          width: 20%;
          display: flex;
          justify-content: flex-end;
          @include breakpoint(tab-landscape) {
            width: 100%;
            justify-content: flex-start;
          }
          button.order-tile__view-order {
            @include text-style($font-medium, 0.875rem, 1.5rem, -0.25px);
            color: $color-slate;
            border: 1px solid $color-slate;
            @include breakpoint(tab-landscape) {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
