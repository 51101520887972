.order-status {
  &__error-box {
    margin-bottom: 15px;
    span {
      font-family: $font-regular;
      font-size: 16px;
      line-height: 1.38;
      letter-spacing: -0.3px;
      color: $color-error-red;
      p {
        color: $color-error-red;
        font-family: $font-regular;
      }
    }
  }
  &__breadcrumb {
    margin-top: 30px;
    color: $color-dark-gray;
    font-family: $font-regular;
    font-size: 14px;
    letter-spacing: -0.3px;
    line-height: 24px;
    a {
      cursor: pointer;
      text-decoration: none;
      color: $color-dark-gray;
      &:hover {
        color: $color-slate;
      }
    }
  }
  &__tooltip-wrapper {
    position: absolute;
    z-index: 9;
    top: -40px;
    left: 180px;
  }
  &__tooltip--show {
    display: block;
    position: relative;
  }
  &__tooltip--hide {
    display: none;
  }
  .input-field__wrapper {
    margin-bottom: 0;
  }
  &__support {
    a {
      cursor: pointer;
      text-decoration: none;
      font-family: $font-regular;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.55px;
      color: $color-slate;
    }
  }

  @media screen and (max-width: 576px) {
    &__tooltip-wrapper {
      top: -15px;
      left: 85px;
    }
  }
}

.orderWarning {
  .modal-popup {
    &-content {
      width: 500px;
      padding: 70px 40px 40px;
    }
  }
  .order-warning {
    &__modal {
      color: $color-slate;
    }
    &__modal-title {
      font-family: $font-light;
      font-size: 36px;
      line-height: 44px;
      letter-spacing: -1.7px;
    }
    &__modal-desc {
      font-family: $font-regular;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.55px;
      margin-top: 30px;
    }
    &__modal-cta {
      margin-top: 60px;
      .kf-react-button.button {
        width: 100%;
      }
    }
  }
  @media screen and (min-width: 480px) and (max-width: 990px) {
    .modal-popup {
      &-content {
        width: 100%;
        height: 100vh;
        padding: 55px 100px;
      }
    }
  }
  @media screen and (max-width: 480px) {
    .modal-popup {
      &-content {
        padding: 55px 18px 18px;
      }
    }
  }
  @media screen and (max-width: 990px) {
    .order-warning {
      &__modal-title {
        font-family: $font-light;
        font-size: 32px;
        line-height: 40px;
        letter-spacing: -2.2px;
      }
    }
  }
}
// Order Status UI Enhancement
.order-status {
  padding: 0 2.6% !important;
  @include breakpoint(desktop-large) {
    padding: 0 50px !important;
  }
  @include breakpoint(mobile-landscape) {
    padding: 0 32px !important;
  }
  @include breakpoint(mobile-potrait) {
    padding: 0 18px !important;
  }
  .order-status {
    &__form-wrapper {
      max-width: 554px;
      margin: 0 auto;
      padding: 55px 0 100px 0;
      @include breakpoint(mobile-landscape) {
        max-width: 475px;
        margin: 0 auto;
      }
      @include breakpoint(mobile-potrait) {
        max-width: 100%;
        margin: 0;
      }
    }
    &__header {
      text-align: center;
    }
    &__title {
      @include text-style($font-light, 36px, 44px, -1.7px);
      color: $color-slate;
      @include breakpoint(tab-landscape) {
        @include text-style($font-light, 32px, 40px, -2.2px);
      }
    }
    &__desc {
      @include text-style($font-regular, 14px, 22px, -0.45px);
      color: $color-slate;
      padding-top: 15px;
      @include breakpoint(mobile-potrait) {
        padding-top: 10px;
      }
    }
    &__form {
      padding: 30px 0 0;
    }
    &__form-content {
      .input-field {
        margin-bottom: 0;
        &__border {
          align-items: end;
          padding: 8px 15px;
        }
        &__value {
          label {
            @include text-style($font-regular, 18px, 26px, -0.5px);
            color: $color-dark-gray;
          }
        }
      }
      .recaptcha-links {
        @include text-style($font-regular, 14px, 22px, -0.45px);
        color: $color-dark-gray;
        margin: 0 !important;
        padding-top: 20px;
      }
    }
    &__help-text {
      @include text-style($font-regular, 14px, 22px, -0.45px);
      color: $color-slate;
      margin: 0;
      padding: 10px 0 30px;
      text-decoration: underline;
      cursor: pointer;
    }
    &__find-btn {
      margin: 0;
      .order-status__find-btn {
        margin-top: 30px;
        font-family: $font-regular;
        line-height: 26px;
        height: 50px !important;
        padding: 0 40px;
      }
    }
    &__support {
      margin: 0;
      padding-top: 40px;
      span {
        @include text-style($font-regular, 1rem, 1.5rem, -0.0344rem);
        color: $color-slate;
        margin: 0;
        padding: 0;
        @include breakpoint(tab-landscape) {
          letter-spacing: -0.025rem;
        }
      }
      .cart-info {
        &__brand-title {
          @include text-style($font-regular, 0.875rem, 1.375rem, -0.0281rem);
          color: $color-slate;
          margin: 0;
          padding-top: 0.9375rem;
          .cart-info__link {
            @include text-style($font-regular, 0.875rem, 1.375rem, -0.0281rem);
            color: $color-charcoal;
            padding: 0;
            margin: 0;
          }
          &:last-child {
            padding-top: 0.625rem;
          }
          @include breakpoint(mobile) {
            display: flex;
            gap: 2px;
          }
        }
      }
    }
    &__tooltip-wrapper {
      @include breakpoint(tab-landscape) {
        left: 0px;
      }
    }
  }
}
