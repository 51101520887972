// BREAKPOINTS
// *********************

@mixin breakpoint($point) {
  @if $point == desktop-xxxlg {
    @media (min-width: 1401px) and (max-width: 1920px) {
      @content;
    }
  } @else if $point == desktop-large {
    @media (min-width: 1920px) {
      @content;
    }
  } @else if $point == desktop-extra-lite-sm {
    @media (max-width: 1200px) {
      @content;
    }
  } @else if $point == desktop-extra-lite-lg {
    @media (min-width: 1023px) and (max-width: 1200px) {
      @content;
    }
  } @else if $point == desktop-extra-lite {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $point == desktop-extra-sm {
    @media (min-width: 1024px) and (max-width: 1280px) {
      @content;
    }
  } @else if $point == desktop-xlg {
    @media (min-width: 1601px) {
      @content;
    }
  } @else if $point == desktop-lg {
    @media (min-width: 1024px) and (max-width: 1600px) {
      @content;
    }
  } @else if $point == desktop-mid {
    @media (min-width: 1024px) and (max-width: 1440px) {
      @content;
    }
  } @else if $point == desktop-small {
    @media (min-width: 1024px) {
      @content;
    }
  } @else if $point == tab-max {
    @media (max-width: 1024px) {
      @content;
    }
  } @else if $point == tab-min {
    @media (min-width: 561px) and (max-width: 768px) {
      @content;
    }
  } @else if $point == tab {
    @media (min-width: 561px) and (max-width: 1024px) {
      @content;
    }
  } @else if $point == mobile {
    @media (max-width: 560px) {
      @content;
    }
  } @else if $point == mobile-potrait {
    @media (max-width: 767px) {
      @content;
    }
  } @else if $point == mobile-landscape {
    @media (min-width: 768px) and (max-width: 1023px) {
      @content;
    }
  } @else if $point == tab-landscape {
    @media (max-width: 1023px) {
      @content;
    }
  }
}
