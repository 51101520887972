.order-confirmation {
  &__message {
    background-color: $color-off-white;
    padding-top: 80px;
    padding-bottom: 100px;

    @media screen and (min-width: 767px) {
      .container {
        &.kf-react-container {
          padding: 0 2.6rem !important;
          max-width: 100% !important;
        }
      }
    }
  }

  &__main-title {
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 1.82px;
    color: $slate;
    font-family: $font-bold;
    text-transform: uppercase;
  }

  &__sub-title {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.45px;
    margin-top: 20px;
    color: $slate;
    font-family: $font-regular;

    @media screen and (min-width: 768px) {
      width: 36.625rem;
    }
  }

  &__thank-you,
  &__no-order {
    font-family: $font-light;
    font-size: 60px;
    line-height: 1.13;
    letter-spacing: -3.1px;
    color: $color-slate;
    margin-bottom: 25px;
  }

  &__description {
    font-family: $font-regular;
    font-size: 20px;
    line-height: 1.4;
    letter-spacing: -0.65px;
    color: $color-slate;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  &__order-summary {
    &.sticky-order {
      position: sticky;
      top: 0;

      @media screen and (min-width: 1024px) {
        top: 40px;
      }

      @media screen and (max-width: 1023px) {
        position: relative !important;
        top: unset;
        margin: 0;
      }
    }

    .order-summary {
      @media screen and (max-width: 1023px) {
        padding: 0px 32px;
      }

      @media screen and (max-width: 767px) {
        padding: 0px 16px;
      }
    }
  }
  .return-modal{
    .close-button-holder {
      display: none;
    }
    .modal-popup.show-modal-popup .modal-popup-content{
      max-width: 31.25rem;
      padding: 3.125rem;
      @media screen and (max-width: 767px){
        max-width: 100%;
        padding: 1.5625rem;
      }
    }
    .modal-popup .modal-popup-content .modal-popup-body{
      padding: 0;
    }
    h5{
      font-size: 1.25rem;
      line-height: 1.8375rem;
      letter-spacing: .1138rem;
      font-family: $font-bold;
      text-transform: uppercase;
      margin-bottom: .9375rem;
      color: $color-slate;
    }
    p{
      color: $color-dark-gray;
      padding-bottom: 1.875rem;
    }
    .return__close-button{
      width: 100%;
    }
  }
  .order-confirmation__sub-title{
    @media screen and (max-width: 1023px){
      margin-bottom: 1.875rem;
    }
  }
  .order-confirmation__content-section .return-order-items{
   .cart-item__name{
    font-family: $font-regular;
   }
   .cart-item__info, .mobile-full-width{
    padding-left: 0;
  }
    @media screen and (max-width: 1023px) {
      &.order-items{
        margin-top: .625rem;      
      }
      .cart-item__info, .mobile-full-width{
        padding-top: .625rem;
        padding-left: 0;
      }
    }
    @media screen and (max-width: 767px){
      .cart-item__info{
        display: flex;
      }
      .order-items__links{
        column-gap: 0;
        padding-right: 0;
        width: 100%;
        &.return-order-links{
          justify-content: space-between;
        }
      }
      .order-items__invoice{
        font-size: .875rem;
        line-height: 1.375rem;
        letter-spacing: -0.3px;
        padding-right: 0;
      }
      .order-items__status{
        font-size: .875rem;
        padding-right: 1.375rem;
        line-height: 1.375rem;
        letter-spacing: -0.3px;
      }
    }
  }
  .return-cart-item{
    padding-bottom: 20px;
    margin: 0 0px;
    @media screen and (max-width: 1023px){
      &.cart-item{
        padding-bottom: 0;
      }
    }
    .cart-item__qty{
      justify-content: flex-start;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
      @media screen and (max-width: 767px){
        margin-left: 116px;
        float: none;
      }
    }
    .cart-item__qty-price{
      margin-bottom: 0;
    }
    .requested-service-quantity{
      font-family: $font-regular;
      padding-bottom: .3125rem;
      letter-spacing: -0.0219rem;
      color: $color-slate;
      font-size: 1rem;
      letter-spacing: -0.0219rem;
      line-height: 1.5rem;
    }
    .requested-quantity{
      display: flex;
      font-family: $font-medium;
      padding-bottom: .3125rem;
      letter-spacing: -0.0219rem;
      color: $color-charcoal;
    }
    .rejected-item{
      padding-top: 1.25rem;
      @media screen and (max-width: 767px){
        padding-top: 0;
        padding-bottom: 1.25rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      p{
        font-size: 1rem;
        font-family: $font-regular;
        line-height: 1.5rem;
        letter-spacing: -0.0344rem;
        color: $color-slate;
        text-align: left;
        &:first-child{
          font-family: $font-medium;
          padding-bottom: .3125rem;
          letter-spacing: -0.0219rem;
          color: $color-charcoal;
        }
      }
    }
    .return-reason{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-top: 1.25rem;
      @media screen and (max-width: 767px){
        padding-bottom: 1.25rem;
      }
      p{
        font-size: 1rem;
        font-family: $font-regular;
        line-height: 1.5rem;
        letter-spacing: -0.0344rem;
        color: $color-slate;
        text-align: left;
        &:first-child{
          font-family: $font-medium;
          padding-bottom: .3125rem;
          letter-spacing: -0.0219rem;
          color: $color-charcoal;
        }
      }
    }
    .cart-item__total{
      @media screen and (max-width:767px){
        float: left;
        text-align: left;
        margin-left: 115px;
      }
    }
  }

  @media screen and (min-width: 1024px) {
    &__order-summary {
      width: 21.6875rem;
    }
  }

  @media screen and (min-width: 1600px) {
    &__order-summary {
      width: 20.6875rem;
    }
  }

  @media screen and (min-width: 1920px) {
    &__order-summary {
      width: 24.6875rem;
    }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1100px) {
    &__order-summary {
      width: 21.6875rem;
    }
  }

  @media only screen and (min-width: 1100px) and (max-width: 1100px) {
    &__order-summary {
      width: 20.6875rem;
    }
  }

  @media only screen and (min-width: 1300px) and (max-width: 1599px) {
    .order-summary-cart {
      width: 21.6875rem;
    }

    .cart-item-list-section {
      max-width: 71%;
    }
  }

  &__shipment-details {
    order: 3;

    .combo-sku-with-cart {
      .cart-item {
        .comob-cart-item__links {
          float: right;

          @media screen and (max-width: 1023px) {
            float: left;
          }
        }

        &__info {
          display: block !important;

          @media screen and (max-width: 767px) {
            margin: 0;
          }

          .cart-item__qty-price {
            display: none !important;
          }
        }

        @media screen and (max-width: 767px) {
          &__links {
            text-align: left;
            padding-left: 90px;
          }
        }
      }

      @media screen and (max-width: 767px) {
        .cart-stock-message-mobile {
          display: block;
        }
      }
    }
  }

  &__header {
    margin-bottom: 30px;
  }

  &__title {
    font-family: $font-light;
    font-size: 45px;
    line-height: 56px;
    letter-spacing: -3.19px;
    color: $color-slate;
  }

  .show-mobile-view {
    display: none;

    @media screen and (max-width: 767px) {
      display: block;
    }
  }

  &__details {
    margin-bottom: 10px;
    font-family: $font-regular;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.55px;
    color: $dark-gray;

    span {
      color: $color-slate;
    }

    &:last-child {
      margin: 0;

      @media screen and (max-width: 767px) {
        margin: 15px 0 0 0;
      }
    }
  }

  &__customer-info {
    h2 {
      font-family: $font-medium;
      font-size: 20px;
      line-height: 1.4;
      letter-spacing: -0.4px;
      color: $color-charcoal;
      margin: 0px 0 32px 0;
    }
  }

  &__info-row {
    display: flex;
    column-gap: 28px;
    width: 100%;
    margin-top: 15px;
  }

  &__info-tile {
    width: 100%;

    p {
      margin-bottom: 5px;
      font-family: $font-medium;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.35px;
      color: $slate;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    li {
      font-family: $font-regular;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.55px;
      color: $color-dark-gray;
    }
  }

  &__buttons {
    margin: 5% 0 7.9% 0;
    display: flex;
    column-gap: 20px;

    @media screen and (max-width: 1023px) {
      margin: 30px 0 60px 0;
    }
  }

  &__shipment {
    padding: 0 0 8% 0;

    @media screen and (max-width: 1023px) {
      padding: 0 0 50px 0;
    }

    &-title {
      display: flex;
      font-family: $font-medium;
      font-size: 20px;
      line-height: 1.4;
      letter-spacing: -0.4px;
      color: $color-charcoal;

      img {
        cursor: pointer;
        margin-left: 20px;
        width: 30px;
        height: 30px;
      }
    }

    @media screen and (min-width: 767px) {
      .cart-item {
        &__qty-price {
          justify-content: end;
        }

        &__qty {
          justify-content: flex-start;
        }

        &__discount-container {
          text-align: center;
        }
      }
    }
  }

  &__tooltip-wrapper {
    position: absolute;
    z-index: 9;
    bottom: 10px;
    left: 10px;
  }

  &__oneClick-email-label {
    .order-confirmation__tooltip-wrapper {
      bottom: -8px;
      left: -13px;
    }
  }

  &__oneClick-AccountTypeLable {
    .order-confirmation__tooltip-wrapper {
      bottom: 6px;
      left: 131px;
      bottom: 6px;

      @media only screen and (min-width: 1025px) and (max-width: 1218px) {
        left: 112px;
      }
    }

    .tooltip__container {
      @media screen and (max-width: 768px) {
        width: 224px;
      }

      @media screen and (max-width: 374px) {
        width: 160px;
      }
    }
  }

  &__tooltip--show {
    display: block;
    position: relative;
  }

  &__tooltip--hide {
    display: none;
  }

  &__support {
    margin: 17.8% 0 0 0;

    &-help {
      padding-bottom: 23px;
      border-bottom: 1px solid $color-dark-gray;
    }

    &-call {
      padding-top: 23px;
    }

    &-title {
      margin-bottom: 10px;
      font-family: $font-medium;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.35px;
      color: $color-slate;
    }

    &-subtitle {
      margin-bottom: 10px;
      font-family: $font-light;
      font-size: 26px;
      line-height: 1.31;
      letter-spacing: -1.5px;
      color: $color-slate;
    }

    &-label,
    .order-confirmation__support-label p {
      margin-bottom: 10px;
      ul {
        list-style: none;
        margin-left: 0;
        padding-left: 0;
        li {
          margin-top: 3px;
        }
      }
      a,
      .--phone {
        text-decoration: underline;
        display: inline-block;
        color: $color-slate;
        font-family: $font-medium;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.55px;
      }
    }
  }

  &__breadcrumb {
    margin-top: 30px;

    nav {
      font-family: $font-regular;
      font-size: 14px;
      line-height: 1.71;
      letter-spacing: -0.3px;
      color: $color-dark-gray;
    }
  }

  &__breadcrumb-link {
    cursor: pointer;
    font-family: $font-regular;
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: -0.3px;
    color: $color-dark-gray;
    text-decoration: none;
  }

  .cart-item {
    .cart-item__label {
      &.--quantity {
        line-height: 28px;
        color: $color-charcoal;
        margin-left: 45px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    &__message {
      padding-top: 50px;
      padding-bottom: 116px;
    }
  }

  @media screen and (max-width: 1023px) {
    &__container {
      margin-top: 20px;
    }

    &__message {
      padding-top: 50px;
      padding-bottom: 88px;
    }

    &__thank-you {
      font-size: 2.5rem;
      line-height: 2.875rem;
      letter-spacing: -2.5px;
      margin-bottom: 15px;
    }

    &__wrapper {
      display: grid;
      grid-template-columns: 1fr;
      column-gap: 0;
    }

    &__order-summary {
      grid-row-start: 3;
      grid-row-end: 3;
      width: 100%;
      max-width: 100%;
      margin: 30px 0 0 0;
    }

    &__title {
      font-size: 28px;
      line-height: 36px;
      letter-spacing: -1.98px;
    }

    &__info-row {
      row-gap: 20px;
      display: flex;
      flex-wrap: wrap;
      margin-top: 30px;

      @media screen and (max-width: 767px) {
        margin-top: 20px;
      }
    }

    &__details {
      margin-bottom: 5px;

      span {
        font-family: $font-regular;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.55px;
      }
    }

    @media screen and (max-width: 767px) {
      &__email {
        span {
          letter-spacing: -0.35px;
        }
      }
    }

    &__customer-info {
      margin-top: 0px;
      margin-bottom: 25px;
    }

    &__shipment-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__tooltip-wrapper {
      bottom: 25px;
      left: unset;
      right: 0;
    }

    &__buttons {
      display: flex;
      width: 100%;
      row-gap: 20px;
      flex-wrap: wrap;

      .button {
        width: 100% !important;
        margin-bottom: 0;
      }
    }

    &__support {
      border: none;
      border-radius: 0;
      padding: 50px 0 60px 0;
      margin: 0;

      &-help {
        padding-bottom: 25px;
      }

      &-call {
        padding-top: 25px;
        text-align: left;
      }
    }
  }

  @media screen and (max-width: 767px) {
    &__support {
      &-help {
        padding-bottom: 40px;
      }

      &-call {
        padding-top: 40px;
        padding-bottom: 20px;
      }
    }

    .combo-cart-items {
      .cart-item__link {
        margin-top: 0px;
      }
    }

    .cart-item {
      .cart-item__label {
        &.--quantity {
          text-align: center;
          margin-bottom: 0;
          line-height: 22px;
        }
      }

      &__qty-price {
        margin: 0 !important;
        justify-content: space-evenly;
      }

      &__total {
        float: right;
        width: 50% !important;
      }

      &__link {
        margin-top: 2px;
      }

      &__email {
        display: none !important;
      }

      &__total-price {
        display: block !important;
        letter-spacing: -0.8px;
      }

      &__note {
        &.--e-delivery {
          margin-top: 5px;
        }
      }
    }
  }

  .userExistAccount {
    .order-confirmation__customer-info {
      h2 {
        margin: 80px 0 32px 0;

        @media screen and (max-width: 1023px) {
          margin: 50px 0 25px 0;
        }
      }
    }
  }

  .viewOrderButton {
    font-size: 1.25rem;
    font-family: $font-medium;
    line-height: 1.25rem;
    letter-spacing: -0.025rem;
    color: $color-slate;
    margin: 5px 0 0 0;
    text-decoration: underline;
    cursor: pointer;
  }

  &__order_detail_title {
    font-size: 2.5rem;
    font-family: $font-light;
    line-height: 2.875rem;
    letter-spacing: -2.5px;
    color: $color-slate;
  }

  &__buttons {
    &.custom_button {
      .kf-react-button.button,
      .order-confirmation__download-btn {
        background: unset !important;
        padding: 0 0 5px 0 !important;
        height: unset !important;
        font-size: 1.25rem;
        line-height: 1.25rem;
        font-family: $font-medium;
        letter-spacing: -0.025rem;
        border-bottom: 1px solid $color-slate;
        border-top: unset;
        border-left: unset;
        border-right: unset;

        @media screen and (max-width: 1024px) {
          width: auto;
          margin-bottom: 0px;
        }
      }
    }
  }

  &__buttons {
    &.custom_button {
      div.order-confirmation__download-btn {
        border-bottom: unset !important;
      }
    }
  }

  &__order_title_printsec {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
    }
  }
}

.order-items {
  margin-top: 40px;
  &.return-order-items{
    margin-top: 3.75rem;
  }

  &__details {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid $dark-gray;

    .order-items__shipment {
      font-family: $font-bold;
      font-size: 16px;
      line-height: 17px;
      letter-spacing: 1.45px;
      color: $color-slate;
      text-transform: uppercase;
      width: fit-content;

      @media screen and (max-width: 767px) {
        display: block;
        width: 100%;
      }
    }
    .order-items__links {
      flex-wrap: nowrap;
      width: auto;
      column-gap: 30px;
      font-family: $font-medium;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.35px;
      color: $color-slate;
      @media screen and (max-width: 767px) {
        margin-top: 10px;
        flex-direction: row-reverse;
        justify-content: flex-end;
      }
    }
    .order-items__invoice {
      text-decoration: underline;
      cursor: pointer;
      width: fit-content;
      text-underline-offset: 4px;
    }
    .order-items__status {
      width: fit-content;
    }
    .order-items__return {
      font-family: $font-medium;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.0219rem;
      color: $color-slate;
      display: inline-block;
      margin-right: 1.875rem;
      border-bottom: 1px solid $color-slate;
      cursor: pointer;
      @media screen and (max-width: 767px) {
        margin-top: 15px;
      }
    }
  }

  &__td {
    padding-bottom: 20px;
    margin-top: 15px;
    font-family: $font-regular;
    font-size: 14px;
    line-height: 1.57;
    letter-spacing: -0.65px;
    color: $color-dark-gray;
  }

  .cart-items {
    &__email {
      display: none !important;
    }

    &__th {
      > .order-items {
        &__td {
          &:first-child {
            visibility: hidden;

            @media only screen and (max-width: 480px) {
              display: none;
            }
          }
        }
      }
    }
  }

  .combo-cart-items {
    .cart-item {
      padding: 0px;
    }
  }

  .cart-item {
    &__display-discount {
      display: none;
    }

    .order-complete-item-price {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.55px;
      color: $slate;

      @media screen and (max-width: 767px) {
        display: none;
      }
    }

    &__color {
      margin: 8px 0 0;
    }

    @media screen and (max-width: 767px) {
      &__info {
        margin: 10px 0 0 0;
      }
    }

    &__label {
      margin-bottom: 0;
      color: $slate;

      @media screen and (max-width: 767px) {
        letter-spacing: -0.8px;
        color: $charcoal;
      }
    }

    &__link {
      a,
      span {
        font-family: $font-medium;
      }
    }

    &__item-of {
      padding: 15px 31px 15px 0;
    }

    &__qty-price {
      .cart-item__discount-container {
        padding-right: 10px;

        @media only screen and (max-width: 480px) {
          padding-right: 0px;
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .cart-item {
      &__item-of {
        padding: 15px 31px 15px 0;
      }
    }
  }
}

@media print {
  .order-confirmation {
    &__print,
    &__buttons,
    &__breadcrumb,
    &__message,
    &__support-help {
      display: none !important;
    }

    &__shipment-title {
      img {
        display: none !important;
      }
    }

    .cart-item {
      &__link {
        span {
          display: none !important;
        }
      }

      &__sku {
        white-space: nowrap;
        display: block;
      }

      &__image-link {
        margin-right: 15px;
      }
    }

    &__order-summary {
      &.sticky-order {
        position: relative;
      }
    }
  }

  .carousel-slick.carousel.carousel-promo-banner.bg-slate {
    display: none !important;
  }
}

.mobile-combo-cart-item {
  display: none;
}

.desktop-combo-cart-item {
  display: inline-block;
}

@media only screen and (max-width: 1023px) {
  .mobile-combo-cart-item {
    display: block;
  }

  .desktop-combo-cart-item {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .cart-item {
    padding-bottom: 0;

    .cart-item__label {
      &.--mobile {
        display: block;
      }

      &.--quantity {
        margin-left: 0 !important;
      }
    }

    &__qty {
      .cart-item__label {
        &.--mobile {
          text-align: left;
          margin-bottom: 10px;
        }
      }
    }

    &__label {
      &.--mobile {
        display: block;
        margin-bottom: 10px;
      }
    }

    .order-confirmation {
      .cart-item {
        .cart-item__label {
          &.--quantity {
            margin-left: 20px;
          }
        }
      }
    }

    .combo-cart-items {
      padding-bottom: 0;

      &__note {
        padding-left: 0px !important;
      }
    }

    .order-confirmation__shipment {
      margin-top: 50px;
    }
  }

  .order-items {
    &__td {
      margin-top: 10px !important;
    }
  }

  .order-confirmation {
    .combo-cart-items {
      padding: 0 0 30px 160px;

      @media screen and (max-width: 767px) {
        padding: 0 0 30px 91px;
      }

      @media only screen and (min-width: 1024px) and (max-width: 1120px) {
        padding: 0 0 30px 100px;
      }

      border-bottom: 1px solid $light-gray;

      .cart-item {
        padding: 0 !important;

        &__info {
          display: none;
        }
      }
    }

    @media screen and (max-width: 767px) {
      .hide-mobile-checkout-section {
        display: none;
      }
    }
  }

  .cart-item__display-discount {
    &.--mobile {
      display: none !important;
    }
  }
}

.font-medium {
  font-family: $font-medium !important;
}

.font-bold {
  font-family: $font-bold !important;
}

.show-mobile-total-price {
  display: none;

  @media screen and (max-width: 767px) {
    border-top: 1px solid $light-gray;
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    margin-top: 20px;
  }
}

//Order Confirmation
@import "@/styles/base/_colors";
@import "@/styles/base/_fonts";
@import "@/styles/mixins/_breakpoint";

.Thankyou {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 55px 0 0 0;

  @include breakpoint(tab-max) {
    padding: 30px 0 0 0;
  }

  font-family: $font-regular;

  :global {
    .thankyou-container {
      max-width: 872px;

      @include breakpoint(tab-max) {
        max-width: 612px;
      }

      .title {
        font-size: 45px;
        line-height: 56px;
        letter-spacing: -3.187px;
        color: $slate;
        font-weight: 300;

        @include breakpoint(tab-max) {
          font-size: 28px;
          line-height: 36px;
          letter-spacing: -1.983px;
        }
      }

      .order-number {
        font-size: 20px;
        margin: 30px 0 20px 0;
        line-height: 28px;
        letter-spacing: -0.65px;
        color: $slate;
        font-weight: 400;

        span {
          font-weight: 500;
          color: $charcoal;
          letter-spacing: -0.4px;
        }
      }

      .order-message {
        margin-bottom: 20px;
        font-size: 16px;
        letter-spacing: -0.4px;
        font-weight: 400;
        line-height: 24px;
        color: $slate;

        span {
          color: $charcoal;
          letter-spacing: -0.35px;
          font-weight: 500;
        }
      }

      .order-info {
        p {
          font-size: 16px;
          letter-spacing: -0.4px;
          font-weight: 400;
          line-height: 24px;

          span {
            color: $charcoal;
            letter-spacing: -0.35px;
            font-weight: 500;

            + span {
              color: $slate;
              font-weight: 400;
            }
          }

          + p {
            margin-top: 10px;
          }
        }
      }

      .btn-group {
        display: flex;
        align-items: center;
        margin: 40px 0 70px 0;
        justify-content: center;

        @include breakpoint(tab-max) {
          margin: 40px 0 50px 0;
        }

        @include breakpoint(mobile) {
          width: 100%;
          display: block;
        }

        gap: 20px;

        @include breakpoint(tab-max) {
          button {
            &.button-hide-mobile {
              display: none;
            }
          }
        }

        @media print {
          display: none !important;
        }

        .view-order-button,
        button {
          border: 1px solid $charcoal;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          text-decoration: none;
          white-space: nowrap;
          padding: 0 40px;
          height: 40px;
          min-width: 207px;
          -webkit-transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          transition: all 0.3s ease;
          line-height: 26px;
          letter-spacing: -0.35px;
          font-size: 18px;
          color: $charcoal;
          font-weight: 500;

          &:hover {
            background-color: $color-off-white-dark;
            transform: scale(0.95);
          }

          @media screen and (max-width: 1023px) {
            min-width: 100%;
            width: 100%;
          }
        }
      }
    }
  }
}

// Order Details UI Enhancement
.order-confirmation {
  .kf-react-container.order-confirmation__container {
    padding: 0 !important;
    max-width: 100% !important;
    margin: 0;
  }

  .order-confirmation {
    &__wrapper {
      width: 100%;

      @include breakpoint(mobile-potrait) {
        display: block;
      }
    }

    &__header-section {
      background-color: $color-off-white;
      padding: 0 2.6%;

      @include breakpoint(desktop-large) {
        padding: 0 50px;
      }

      @include breakpoint(mobile-landscape) {
        padding: 0 32px;
      }

      @include breakpoint(mobile-potrait) {
        padding: 0 18px;
      }
    }

    &__header-wraper {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      max-width: 1504px;
      margin: 0 auto;
      padding: 3.4375rem 0;

      @include breakpoint(tab-landscape) {
        flex-direction: column;
        align-items: flex-start;
        padding: 30px 0;
      }
    }

    &__header {
      padding: 0;
      margin: 0;
    }

    &__title {
      @include text-style($font-light, 2.8125rem, 3.5rem, -3.187px);
      color: $color-slate;

      @include breakpoint(tab-landscape) {
        @include text-style($font-light, 28px, 36px, -1.983px);
      }
    }

    &__info-header {
      display: flex;
      padding-top: 1.875rem;

      @include breakpoint(mobile-potrait) {
        flex-direction: column;
      }
    }

    &__details {
      @include text-style($font-regular, 1rem, 1.5rem, -0.55px);
      color: $color-dark-gray;
      margin: 0;

      .font-medium {
        @include text-style($font-medium, 1rem, 1.5rem, -0.35px);
        color: $color-slate;
      }

      &:last-child {
        margin: 0 0 0 2.5rem;

        @include breakpoint(mobile-potrait) {
          margin: 20px 0 0;
        }
      }
    }

    &__buttons {
      margin: 0;
      padding: 0;
      display: flex;
      column-gap: unset;

      @include breakpoint(tab-landscape) {
        row-gap: unset;
        padding-top: 30px;
      }

      @include breakpoint(mobile-landscape) {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        width: auto !important;
      }
    }

    &__download-btn {
      .order-confirmation__download-btn {
        margin: 0 0 0 1.25rem;
        @include text-style(
          $font-medium !important,
          0.875rem !important,
          1.375rem !important,
          -0.3px !important
        );
        color: $color-slate;
        border-radius: 0;
        border: 1px solid $color-slate;
        padding: 0 1.875rem;
        height: 40px !important;
        width: auto !important;

        @include breakpoint(tab-landscape) {
          margin: 0;
        }

        @include breakpoint(mobile-potrait) {
          width: 100% !important;
        }
      }
    }

    &__print {
      .order-confirmation__print {
        @include text-style(
          $font-medium !important,
          0.875rem !important,
          1.375rem !important,
          -0.3px !important
        );
        color: $color-slate;
        border-radius: 0;
        border: 1px solid $color-slate;
        padding: 0 1.875rem;
        height: 40px !important;
        width: auto !important;
      }
    }

    &__register-btn {
      .order-confirmation__register-btn {
        @include text-style(
          $font-medium !important,
          0.875rem !important,
          1.375rem !important,
          -0.3px !important
        );
        color: $color-slate;
        border-radius: 0;
        border: 1px solid $color-slate;
        padding: 0 1.875rem;
        margin: 0 0 0 1.25rem;
        height: 40px !important;
        width: auto !important;

        @include breakpoint(mobile-potrait) {
          margin: 0 0 20px 0;
          width: 100% !important;
        }

        @include breakpoint(mobile-landscape) {
          margin: 0 20px 0 0;
        }
      }
    }

    // Content Section
    &__content-section {
      padding: 0 3.0313rem;

      @include breakpoint(desktop-large) {
        padding: 0 50px;
      }

      @include breakpoint(mobile-landscape) {
        padding: 0 32px;
      }

      @include breakpoint(mobile-potrait) {
        padding: 0 18px;
      }

      .order-items {
        padding: 0;

        &__shipment {
          @include text-style($font-bold, 1rem, normal, 1.455px);
          color: $color-slate;
        }

        &__status {
          @include text-style($font-medium, 1rem, 24px, -0.35px);
          color: $color-slate;
          text-transform: capitalize;
        }

        &__details {
          border: none;
        }

        @include breakpoint(tab-landscape) {
          margin-top: 30px;
        }
      }

      .cart-item {
        border-top: 1px solid $color-dark-gray;
        border-bottom: unset;
        .order-confirm-related {
          margin-bottom: 1.875rem;
          padding-right: 0;
          @media screen and (max-width: 767px) {
            padding-top: 0.625rem;
            padding-right: 0.9375rem;
          }
        }
        .cart-item__return-btn-sec {
          display: flex;
          justify-content: end;
        }
        .return__service-button {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          text-decoration: none;
          border: none;
          white-space: nowrap;
          padding: 0 30px;
          font-size: 14px;
          height: 2.5rem;
          transition: all 0.3s ease;
          font-family: $font-medium;
          line-height: 1.33;
          letter-spacing: -0.35px;
          width: 100%;
          background-color: transparent;
          border: 1px solid $color-slate;
          color: $color-slate;
          &:hover {
            background-color: $color-off-white-dark;
            transform: scale(0.9);
          }
          img {
            margin-right: 0.625rem;
          }
        }
        .order-confirm-eligible {
          background-color: $color-off-white;
          padding: 1.6875rem 0.9375rem;
          display: flex;
          align-items: baseline;
          border-left: 5px solid $color-kohler-blue;

          @media screen and (max-width: 767px) {
            padding: 0.9375rem;
          }
          img {
            width: 1.25rem;
            height: 1.25rem;
          }
          .return-confirm-info {
            padding-left: 0.9375rem;
          }
          p {
            font-size: 1.75rem;
            font-family: $font-light;
            line-height: 2.25rem;
            letter-spacing: -0.0875rem;
            margin-bottom: 0.3125rem;
          }
          span {
            font-size: 1rem;
            font-family: $font-regular;
            line-height: 1.5rem;
            letter-spacing: -0.0344rem;
          }
        }

        &__image-link {
          margin-right: 1.25rem;
          background: #f9f9f9;

          &-img {
            width: 9.375rem;
            height: 9.375rem;
            mix-blend-mode: multiply;
            object-fit: scale-down;
          }

          @include breakpoint(mobile-potrait) {
            margin-right: 15px;

            &-img {
              width: 100px;
              height: 100px;
            }
          }
        }

        &__brand {
          margin-bottom: 2px;
        }

        &__brand-name {
          @include text-style($font-medium, 1rem, 1.5rem, -0.35px);
          color: $color-dark-gray;
          text-transform: uppercase;
          width: auto;
          margin: 0;
        }

        &__name {
          @include text-style($font-medium, 1rem, 1.5rem, -0.4px);
          color: $color-slate;
          margin: 0;
          &.cart-item__installation-name{
            font-size: 1rem;
            line-height: 1.5rem;
            letter-spacing: -0.55px;  
            font-family: $font-regular;
            color: $color-slate;
          }
        }

        &__desc {
          @include text-style($font-medium, 1rem, 1.5rem, -0.4px);
          color: $color-slate;
          margin: 0;
        }

        &__color,
        &__sku,
        .order-complete-item-price {
          @include text-style($font-regular, 0.875rem, 1.375rem, -0.45px);
          color: $color-slate;
          margin-top: 5px;
        }

        &__color,
        &__sku {
          margin-top: 5px;
        }

        &__sku {
          display: block;

          @include breakpoint(mobile-potrait) {
            float: unset;
            text-align: left;
          }
        }

        &__qty-mobile {
          @include breakpoint(mobile-potrait) {
            width: 80px;
          }
        }

        &__info {
          padding-right: 0;
          @include breakpoint(mobile-potrait) {
            padding-right: 1.125rem;
          }
          .cart-item__link {
            &.cart-item__track-btn {
              display: flex;
              justify-content: flex-end;

              a {
                @include text-style($font-medium, 0.875rem, 1.375rem, -0.3px);
                padding: 0 1.875rem;
                color: $white;
                background-color: $color-charcoal;
                height: 40px;
                display: flex;
                align-items: center;
                width: max-content;
                justify-content: flex-end;
                text-decoration: unset;
                font-weight: 500;
                width: 10rem;

                @include breakpoint(mobile-potrait) {
                  width: 100%;
                  align-items: center;
                  justify-content: center;
                }
              }

              &.disabled > a {
                cursor: not-allowed;
                pointer-events: none;
                background-color: $color-light-gray;
                color: $color-white;
              }
            }

            @include breakpoint(mobile-potrait) {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            &.cart-item__track-number {
              @include text-style($font-medium, 0.875rem, 1.375rem, -0.45px);
              color: $color-slate;
              text-decoration: underline;
            }
          }
          .cart-item__return-btn {
            display: flex;
            justify-content: flex-end;
          }
          button.cart-item__return-btn {
            margin-bottom: 0.9375rem;
            width: 10rem;
            display: flex;
            justify-content: center;

            @include breakpoint(mobile-potrait) {
              margin-top: 1.25rem;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          .cart-item__qty {
            .cart-item__label {
              span {
                @include breakpoint(mobile-landscape) {
                  line-height: 24px;
                }

                @include breakpoint(mobile-potrait) {
                  line-height: 24px;
                  letter-spacing: -0.4px;
                }
              }
            }
          }

          .cart-item__total-price {
            @include breakpoint(mobile-landscape) {
              line-height: 24px;
            }
          }
        }

        &__links {
          @include breakpoint(mobile-potrait) {
            margin-top: 20px;
          }
        }

        &__name-desc {
          margin-bottom: 15px;
          display: inline-block;
        }

        .order-complete-item-price {
          .cart-item__span {
            @include text-style($font-regular, 0.875rem, 1.375rem, -0.3px);
            color: $color-dark-gray;
          }
        }
        .modal-popup .modal-popup-content .modal-popup-body {
          padding: 55px 50px 50px;
          max-width: 500px;
          h3 {
            color: $color-slate;
            font-family: $font-bold;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 1.818px;
            text-transform: uppercase;
            padding-bottom: 15px;
          }
          p {
            color: $color-dark-gray;
            font-family: $font-medium;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
            letter-spacing: -0.55px;
            padding-bottom: 30px;
          }
          .return__modal-cta {
            display: flex;
            justify-content: space-between;
            .return__modal-button {
              width: 100%;
              &:first-child {
                margin-right: 0.9375rem;
              }
            }
          }
        }
      }

      .combo-cart-items {
        border: none;
        padding-bottom: 0;

        @include breakpoint(mobile-potrait) {
          padding-left: 0;
        }

        &__note {
          border: none;
          padding: 0;
          margin-top: 20px;
        }

        .cart-item {
          border: none;
        }
      }
    }

    &__product-details {
      display: flex;
      justify-content: space-between;
      max-width: 1504px;
      margin: 0 auto;
      padding: 3.4375rem 0 7.5rem;

      @include breakpoint(tab-landscape) {
        padding: 30px 0 60px;
        flex-direction: column;
      }
    }

    &__content {
      display: flex;
      flex-wrap: unset;
      width: 68.36%;

      @include breakpoint(tab-landscape) {
        width: 100%;
      }
    }

    &__order-summary {
      width: 26.27%;
      margin-top: 69px;

      @include breakpoint(tab-landscape) {
        width: 100%;
        margin-top: 60px;
      }

      .order-summary {
        padding-bottom: 5rem;

        @include breakpoint(tab-landscape) {
          padding-bottom: 3.75rem;
        }

        &__title {
          line-height: normal;
          letter-spacing: 1.818px;
          margin-bottom: 1.25rem;
          font-weight: 700;
        }

        &__item--label {
          letter-spacing: -0.4px;
        }

        &__item--value {
          letter-spacing: -0.4px;
        }

        &__item.estimated-total {
          .order-summary__item--label,
          .order-summary__item--value-large {
            letter-spacing: -0.35px;
          }
        }
      }
    }

    &__shipment-details {
      order: unset;
      width: 100%;
    }

    &__shipment {
      margin: 80px 0 0 0;
      padding: 0;

      @include breakpoint(tab-landscape) {
        margin: 60px 0 0 0;
      }

      &:first-child {
        margin: 0;
      }
    }

    &__email {
      padding: 30px 0;
      border-top: 1px solid $color-dark-gray;
      display: flex;
      flex-direction: column;
      @include text-style($font-regular, 16px, 24px, -0.55px);
      color: $color-slate;
    }

    &__info-row {
      display: block;
      column-gap: unset;
      margin: 0;
    }

    &__info-tile {
      padding: 30px 0;
      border-top: 1px solid $color-dark-gray;

      p {
        @include text-style($font-medium, 16px, 24px, -0.35px);
        color: $color-slate;
      }
    }

    &__info-list {
      li {
        @include text-style($font-regular, 16px, 24px, -0.55px);
        color: $color-slate;
      }
    }

    &__support {
      padding: 0;
      margin: 0;
    }

    &__support-help,
    &__support-call {
      padding: 30px 0;
      border-top: 1px solid $color-dark-gray;
      border-bottom: none;
    }

    &__support-title {
      @include text-style($font-medium, 16px, 24px, -0.35px);
      color: $color-slate;
      margin-bottom: 5px;
    }

    &__main-title {
      @include text-style($font-bold, 20px, normal, 1.818px);
      color: $color-slate;
    }

    &__support-call {
      @include breakpoint(mobile-potrait) {
        padding-bottom: 0;
      }
    }

    &__sub-title {
      @include text-style($font-regular, 0.875rem, 1.375rem, -0.45px);
      color: $color-slate;
      margin-top: .625rem;

      @include breakpoint(tab-landscape) {
        width: 100%;
      }
    }
  }
}
