.kf-lazyload-wrapper {
  min-height: auto;
}
.product-list {
  &__container {
    max-width: 86.25rem;
  }

  &__no-results {
    * {
      font-family: "Helvetica Now Text W05 Regular";
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.65px;
      color: $color-dark-gray;
    }
    a {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  &__header {
    margin-bottom: 43px;
  }

  &__curated {
    padding-top: 3.75rem !important;
  }

  &__title-info {
    margin-top: 0;
    height: 9.563rem;
  }

  &__title {
    font-family: $font-light;
    font-size: 60px;
    line-height: 1.1;
    letter-spacing: -4.45px;
    color: $color-slate;
    margin-top: 10px;
    word-break: break-word;
    p,
    h1,
    h2,
    h3 {
      font-family: $font-light;
      font-size: 60px;
      line-height: 1.1;
      letter-spacing: -4.45px;
      color: $color-slate;
      word-break: break-word;
    }
  }

  &__transparent-button {
    background: none;
    border: none;
    outline: none;
    margin-left: 20px;
  }

  &__total {
    bottom: 12px;
    padding-top: 18px;
    font-family: $font-light;
    font-size: 2rem;
    max-height: 2.5rem;
    line-height: 1.25;
    letter-spacing: -1.5px;
    color: $color-slate;
  }

  &__filter-toggle {
    display: flex;
    align-items: center;
    font-family: $font-regular;
    font-size: 18px;
    line-height: 1.33;
    letter-spacing: -0.45px;
    color: $color-charcoal;
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    outline: none;

    &-text {
      list-style: none;
      padding: 0;
      margin: 0;
      text-align-last: left;
      height: 24px;
      overflow: hidden;

      li {
        height: 24px;
        transition: all 0.3s ease-in-out;

        &.hide {
          opacity: 0;
          transform: scale(0.9);
        }

        &.show {
          opacity: 1;
        }
      }

      li:last-child {
        transform: translateY(-30%);
      }

      &--show {
        li:last-child {
          transform: translateY(-100%);
        }
      }
    }

    &-icon {
      width: 21px;
      height: 20px;
      margin-right: 10px;
    }
  }

  &__close-icon {
    width: 16px;
    height: 16px;
  }

  &__sticky-nav-wrap {
    position: relative;
    z-index: 2;
    padding-bottom: 20px;
  }

  &__sticky-nav {
    background: $color-white;
    transition: all 0.3s ease;
    padding-right: 15px;
    padding-left: 15px;

    &--shadow {
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.07);
    }
  }

  &__controls {
    display: flex;
    justify-content: flex-start;
    height: 65px;
    align-items: center;

    .control-left-section {
      width: 300px;
      position: absolute;
    }

    .control-right-section {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      margin-left: 350px;
      position: relative;
    }
    .sort {
      position: relative;
      &__drop-down {
        top: 46px;
      }
      &__item {
        &:hover {
          background-color: $color-off-white;
        }
        &.active {
          background-color: $color-off-white;
          color: $color-dark-gray;
        }
      }
    }
    section {
      display: flex;
      min-height: 65px;
      align-items: center;
    }
  }

  &__filter {
    width: 300px;
    height: 100%;
    position: absolute;
    opacity: 1;
    transition: all 0.5s ease-in-out;

    &-curated {
      .filter__print-and-share {
        margin-top: 25px;
      }
    }
    &--hide {
      opacity: 0;
    }

    &--sticky {
      position: sticky;
      top: 80px;
      overflow: hidden;
    }
    .social-share {
      position: relative;
      top: -80px;
    }
  }
  &__tiles {
    margin-top: 10px;
    margin-bottom: 120px;
    @media only all and (min-width: 1024px) and (max-width: 1100px) {
      margin-bottom: 50px;
    }
  }

  &__tiles-knowledge-articles {
    margin-bottom: 63px;
  }

  &__tile-col {
    transition: all 0.5s ease-in-out;
    margin-left: 359px;
    min-height: calc(100vh - 100px);
    position: relative;
    &--full-width {
      margin-left: 15px;
    }
  }

  &__tile {
    margin-bottom: 70px;
    padding: 0 2.5px;
    a {
      &:hover {
        text-decoration: none;
      }
    }
  }

  &__load-more {
    width: 100%;
    background: $color-white;
    border: solid 1px $color-slate;
    font-family: $font-medium;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.25px;
    text-align: center;
    color: $color-slate;
    transition: all 0.3s ease-in-out;
    margin-bottom: 40px;
    padding: 8px;
    margin-left: auto;
    margin-right: auto;
  }

  &__back-to-top {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  &__print-and-share {
    font-family: $font-regular;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.55px;
    color: $color-dark-gray;
    display: flex;

    .social-share__box {
      top: 30px;
      transform: translateX(-50%);
    }

    span {
      margin-right: 30px;
      display: flex;
      align-items: center;

      img {
        height: 16px;
        margin-right: 10px;
      }
    }
  }

  &__go-back {
    display: flex;
    font-family: $font-regular;
    font-size: 18px;
    line-height: 1.33;
    letter-spacing: -0.45px;
    color: $color-charcoal;
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    outline: none;

    img {
      height: 20px;
      margin-left: 15px;
    }
  }
  @media all and (min-width: 1440px) {
    .kf-react-container {
      max-width: 1380px !important;
      padding: 0px !important;
      .row {
        margin: 0;
        .col {
          padding: 0;
        }
        .product-list {
          &__tile {
            padding: 0 2.5px 0;
            &-col--full-width {
              margin-left: 0px;
            }
          }
        }
      }
    }
  }
}

.sorting-section {
  margin-right: -8px;
}

//products per page
.products-per-page {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media all and (max-width: 768px) {
    display: none;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: inline-block;
      font-family: $font-regular;
      font-size: 1.125rem;
      line-height: 1.33;
      letter-spacing: -0.45px;
      color: $color-charcoal;
      margin-right: 15px;
      cursor: pointer;

      &.disabled {
        pointer-events: none;
        color: $color-dark-gray;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

//sort
.sort {
  margin-left: 5rem;
  display: flex;
  justify-content: flex-end;

  &__toggle {
    display: flex;
    align-items: center;
    position: relative;
    font-family: $font-regular;
    font-size: 1.125rem;
    line-height: 30px;
    letter-spacing: -0.45px;
    color: $color-charcoal;
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    outline: none;

    &-icon {
      width: 16px;
      height: 9px;
      margin-left: 15px;
      transform: rotateX(180deg);
      transition: transform 0.5s ease-in-out;
    }
  }

  &__drop-down {
    max-height: 0px;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: $color-white;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0.15);
    list-style: none;
    padding: 0;
    overflow: hidden;
    transition: max-height 0.1s ease-in-out;
    width: max-content;
  }

  &__item {
    font-family: $font-regular;
    font-size: 18px;
    line-height: 1.33;
    letter-spacing: -0.5px;
    text-align: left;
    color: $color-dark-gray;

    cursor: pointer;
    opacity: 0;
    padding: 10px 25px;
    transition: opacity 0.1s ease-in-out;
    margin: auto 2px;

    &:first-child {
      padding-top: 10px;
    }
    &:last-child {
      padding-bottom: 10px;
    }
    &:hover {
      background-color: $color-off-white;
    }
    &.active {
      background-color: $color-off-white;
      color: $color-dark-gray;
    }
  }

  &--open {
    .sort__drop-down {
      max-height: 280px;
      z-index: 10;
      padding-top: 15px;
      padding-bottom: 15px;
    }

    .sort__item {
      opacity: 1;
      margin: 1px; // to make keyboard focus visible
    }
  }

  @media all and (max-width: 576px) {
    margin-left: 0;

    &__drop-down {
      right: 15px;
    }
  }
}

//filter
.filter {
  height: 100vh;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: thin; // firefox scrollbar
  scrollbar-color: #757575 transparent; // firefox scrollbar color
  padding: 0 20px 50px 2px; //required for ADA focus border to be visible
  scrollbar-gutter: stable;
  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-thumb {
    background: $color-dark-gray;
    border-radius: 4px;
  }
  @media only all and (max-width: 991px) {
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &--show-less {
    .collapsible-outer {
      display: none;

      &:nth-child(-n + 5) {
        display: block;
      }
    }
  }

  &__mobile-header {
    display: none;
  }

  &__apply {
    display: none;
    width: 100%;
    height: 60px;
    background-color: $color-kohler-blue;
    font-family: $font-medium;
    font-size: 18px;
    line-height: 1.33;
    letter-spacing: -0.35px;
    color: $color-white;
    border: none;
    outline: auto;
    margin-top: 40px;
    margin-bottom: 40px;
    transition: all 0.5s ease;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;

    &-loader {
      border: 2px solid $color-kohler-blue-loader;
      border-left: 2px solid $color-white;
      animation: load 1s infinite linear;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      margin-right: 10px;
    }
  }

  @keyframes load {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  &__show-more {
    font-family: $font-regular;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.55px;
    color: $color-dark-gray;
    padding: 0;
    margin: 0;
    margin-top: 15px;
    background: none;
    border: none;
    outline: none;
  }

  &__buying-guide {
    padding-top: 50px;
    padding-bottom: 25px;
    border-bottom: 1px solid $color-dark-gray;

    h3 {
      font-family: $font-medium;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: -0.35px;
      color: $color-charcoal;
      margin: 0;
    }
    a {
      font-family: $font-medium;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
      letter-spacing: -0.35px;
      color: $color-dark-gray;
      text-decoration: underline;
    }
  }

  &__hide-discontinued {
    margin-top: 25px;
  }

  &__print-and-share {
    font-family: $font-regular;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.55px;
    color: $color-dark-gray;
    display: flex;
    padding-top: 25px;
    padding-bottom: 80px;

    span {
      margin-right: 30px;
      display: flex;
      align-items: center;
      cursor: pointer;

      img {
        height: 16px;
        margin-right: 10px;
      }
    }
  }

  &__chips {
    transition: all 0.5s ease;
    &-tag {
      display: inline-block;
      border-radius: 5px;
      border: solid 1px $color-medium-gray;
      font-family: $font-regular;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: -0.55px;
      color: $color-dark-gray;
      padding: 8px 15px;
      vertical-align: middle;
      margin-bottom: 10px;
      margin-right: 10px;
    }

    &-close-icon {
      width: 10px;
      height: 10px;
      margin-left: 15px;
      cursor: pointer;
    }

    &-clear-all {
      font-family: $font-medium;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: -0.35px;
      color: $color-dark-gray;
      text-decoration: underline;
      margin-top: 5px;
      margin-bottom: 25px;
      cursor: pointer;
    }
  }

  .social-share__box {
    position: relative;
  }

  @media all and (max-width: 768px) {
    &__print-and-share {
      display: none;
    }
    &__title-info {
      margin-top: 0;
      height: 145px;
    }
    &__buying-guide {
      font-size: 14px;
      line-height: 22px;
      margin-top: 40px;
      margin-bottom: 150px;
      padding-bottom: 0;
      border-bottom: none;
    }
    &__mobile-header {
      display: flex;
    }
    &__apply {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }

  .Collapsible {
    border-bottom: 1px solid $color-light-gray;

    &.is-open {
      .Collapsible__contentInner {
        opacity: 1;
        .filter-list {
          margin: 0;
          li {
            list-style: none;
            .checkbox {
              margin-left: 2px;
              margin-top: 5px;
              padding: 0 2px;
            }
          }
        }
      }
    }

    &__trigger {
      width: 100%;
      display: block;
      font-family: $font-medium;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: -0.35px;
      color: $color-charcoal;
      padding: 20px 0px;
      cursor: pointer;

      div {
        display: flex;
        justify-content: space-between;

        .plus {
          width: 20px;
          height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;

          .line {
            background: $color-charcoal;
            transition: all 0.5s ease;

            &.line-1 {
              width: 100%;
              height: 1px;
            }

            &.line-2 {
              width: 1px;
              height: 100%;
              transform: translateX(-10px);
            }
          }
        }
      }

      &.is-open {
        .plus {
          .line {
            &.line-2 {
              height: 0;
            }
          }
        }
      }
    }

    &__contentInner {
      opacity: 0;
      transition: all 0.3s ease-in-out;
      padding-bottom: 15px;
    }
  }
}

#price-range {
  .price_range__description {
    font-family: $font-regular;
    font-size: 14px;
    line-height: 1.57;
    letter-spacing: -0.65px;
    color: $color-dark-gray;
    margin-bottom: 16px;
  }

  .input-wrap {
    display: grid;
    grid-template-columns: 1fr 40px 1fr;
    align-items: center;
    text-align: center;
    margin-bottom: 15px;

    span {
      font-family: $font-regular;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: -0.55px;
      color: $color-dark-gray;
    }

    input {
      width: 100%;
      height: 60px;
      border-radius: 4px;
      border: 1px solid $color-medium-gray;
      font-size: 16px;
      line-height: 1;
      letter-spacing: -0.5px;
      text-indent: 10px;
    }
    input.error {
      border: 1px solid #ff2a2a;
      color: $color-dark-gray;
    }
  }

  .error {
    font-family: $font-regular;
    font-size: 16px;
    line-height: 1.38;
    letter-spacing: -0.3px;
    color: #ff2a2a;
  }
}

.product-tile__wrapper {
  cursor: pointer;
  .social-share {
    position: absolute;
    right: 0;
    &__box {
      right: 5px;
      top: 50px;
    }
  }

  &__card {
    margin-top: 5px; //To make keyboard focus outline visible
    &__parts {
      &--no-compare {
        margin-top: 0;
      }
      .product-parts__viewer {
        color: $color-dark-gray;
        font-size: 16px;
        font-family: "Helvetica Now Text W05 Regular";
        line-height: 20px;
        text-align: right;
        text-decoration: underline;
      }
    }
  }

  &:hover,
  &.--hover {
    .product-tile__share {
      opacity: 1;
    }
    .product-tile {
      .product-tile__colors--count {
        opacity: 0;
      }
      .product-tile__colors--swatch,
      .product-tile__compare {
        opacity: 1;
        @media all and (min-width: 481px) and (max-width: 767px) and (orientation: portrait) {
          display: none;
        }
      }
    }
  }
}

//product tile
.product-tile {
  width: 100%;
  position: relative;

  &__image {
    width: 100%;
    padding-bottom: 130%; //for maintaining aspect ratio
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;

    div {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      mix-blend-mode: multiply; //temp style for demonstration
      transition: all 0.5s ease-in-out;
    }

    img:first-child {
      position: absolute;
      opacity: 1;
    }
    img:nth-child(2) {
      opacity: 0;
      transform: scale(1.03);
    }
    &:hover {
      .product-tile__image--hover {
        opacity: 0;
      }
      img:nth-child(2) {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  &__badge {
    position: absolute;
    z-index: 1;
    top: 15px;
    left: 25px;
    display: flex;
  }
  &__new {
    font-family: $font-regular;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.65px;
    color: $color-white;
    margin-bottom: 0px;
    padding: 9px 12px;
    background-color: $color-kohler-blue;
    text-align: center;
  }
  &__offer {
    background-color: $color-white;
    font-family: $font-regular;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.65px;
    color: $color-kohler-blue;
    margin-bottom: 0px;
    padding: 9px 12px;
    text-align: center;
  }

  &__details {
    display: flex;
    justify-content: space-between;
    word-break: break-word;
    div {
      &:first-child {
        flex: 1;
      }
    }
  }

  &__title {
    font-family: $font-regular;
    font-size: 16px;
    line-height: 1.38;
    letter-spacing: -0.4px;
    color: $color-charcoal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 5px;
  }

  &__description {
    font-family: $font-regular;
    color: $color-dark-gray;
    font-size: 16px;
    letter-spacing: -0.55px;
    line-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__startingat {
    padding-left: 12px;
    padding-right: 25px;
    color: $color-medium-gray;
    font-family: $font-medium;
    font-size: 14px;
    letter-spacing: -0.7px;
    text-align: right;
  }

  &__price {
    padding-left: 0px;
    padding-right: 25px;
    font-family: $font-bold;
    font-size: 16px;
    line-height: 1.38;
    letter-spacing: -0.8px;
    color: $color-charcoal;
    margin-bottom: 5px;
    text-align: right;
  }
  &__discount-price {
    padding-left: 0px;
    padding-right: 25px;
    font-family: $font-regular;
    font-size: 16px;
    line-height: 1.38;
    letter-spacing: -0.8px;
    color: $color-dark-gray;
    text-decoration: line-through;
    text-align: right;
  }
  &__colors {
    font-family: $font-regular;
    font-size: 16px;
    line-height: 1.38;
    letter-spacing: -0.4px;
    color: $color-dark-gray;
    margin-top: 15px;

    &--count {
      opacity: 1;

      transition: all 0.5s ease-in-out;
    }

    &--swatch {
      position: absolute;
      display: flex;
      z-index: 1;
      align-items: center;
      opacity: 0;
      transition: all 0.5s ease-in-out;
    }
  }

  &__color-swatch {
    img {
      width: 20px;
      height: 20px;
      border-radius: 5px;
      margin-right: 5px;
      display: block;
    }
  }

  &__compare {
    opacity: 0;
    transition: all 0.5s ease-in-out;
    margin-top: 15px;
    position: relative;
    @media all and (max-width: 992px) {
      opacity: 1;
    }
    .tooltip__container {
      position: absolute;
      z-index: 9;
      right: 0;
    }

    &-button {
      background: none;
      border: none;
      outline: none;
      color: $color-dark-gray;
      z-index: 100;

      &:hover {
        color: $color-dark-gray;
      }
    }
  }

  &__share {
    display: flex;
    position: absolute;
    z-index: 1;
    right: 25px;
    top: 15px;
    transition: all 0.5s ease;
    opacity: 0;

    &-icon,
    &-icon-plp {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 36px;
      height: 36px;
      background: $color-white;
      border-radius: 5px;
      margin-left: 5px;
      border-color: transparent;
      @media all and (max-width: 768px) {
        width: 20px;
        height: 20px;
        background: transparent;
        border-color: transparent;
      }
    }
  }

  &--lite {
    .product-tile__colors,
    .product-tile__share {
      display: none;
      @media all and (min-width: 768px) and (max-width: 992px) {
        display: flex;
      }
    }

    .product-tile__details {
      display: block;
      padding-right: 0px;
    }

    .product-tile__title {
      font-size: 14px;
      line-height: 1.43;
      letter-spacing: -0.25px;
    }

    .product-tile__price {
      font-size: 14px;
      padding-left: 0;
    }
    @media all and (max-width: 992px) {
      .product-tile__discount-price {
        padding-left: 0;
        font-size: 14px;
      }
      .product-tile__startingat {
        padding-left: 0;
        padding-top: 15px;
      }
      .product-tile_paddingtop {
        padding-top: 15px;
      }
    }
  }

  &--loading {
    .loading-image {
      width: 100%;
      padding-bottom: 130%;
      margin-bottom: 20px;
    }
    .loading-title {
      width: 75%;
      height: 10px;
      margin-bottom: 10px;
    }
    .loading-description {
      width: 70%;
      height: 10px;
      margin-bottom: 10px;
    }
    .loading-swatch {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 5px;
        margin-right: 5px;
      }
    }
  }
}
@media all and (min-width: 768px) and (max-width: 992px) {
  .product-tile__shareicon {
    display: none;
  }
}
@media only all and (min-width: 1024px) and (max-width: 1199px) {
  .product-list {
    &__filter {
      width: 230px;
      z-index: 1;
    }
    &__tile-col {
      margin-left: 260px;
    }
    &__container {
      max-width: 79.3rem;
    }

    &__buying-guide-mobile {
      display: none;
    }

    &__controls {
      .control-right-section {
        margin-left: 250px;
      }
      section {
        right: 30px;
        .sort__drop-down {
          right: -30px;
        }
      }
    }

    &__tile-col--full-width {
      margin-left: 15px;
    }
  }

  .product-tile {
    &__details {
      padding-right: 10px;
    }
    &__compare-main {
      display: block;
    }
  }
}
@media only all and (max-device-width: 1023px) and (orientation: landscape) {
  .product-list {
    &__title-info {
      height: auto;
    }

    &__control-section {
      margin-top: 45px;
    }
  }
}
@media only all and (max-width: 860px) and (orientation: landscape),
  only all and (min-width: 768px) and (max-width: 991px) {
  .product-list {
    &__print-and-share {
      display: none;
    }
    &__back-to-top {
      margin-bottom: 80px;
      border-top: 1px solid $color-light-gray;
      padding-top: 20px;
      justify-content: flex-end;
    }
    &__title {
      height: auto;
      font-size: 46px;
      line-height: 50px;
      letter-spacing: -3.4px;
      border-right: none;
      p,
      h1,
      h2,
      h3 {
        font-size: 46px;
        line-height: 50px;
        letter-spacing: -3.4px;
      }
    }
    &__tile-col {
      margin: auto 15px;
    }
    &__filter--sticky {
      top: 8px;
    }
    &__tiles {
      margin-top: 20px;
      margin-bottom: 0;
    }
    &__grid-control {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 20px;
      border-bottom: 1px solid $color-light-gray;
    }
    &__total {
      font-size: 26px;
      line-height: 34px;
      letter-spacing: -1.5px;
      padding-top: 0;
      bottom: 0;
    }
    &__filter {
      width: 100%;
      position: fixed;
      z-index: 999;
      top: 0;
      left: 0;
      background: $color-white;
      padding: 0 32px;
      display: none;
    }
    &__filter--modal {
      display: block;
    }
    &__header {
      margin-bottom: 0;
    }
    &__container {
      padding: 0 32px;
    }
    &__controls {
      justify-content: space-between;
    }
  }
  .filter {
    &__inner-container {
      padding-bottom: 40px;
    }
    &__footer {
      background: $color-white;
      position: absolute;
      width: 100%;
      bottom: 0;
      button {
        margin-top: 20px;
        width: 100%;
        margin-bottom: 35px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    }
    &__buying-guide,
    &__print-and-share {
      display: none;
      margin-top: 40px;
      margin-bottom: 65px;
    }
    &__mobile-header {
      display: flex;
      height: 94px;
    }
  }
  .product-list__tile {
    margin-bottom: 90px;
  }
  .product-tile {
    &__share {
      opacity: 1;
      top: 15px;
      right: 20px;
      &-icon {
        background: transparent;
        margin-left: 20px;
        width: auto;
        height: auto;
      }
    }
    &__colors--swatch,
    &__compare {
      opacity: 1;
    }
    &__colors--count {
      opacity: 0;
    }
  }
}
@media only all and (min-width: 768px) and (max-width: 991px) {
  .product-tile {
    &--lite &__colors {
      display: block;
    }
    &--lite &__description {
      display: -webkit-box;
    }
    &--lite &__details {
      display: flex;
    }
  }
  .product-list {
    &__buying-guide-mobile {
      display: none;
    }
    &__container.container {
      padding: 0px 32px;
    }
    &__controls {
      .control-right-section {
        margin-left: 150px;
      }

      section {
        right: 15px;
        .sort__drop-down {
          right: 0;
        }
      }
    }
  }
}
@media all and (max-width: 992px) {
  .product-list {
    &__header {
      margin-bottom: 0px;
    }
    &__description {
      line-height: 34px;
      font-size: 26px;
      letter-spacing: -1.15px;
      margin-top: 10px;
      margin-bottom: 90px;
    }
    &__sticky-nav-wrap {
      padding-bottom: 0px;
    }
  }

  .product-tile {
    &__wrapper {
      &__card {
        &__parts {
          margin-top: 0;
        }
      }
    }
  }
}
@media all and (max-width: 768px) {
  .product-tile__description {
    color: #757575;
    font-size: 14px;
    letter-spacing: -0.48px;
    line-height: 20px;
  }

  .product-list {
    &__curated {
      padding-top: 40px !important;
    }

    &__back-to-top {
      margin-bottom: 80px;
      border-top: 1px solid $color-light-gray;
      padding-top: 20px;
      justify-content: flex-end;
    }
    &__title {
      font-size: 40px;
      line-height: 46px;
      letter-spacing: -2.5px;
      border-right: none;
      height: auto;
      margin-bottom: 0;
      margin-top: 20px;
      p,
      h1,
      h2,
      h3 {
        font-size: 40px;
        line-height: 46px;
        letter-spacing: -2.5px;
      }
    }

    &__control-section {
      margin-top: 45px;
    }

    &__total {
      font-size: 26px;
      line-height: 34px;
      letter-spacing: -1.5px;
      padding-top: 0;
      bottom: 0;
    }

    &__title-info {
      height: auto;
    }

    &__filter {
      width: 100%;
      height: 100vh;
      position: fixed;
      z-index: 999;
      top: 0;
      left: 0;
      background: $color-white;
      padding-left: 18px;
      display: none;
      overflow: hidden;

      &--modal {
        display: block;
        // overflow: auto;
        bottom: 0;
      }
    }

    &__filter--sticky {
      position: relative;
      top: 9px;
    }

    &__tiles {
      margin-top: 20px;
      margin-bottom: 0;
    }

    &__container {
      padding-left: 18px;
      padding-right: 18px;
    }

    &__controls {
      .control-right-section {
        margin-left: 50%;
        justify-content: flex-end;
      }

      section {
        right: 15px;
        .sort__drop-down {
          right: 0;
        }
      }
    }

    &__tile-col {
      margin: auto 15px;
    }

    &__tile {
      margin-bottom: 50px;
    }

    &__load-more {
      margin-bottom: 40px;
      padding: 8px 30px;
      font-size: 14px;
    }

    &__tile-col--full-width {
      .product-tile__startingat {
        text-align: left;
      }
    }

    &__grid-control {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 20px;
      border-bottom: 1px solid $color-light-gray;

      &-icon {
        width: 17px;
        height: 17px;
        margin-left: 20px;
      }
    }
    &__tile-col {
      .row-cols-1 {
        .product-tile {
          &__share {
            opacity: 1;
            top: 15px;
            right: 20px;
          }
          &__share-icon {
            background: transparent;
            margin-left: 20px;
            width: auto;
            height: auto;
          }
          &__share-icon-plp {
            display: none;
          }
        }
      }
    }

    &__buying-guide-mobile {
      padding-top: 18px;
      padding-bottom: 25px;
      border-top: 1px solid $color-light-gray;
      border-bottom: 1px solid $color-light-gray;
      margin: 0 auto 40px auto;
      padding-left: 0;
      padding-right: 0;

      h3 {
        font-family: $font-medium;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.4px;
        color: $color-charcoal;
      }

      a {
        font-family: $font-medium;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.4px;
        color: $color-dark-gray;
        text-decoration: underline;
        display: block;
      }
    }

    .sorting-section {
      margin-right: -6px;
    }

    &__print-and-share {
      display: none;
    }
  }

  .product-tile {
    &__colors--swatch {
      opacity: 1;
    }
    &__details {
      padding-right: 0px;
    }
    &--lite {
      &__badge {
        top: 9px;
        left: 9px;
      }
      &__new {
        font-size: 10px;
        line-height: 10px;
        letter-spacing: 0.5px;
        padding: 6px 8px;
      }
      &__offer {
        font-size: 10px;
        line-height: 10px;
        letter-spacing: 0.5px;
        padding: 6px 8px;
      }
    }
    &__compare-main {
      min-height: 40px;
    }
    &__wrapper {
      &__card {
        &__parts {
          margin-top: 0;
          .product-parts__viewer {
            font-size: 16px;
          }
          &--lite {
            margin-top: 10px;
            .product-parts__viewer {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  .filter {
    &__inner-container {
      padding-bottom: 50px;
    }
    &__footer {
      background: $color-white;
      position: absolute;
      width: 100%;
      bottom: 0;
      button {
        margin-top: 20px;
        width: 100%;
        margin-bottom: 80px;
      }
    }
    &__mobile-header {
      display: flex;
      height: 30px;
      margin: 9px 0 40px;
      justify-content: space-between;
    }
  }
}

.promo-banner {
  height: 100% !important;
  margin-left: -15px;
  margin-right: -15px;

  iframe {
    width: 100%;
    height: 100%;
  }
}
@media all and (max-width: 480px) {
  .product-tile {
    &__share {
      opacity: 1;
    }

    &__price,
    &__discount-price {
      text-align: left;
    }
  }

  .product-tile--lite {
    .product-tile__share {
      opacity: 1;
      display: flex;
      &-icon {
        background: transparent;
        width: 20px;
        height: 20px;
      }
      > div:last-child {
        display: none;
      }
    }
    .product-tile__image {
      margin-bottom: 10px;
    }
  }
}
@media all and (max-width: 767px) and (orientation: portrait) {
  .product-tile__compare {
    display: none;
  }
}

@media (hover: none) {
  .product-list {
    &__load-more {
      &:hover {
        color: #494949 !important;
        text-decoration: none;
      }
    }
  }
}

/* Safari only */
@media all and (-webkit-min-device-pixel-ratio: 0) {
  .product-list {
    &__controls {
      justify-content: space-between;
      position: relative;
    }
  }
}
@media all and (min-width: 992px) {
  .product-list {
    &__print-and-share {
      display: none;
    }
  }
}

@media print and (min-width: 280px) {
  @page {
    size: A4;
  }

  .breadcrumb,
  .gbh-header-logo-menu,
  .newpar,
  .product-listing__filter {
    display: none !important;
  }

  .product-listing__row {
    display: block;
  }

  .product-listing__tile-col {
    margin-left: 0px !important;
    .row {
      margin-left: 0px !important;
    }
  }

  .product-tile__image {
    break-inside: avoid;
  }
  .product-list {
    &__filter,
    &__sticky-nav-wrap,
    &__load-more,
    &__back-to-top {
      display: none;
    }
    &__tile {
      break-inside: avoid;
      page-break-inside: avoid;

      &-col {
        .row {
          .promo-col {
            display: none;
          }
        }
      }
    }
  }

  .kf-promo-banner {
    display: none;
  }
}

// further changes required for this css
.plppage
  .breadcrumb.fc-slate
  .cmp-breadcrumb
  .cmp-breadcrumb__list
  .cmp-breadcrumb__item
  .cmp-breadcrumb__item-link {
  span,
  span p {
    color: $color-dark-gray !important;
  }
}

.plppage
  .breadcrumb.fc-slate
  .cmp-breadcrumb
  .cmp-breadcrumb__list
  .cmp-breadcrumb__item
  .cmp-breadcrumb-slash {
  padding: 0 0.25rem;
}

.sterling-product-button {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #212121;
  color: $color-white;
}
