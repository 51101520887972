@import "@/styles/base/_colors";
$header-height: 115px;

footer
  .gbh-footer-main-nav
  .gbh-footer-main-nav-link
  .footer-inner-head.gbh-expand
  .accordion
  .line.line-2 {
  height: 0;
}

.header-container {
  z-index: 1001;
}

.scroll-lock {
  .product-detail-page__left-sticky,
  .product-detail-page__right-sticky {
    position: relative !important;
  }
  .product-detail-page__left-sticky,
  .product-detail-page__right-sticky {
    position: relative !important;
  }
}

.gbh-header-logo-menu {
  z-index: 1000;
}

.cmp-tabs__tabpanel--active {
  animation: none;
}
@media (max-width: 1200px) {
  .cmp-tabs__tabpanel.cmp-tabs__tabpanel--active
    .gbh-product-menu.open
    .gbh-pro-menu__list {
    -webkit-animation: none;
  }
}

@media screen and (min-width: 1201px) {
  .auth-widget .auth-nav .auth-nav__menu:after {
    pointer-events: none;
  }
}

.nav-up .brand-logo img,
.nav-up .cart-indicator-widget .cart-indicator img,
.nav-up .icon-Hamburger:before,
.nav-up .wishlist-widget img .auth-widget img {
  filter: invert(0) sepia(100%) saturate(0) hue-rotate(346deg) brightness(161%)
    contrast(125%);
}

.cart-indicator-widget {
  &__checkout {
    .cart-indicator {
      span {
        color: $color-white;
      }
    }
  }
}

@media (max-width: 639px) {
  .double-featured-content-cards {
    padding: 3.125rem 4.8% 1.875rem;
  }
}
@media (min-width: 1024px) and (max-width: 1600px) {
  .container.responsivegrid .cmp-container .gbh-align-item-center {
    padding: 50px 36px;
  }
}
@media (max-width: 1023px) {
  .container.responsivegrid .cmp-container .gbh-align-item-center {
    padding: 50px 2pc;
  }
}

.container-bgcolor-black
  .cmp-teaser__action-container
  .cmp-teaser__action-link.gbh-primary-button {
  background: #fff;
  font-family: Helvetica Now Text W05 Medium;
  text-align: center;
  color: #494949;
  border: 1px solid #fff;
  box-sizing: border-box;
  padding: 17px 60px 19px;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.35px;
}

.container-bgcolor-black
  .cmp-teaser__action-container
  .cmp-teaser__action-link.gbh-secondary-button {
  background: 0 0;
  font-family: Helvetica Now Text W05 Medium;
  text-align: center;
  color: #fff;
  border: 1px solid #fff;
  box-sizing: border-box;
  padding: 17px 60px 19px;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.35px;
}

@media (max-width: 1200px) {
  .gbh-header-logo-menu .cmp-container .gbh-hamber-menu {
    display: block;
    margin-right: 20px;
    margin-top: 6px;
  }
}

.Plp-Search .search-result .search-result__breadcrumb.kf-react-plp-container {
  padding-top: 30px;
  margin-top: 0px;
}

.modal-backdrop.show {
  opacity: 0.5;
  z-index: 3;
}

.featured-content-carousel
  .cmp-carousel-slick
  .cmp-carousel-slick__content
  .slick-list
  .slick-track
  .slick-active
  .cmp-carousel-slick__item
  .cmp-teaser__content,
.featured-content-carousel
  .cmp-carousel-slick
  .cmp-carousel-slick__content
  .slick-list
  .slick-track
  .slick-current
  .cmp-carousel-slick__item
  .cmp-teaser__content {
  visibility: visible;
}

.carousel-text-left-overlap
  .cmp-carousel-slick
  .cmp-carousel-slick__content.single-items
  .cmp-carousel-slick__item {
  margin: 0;
  > div {
    margin: 0 2.5px;
  }
}

.featured-content-carousel
  .cmp-carousel-slick
  .cmp-carousel-slick__content
  .slick-list
  .slick-track
  .cmp-carousel-slick__item {
  margin: 0;
  > div {
    margin: 0 2.5px;
  }
}

@media (max-width: 1023px) {
  .carousel-text-left-overlap
    .cmp-carousel-slick
    .cmp-carousel-slick__content
    .slick-list
    .slick-track
    .cmp-carousel-slick__item {
    margin: 0;
    > div {
      margin: 0 2px;
    }
  }
  .featured-content-carousel
    .cmp-carousel-slick
    .cmp-carousel-slick__content
    .slick-list
    .slick-track
    .cmp-carousel-slick__item {
    margin: 0;
    > div {
      margin: 0 2px;
    }
  }
}

@media screen and (min-width: 1024px) and (max-width: 1600px) {
  html[page="plp"],
  html[page="press-release"],
  html[page="press-kit"] {
    font-size: 15px;
  }
  html[page="support:help and faq"] {
    .kf-react-plp-container {
      @media (max-width: 1920px) {
        max-width: 94.8%;
        margin-left: 2.6%;
        margin-right: 2.6%;
      }
    }
  }
}
@media screen and (max-width: 480px) {
  html[page="support:help and faq"] {
    .kf-react-plp-container {
      @media (max-width: 480px) {
        max-width: 90.4%;
        margin-left: 4.8%;
        margin-right: 4.8%;
      }
    }
  }
}

.searchbar-open {
  &.scroll-lock {
    position: fixed;
  }
}
.search-result__search-field {
  display: flex;
  align-items: flex-start;
  position: relative;
  width: 100%; // For mozilla firefox
  width: -webkit-fill-available; // For google chrome
}

@media screen and (max-width: 576px) {
  .account-settings
    .account-tabs__content
    .account-address
    .account-address__tiles
    .account-address-tile {
    grid-template-columns: 3fr 1fr 1fr;
  }
  .hideMobile {
    display: none;
  }
}

.account-settings .account-address__modal .checkbox input:checked ~ span {
  color: #757575;
}

.remove-scroll {
  overflow-y: hidden !important;
}

.pdp-sticky-header {
  .pdp-sticky-header__container {
    .pdp-sticky-header__right {
      .button {
        &:hover {
          background-color: $primary-blue;
        }
      }
    }
  }
}
.previewmode {
  border: 2px solid yellow;
}
.topcorner {
  background-color: yellow;
  color: red;
  position: fixed;
  top: 0px;
  left: 0%;
  z-index: 99999999;
  padding: 5px;
  padding-top: 10px;
  opacity: 0.2;
  &:hover {
    opacity: 1;
  }
  img:hover {
    cursor: pointer;
  }
}
.previewpage {
  height: 100vh;
}

.account-address__modal .checkbox {
  margin-bottom: 27px !important;
  margin-top: 5px !important;
  align-items: center !important;
}

.contact-us-main .input-field {
  position: relative;
  margin-bottom: 20px;
}

.contact-us-main .tab-list .tab-list-active {
  color: #000;
  border-bottom: 2px solid #000;
  font-weight: 700;
}

.contact-us-main .tab-list .tab-list-item {
  display: inline-block;
  list-style: none;
  padding: 9pt 0;
  font-size: 9pt;
  color: #757575;
  margin: 0 10px;
  cursor: pointer;
}

.favorites .sort--open .sort__item {
  font-size: 18px;
}

.favorites .sort--open .sort__item.active {
  background-color: $primary-blue;
  color: $color-white;
}

@media screen and (max-width: 576px) {
  .favorites-modal .modal-popup-content {
    margin: auto 18px !important;
    min-height: 57vh;
  }
  .favorites-modal .modal-popup .modal-popup-content .modal-popup-body {
    padding: 50px 0 10px !important;
  }
  .favorites-modal .favorites__modal-cta .kf-react-button button:first-child {
    margin-bottom: 20px;
  }
}

.bg-charcoal .cmp-carousel.cmp-carousel-slick {
  + .slider__counter .total-count {
    color: #757575;
  }
}

@media screen and (min-width: 1024px) {
  .room-inspiration-carousel .cmp-carousel.cmp-carousel-slick {
    .slider__counter {
      top: auto !important;
    }
  }
}

.kf-react-button {
  &.checkout-btn {
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
  .edit-address__title
    + .address-form.container.px-0.mx-0
    div.row:last-of-type
    .input-field:last-child {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) and (min-width: 360px) {
  .add-address__title {
    font-size: 32px;
    margin-bottom: 10px;
    margin-top: 16px;
  }
}

.add-address__title {
  margin-top: 5px;
  margin-bottom: 15px;
  font-family: "Helvetica Now Text W05 Bold", sans-serif;
  font-size: 20px;
  letter-spacing: 1.818px;
  line-height: normal;
  color: #494949;
  text-transform: uppercase;
}
.gbh-play-video.active .playinside:before {
  content: "\e933" !important;
}
@media (max-width: 639px) {
  .cmp-container .container.responsivegrid.gbh-align-item-center {
    padding: 50px 21.33%;
  }
}
@media (min-width: 1024px) and (max-width: 1600px) {
  .cmp-container .container.responsivegrid.gbh-align-item-center {
    padding: 70px 36px;
  }
}

:target {
  scroll-margin-top: $header-height;
}

html,
body {
  --scroll-behavior: smooth !important;
  scroll-behavior: smooth !important;
}

.server-error-page .cmp-image__image {
  height: auto;
}

.transparent-menu-black {
  .teaser {
    &.fc-white {
      margin-top: 0px !important;
    }
  }
}
.cmp-carousel.cmp-carousel-slick + .hide {
  visibility: hidden;
}

.press-release .sort--open .sort__item {
  font-size: 18px;
  text-align: left;
}

.press-release .sort--open .sort__item.active {
  background-color: $primary-blue;
  color: $color-white;
}

.download-spinner::after {
  content: "ing....";
}

.cmp-teaser__icons {
  .share-cmp {
    position: absolute;
    top: 100%;
    right: 0;
    display: block;
    left: unset;
    transform: none;
    .social-share__box {
      position: relative;
    }
  }
}

@media (min-width: 1025px) {
  .link-tool-box {
    .cmp-teaser {
      padding: 0 3.75rem !important;
      display: flex;
    }
  }
}

.link-tool-box:first-child .cmp-teaser {
  padding-left: 0 !important;
}
.link-tool-box:last-child .cmp-teaser {
  padding-right: 0 !important;
}
.unsetPageScrollMargin {
  :target {
    scroll-margin-top: unset !important;
  }
}

@media print {
  html[page="literature"] {
    .breadcrumb,
    .kf-page-title__total,
    .cmp-teaser__content,
    .cmp-teaser__image {
      display: none;
    }
  }
}
