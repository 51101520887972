@import "@/styles/mixins/_breakpoint.scss";
.check-out {
  .radio {
    margin: 0 10px 0 0;
    vertical-align: super;
  }
  .radio__custom {
    border: 1px solid $dark-gray;
    border-radius: 10px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 20px;
    height: 20px;
  }
  .payment-address-info-section {
    .radio__input {
      &:checked {
        + {
          .radio__custom {
            &::after {
              background: $charcoal;
              transform: unset !important;
              width: 14px !important;
              height: 14px !important;
              border-radius: 10px;
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }
  }

  .active,
  .payment-address-info-section .radio__input:checked,
  .--active {
    .radio__custom {
      &::after {
        background: $charcoal;
        transform: unset !important;
        width: 15px !important;
        height: 15px !important;
        border-radius: 10px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .radio-button__checked {
    top: 2px !important;
    left: 2px !important;
    height: 15px !important;
    width: 15px !important;
  }
  .preview-cart-item {
    &__image {
      background: $color-off-white;
      margin-right: 32px;
    }
    &__brand {
      letter-spacing: -0.35px;
      line-height: 22px;
    }
    &__header {
      margin-bottom: 13px;
    }
    &__skuId {
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.45px;
      color: $slate;
    }
  }
  .step-nav {
    &__step {
      padding: 15px 0 0 0 !important;
      &--active {
        padding: 0px !important;
        border-top: unset !important;
      }
      &--done {
        .step-nav__review {
          border-bottom: unset;
          @media (min-width: 1023px) {
            padding: 20px 0 0 0 !important;
          }
        }
      }
    }
    &__review {
      padding: 20px 0 0 0 !important;
      border-bottom: 1px solid $dark-gray;
      margin: 0 !important;
      @media (min-width: 1023px) {
        padding: 25px 0 0 0 !important;
      }
      @media (max-width: 767px) {
        padding: 25px 0 0 0 !important;
      }
    }
    &__content {
      padding: 0 !important;
      .step-nav__component {
        .step-nav__review {
          .step-nav__contact-info-title {
            color: $slate;
          }
        }
      }
    }
  }
  margin-top: 55px;
  @media (max-width: 1023px) {
    margin-top: 30px;
  }
  &__grid {
    display: flex;
    @media (min-width: 1023px) {
      justify-content: center;
    }
    &-steps {
      @media (min-width: 1023px) {
        width: 100%;
        max-width: 712px;
        margin-bottom: 60px;
      }
      @media screen and (max-width: 1023px) {
        margin-bottom: 15px;
      }
      @media screen and (max-width: 767px) {
        margin-bottom: 50px;
      }
    }
    &-summary {
      @media (min-width: 1023px) {
        width: 100%;
        max-width: 400px !important;
        margin-left: 79px;
      }
    }
    @media screen and (max-width: 1023px) {
      display: block;
    }
  }
  &__alert {
    width: 100%;
    max-width: 1191px;
    background-color: $color-error-red;
    padding: 20px 25px !important;
    font-family: $font-medium;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.55px;
    color: $color-white;
    margin-bottom: 30px;
    @media only screen and (max-width: 375px) {
      padding: 20px 18px !important;
    }
    @media only screen and (max-width: 812px) and (min-width: 376px) {
      padding: 20px 32px !important;
    }
    &-title {
      margin-right: 10px;
      font-family: $font-medium;
      font-weight: 500 !important;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.35px;
    }
    &-link {
      font-family: $font-medium;
      font-weight: 500 !important;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.35px;
      text-decoration: underline;
      color: $color-white;
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 65px;
  }
  &__back {
    cursor: pointer;
    font-family: $font-regular;
    font-size: 20px;
    line-height: 1.4;
    letter-spacing: -0.65px;
    color: $color-dark-gray;
    display: inline-flex;
    align-items: center;
    img {
      margin-right: 20px;
    }
  }
  &__support {
    margin: 0 40px 40px;
    div {
      margin-bottom: 10px;
      font-family: $font-light;
      font-size: 26px;
      line-height: 1.31;
      letter-spacing: -1.55px;
      color: $color-slate;
    }
    a {
      cursor: pointer;
      font-family: $font-regular;
      font-size: 16px;
      line-height: 1.57;
      letter-spacing: -0.65px;
      color: $color-dark-gray;
    }
    &.--lg-device {
      display: block;
    }
    &.--md-device {
      display: none;
    }
    &.--sm-device {
      display: none;
    }
  }
  &__title {
    display: block;
    font-family: $font-light;
    font-size: 45px;
    line-height: 56px;
    letter-spacing: -3.19px;
    color: $slate;
    margin-bottom: 55px;
    @media (max-width: 1023px) {
      font-size: 28px;
      line-height: 36px;
      letter-spacing: -1.98px;
      margin-bottom: 30px;
    }
    @media (max-width: 1023px) {
      margin-bottom: 30px;
    }
  }
  &__sticky-summary {
    position: sticky;
    top: 0;
    @media screen and (min-width: 1024px) {
      top: 40px;
    }
    .order-summary-wrapper {
      margin: 0px;
    }
    .order-summary {
      &__separator {
        border-top: 1px solid $color-dark-gray;
      }
      &__item {
        color: $color-charcoal;
      }
    }
  }
  &__sticky-content {
    .order-summary-wrapper {
      &.sticky-order {
        position: relative;
        top: unset;
      }
    }
    overflow-y: auto;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: unset;
    &::-webkit-scrollbar {
      display: none;
    }
    .shipping-accordion {
      margin: 0;
      .collapsible-accordion .Collapsible__trigger {
        border-top: 0;
        .collapsible__header {
          margin-top: 10px;
        }
      }
    }
    .cart-accordian {
      margin: 40px 0 0 !important;
      position: relative;
      top: 10px;
      .cart-accordion-section .order-summary__input .input-field__border {
        width: 13.6875rem;
      }
    }
  }

  &__payment-loader {
    padding: 100px 50px;
    text-align: center;
    font-family: "Helvetica Now Text W05 Light";
    font-size: 2rem;

    @include breakpoint(mobile-potrait) {
      font-size: 1.5rem;
    }

    .payment-pending {
      max-width: 900px;
      display: inline-block;
      margin-bottom: 30px;
    }
  }

  &__installation-error {
    &__content {
      padding-inline: 50px;
      width: 500px;

      @media (max-width: 767px) {
        width: 100%;
        padding-inline: 20px;
      }
    }
    &__title {
      color: $color-slate;
      text-align: center;
      font-feature-settings: "liga" off, "clig" off;
      font-family: $font-medium;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 1.818px;
      text-transform: uppercase;
      margin-bottom: 15px;
      margin-top: 20px;

      @media (max-width: 767px) {
        margin-top: 50px;
      }
    }

    &__desc {
      color: $color-dark-gray;
      font-feature-settings: "liga" off, "clig" off;
      font-family: $font-regular;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.55px;
    }

    &__ctas {
      display: flex;
      gap: 20px;
      flex-direction: column;
      margin-top: 20px;

      @media (max-width: 767px) {
        .kf-react-button {
          font-size: 14px !important;
        }
      }

      & button.check-out__installation-error__cta {
        white-space: normal;
      }
    }
  }
  .divider {
    border-top: 1px solid $color-dark-gray;
    margin-top: 50px;
  }
}

.sign-in__error-box {
  .error-msg {
    color: $color-error-red;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.55px;
    font-family: "Helvetica Now Text W05 Regular";
  }
}

.review-order {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  &__order-summary {
    display: none;
  }
  &__cta {
    width: 100%;
    margin: 20px 0 10px 0;
    .button {
      height: 50px !important;
      width: 100%;
    }
  }
  &__title {
    display: block;
    font-family: $font-medium;
    font-size: 20px;
    line-height: 1.4;
    letter-spacing: -0.4px;
    color: $color-charcoal;
    margin-bottom: 5px;
  }
  &__desc {
    font-family: $font-regular;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.48px;
    color: $color-dark-gray;
    font-style: italic;
    text-align: center;
    a {
      color: $color-dark-gray;
      text-decoration: underline;
    }
  }
  .shipping-accordion {
    &__separator {
      color: $color-dark-gray;
    }
    .preview-cart-item__name {
      color: $color-charcoal;
    }
  }
}

.taxware-down {
  .modal-popup {
    .modal-popup-content {
      width: 600px !important;
      @media (max-width: 767px) {
        width: 100% !important;
      }
    }
  }
  &__title {
    margin-top: 20px;
    color: $color-slate;
    font-family: $font-light;
    font-size: 36px;
    letter-spacing: -1.7px;
    line-height: 44px;
  }
  &__desc,
  &__desc p {
    margin-top: 20px;
    color: $color-dark-gray;
    font-family: $font-regular;
    font-size: 20px;
    letter-spacing: -0.65px;
    line-height: 28px;
  }
  &__cta {
    margin-top: 40px;
  }
}

.payment-method {
  &__billing-form {
    margin: 30px 0 0 0;
  }
  &--main-title {
    padding: 25px 0 30px 0;
    font-size: 20px;
    line-height: 29px;
    letter-spacing: 1.82px;
    color: $slate;
    text-transform: uppercase;
    font-family: $font-bold;
    @media screen and (max-width: 1023px) {
      padding: 15px 0 30px 0;
    }
  }
  &__title {
    font-family: $font-medium;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.4px;
    line-height: 28px;
    color: $color-charcoal;
    margin-bottom: 30px;
  }
  &__paypal-logo {
    width: 100px;
    height: 25px;
  }
  &__tile {
    border-radius: 5px;
    background-color: $color-white;
    border: solid 1px $dark-gray;
    padding: 20px;
    &-details {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-name {
      font-family: $font-medium;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.35px;
      color: $slate;
      display: flex;
      align-items: center;
      justify-content: center;
      label {
        margin: 0;
        align-items: center;
        justify-content: center;
        display: flex;
      }
    }
    &.--paypal-payment {
      margin-top: 20px;
      &.--active {
        padding-bottom: 25px;
      }
    }
    &.--active {
      border: solid 1px $color-dark-gray;
      padding-bottom: 30px;
      .payment-method__tile-name {
        color: $charcoal;
      }
      @media screen and (max-width: 767px) {
        padding: 15px 15px 30px 15px;
      }
      .payment-method__tile-details {
        cursor: auto;
      }
    }
  }
  &__billing-checkbox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 10px;
    label {
      margin-bottom: 0 !important;
    }
    .checkbox {
      &__border {
        margin-top: 0 !important;
      }
    }
    .checkbox_default {
      color: $slate !important;
    }
  }
  &__norton-logo {
    img {
      width: 92px;
      height: 47px;
    }
  }
  &__use-different {
    span {
      cursor: pointer;
      text-decoration: underline;
      font-family: $font-regular;
      font-size: 16px;
      letter-spacing: -0.55px;
      line-height: 24px;
      color: $color-slate;
    }
  }
  &__paypal {
    margin-top: 20px;
    @media screen and (max-width: 1023px) {
      margin-top: 18px;
    }
  }
  .paypal-method__info-message {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.45px;
    color: $slate;
    font-family: $font-regular;
    margin: 0 0 20px 0;
  }
  &__billing-form {
    &-title {
      font-family: $font-medium;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: -0.35px;
      color: $color-charcoal;
      margin-bottom: 20px;
    }
  }
  &__card-name {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    margin-left: 10px;
    &--label {
      width: 33.33%;
      margin-top: 10px;
      text-align: right;
      padding-right: 25px;
      font-size: 14px;
      text-align: right;
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight: 500;
    }
    &--field {
      width: 33.33%;
    }
    &--input {
      border-radius: 4px;
      background-color: $color-white;
      padding-left: 15px;
      padding-right: 15px;
      font-size: 12px;
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      height: 40px;
      width: 100%;
      border: solid 1px #ddd;
      box-sizing: border-box;
      &.--error {
        border: solid 1px #e50000;
        margin-bottom: 4px;
      }
      &:focus-visible {
        outline: -webkit-focus-ring-color auto 1px;
      }
    }
    &--error {
      font-size: 12px;
      font-family: $font-regular;
      color: #e50000;
    }
  }
  &__promo {
    margin-bottom: 30px;
    &-add {
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      span {
        color: $charcoal;
        font-family: $font-medium;
        font-size: 14px;
        letter-spacing: -0.28px;
        line-height: 16px;
        border-bottom: 1px solid $charcoal;
        padding-bottom: 6px;
      }
    }
    &-field {
      display: flex;
      align-items: center;
      button {
        margin-left: 15px !important;
        width: 97px;
      }
      .input-field {
        margin: 0 !important;
        width: 283px;
        @media screen and (max-width: 767px) {
          width: auto;
        }
        &__border {
          width: 100%;
          margin-right: 15px;
          padding: 0 15px !important;
          border-radius: 4px !important;
          border: solid 1px $dark-gray !important;
          background-color: $color-white !important;
          height: 50px !important;
          input {
            font-family: $font-regular !important;
            font-size: 14px !important;
            line-height: 22px !important;
            letter-spacing: -0.45px !important;
            color: $dark-gray !important;
          }
          &.--error {
            border-color: $color-error-red !important;
          }
        }
      }
    }
    &-error {
      margin-top: 10px;
      font-family: $font-regular;
      font-size: 16px;
      line-height: 1.38;
      letter-spacing: -0.3px;
      color: $color-error-red;
    }
    &-list {
      max-width: 392px;
    }
    &-item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      &:first-child {
        margin-top: 20px;
      }
      &:last-child {
        margin-bottom: 0;
      }
      img {
        cursor: pointer;
        width: 10px;
        height: 10px;
        margin-right: 15px;
      }
    }
    &-label {
      font-family: $font-regular;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: -0.55px;
      color: $color-slate;
    }
  }
  &__payment-process-error {
    margin-top: 20px;
    color: $color-error-red;
    font-family: $font-regular;
    font-size: 16px;
    letter-spacing: -0.55px;
    line-height: 22px;
  }
  .order-detail-block {
    .thankyou-container {
      max-width: 872px;
      padding: 24px;
      @include breakpoint(mobile-landscape) {
        max-width: 748px;
      }
      @include breakpoint(mobile-potrait) {
        max-width: 100%;
      }
      .underline {
        text-decoration: underline;
        text-underline-offset: 5px;
      }
      .order-details {
        padding: 0;
      }
      .title {
        font-size: 2.8125rem;
        line-height: 3.5rem;
        letter-spacing: -0.1992rem;
        color: $slate;
        font-weight: 300;
        font-family: $font-light;
        @include breakpoint(tab-max) {
          font-size: 28px;
          line-height: 36px;
          letter-spacing: -1.983px;
        }
      }
      .order-number {
        font-size: 20px;
        margin: 30px 0 20px 0;
        line-height: 28px;
        letter-spacing: -0.65px;
        color: $slate;
        font-weight: 400;
        font-family: $font-regular;
        span {
          font-weight: 500;
          color: $charcoal;
          letter-spacing: -0.4px;
          font-family: $font-medium;
        }
      }
      .order-message,
      p.order-message {
        margin-bottom: 20px;
        font-size: 16px;
        letter-spacing: -0.4px;
        font-weight: 400;
        line-height: 24px;
        color: $slate;
        font-family: $font-regular;
        span {
          color: $charcoal;
          letter-spacing: -0.35px;
          font-weight: 500;
          font-family: $font-medium;
        }
      }
      .order-info {
        p {
          font-size: 16px;
          letter-spacing: -0.4px;
          font-weight: 400;
          line-height: 24px;
          font-family: $font-regular;
          span {
            color: $charcoal;
            letter-spacing: -0.35px;
            font-weight: 500;
            + span {
              color: $slate;
              font-weight: 400;
              font-family: $font-regular;
            }
          }
          + p {
            margin-top: 10px;
          }
        }
      }
      .btn-group {
        display: flex;
        align-items: center;
        margin-top: 40px;
        justify-content: center;
        gap: 20px;
        @include breakpoint(mobile) {
          width: 100%;
          display: block;
        }
        @include breakpoint(tab-max) {
          button {
            &.button-hide-mobile {
              display: none;
            }
          }
        }
        @media print {
          display: none !important;
        }
        .view-order-button,
        .print-order-button {
          border: 1px solid $slate;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          text-decoration: none;
          white-space: nowrap;
          padding: 0 40px;
          height: 50px;
          min-width: 207px;
          -webkit-transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          transition: all 0.3s ease;
          line-height: 26px;
          letter-spacing: -0.35px;
          font-size: 18px;
          color: $slate;
          font-weight: 500;
          &:hover {
            background-color: $color-off-white-dark;
            transform: scale(0.95);
          }
        }
        @include breakpoint(mobile) {
          .print-order-button {
            margin-top: 15px;
          }
        }
      }
    }
  }
}

.shipping-accordion {
  margin: 40px 0 0 0;
  @media screen and (max-width: 1023px) {
    margin: 5px 0 35px 0;
  }
  @media screen and (max-width: 767px) {
    margin: 39px 0 35px 0;
  }
  .collapsible-accordion {
    .Collapsible {
      border-bottom: 1px solid $color-dark-gray !important;
      .Collapsible__trigger {
        padding: 10px 0;
        @media screen and (max-width: 1023px) {
          padding: 11px 0;
        }
        @media screen and (max-width: 767px) {
          padding: 10px 0;
        }
        .collapsible__header {
          span {
            font-family: $font-regular;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.35px;
            color: $charcoal;
          }
        }
      }
      .Collapsible__contentInner {
        padding: 5px 0 10px;
        .preview-cart-item {
          padding: 20px 0;
          &:first-child {
            padding-top: 0;
            border-top: unset !important ;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .check-out {
    &__support {
      margin-bottom: 0;
      &.--lg-device {
        display: none;
      }
      &.--md-device {
        display: block;
      }
      &.--sm-device {
        display: none;
      }
    }
    &__sticky-summary {
      position: static;
    }
  }
}

@media screen and (max-width: 767px) {
  .check-out {
    &__shipping-modal .modal-popup .modal-popup-content {
      padding: 35px 18px 65px !important;
    }
    &__support {
      &.--lg-device {
        display: none;
      }
      &.--md-device {
        display: none;
      }
      &.--sm-device {
        display: block;
        margin-top: 50px;
        text-align: left;
      }
    }
  }
  .payment-method {
    &__card-name {
      display: block;
      &--label {
        width: calc(90% - 10px);
        text-align: left;
        margin-bottom: 10px;
      }
      &--field {
        width: calc(90% - 25px);
      }
    }
  }
}
.check-out {
  .step-nav {
    &__step {
      &--done {
        padding-bottom: 25px !important;
        border-bottom: 1px solid $color-dark-gray !important;
        &:nth-of-type(2) {
          border-top: unset !important;
        }
        &:nth-of-type(3) {
          border: unset !important;
        }
      }
    }
  }
}
.cart-promo-code {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  span {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.45px;
    color: $charcoal;
    font-family: $font-regular;
    &.order-summary__close {
      padding: 0;
      text-decoration: underline;
    }
  }
  .active-promo-name {
    font-family: $font-medium;
    text-transform: uppercase;
  }
}
.cart-promo-description {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.45px;
  color: $slate;
  font-family: $font-regular;
  font-style: italic;
  @media screen and (max-width: 767px) {
    width: 58%;
    margin: 3px 0 0 0;
  }
}
.cart-promo-code-section {
  margin: -15px 0 0 0;
  @media screen and (max-width: 767px) {
    margin: 7px 0 0 0;
  }
}
.promo-code-list {
  margin: 0 0 20px 0;
}
.margin-0 {
  margin: 0 !important;
}
.paypal-button {
  height: 50px !important;
}
.payment-list-type {
  margin-bottom: 20px;
}
.checkout-need-help {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.4px;
  color: $color-slate;
  font-family: $font-regular;
  border-top: 1px solid $color-light-gray;
  a {
    font-family: $font-medium;
    color: $color-charcoal;
    text-decoration: underline;
  }
  span {
    margin-top: 15px;
    display: block;
  }
  margin: 30px 50px 120px 50px;
  padding-top: 30px;
  @media screen and (max-width: 813px) {
    margin: 0;
    border: unset;
    padding: 50px 32px 0;
  }
  @media screen and (max-width: 426px) {
    padding: 50px 18px 0;
  }
}
.shipping-accordion {
  .collapsible-accordion {
    .Collapsible {
      border-color: $dark-gray;
      .preview-cart-item {
        border-top: 1px solid $light-gray;
        &:first-child {
          border-top: none;
        }
        &:last-child {
          border-top: 1px solid $light-gray;
        }
      }
      .preview-cart-item__price {
        font-family: $font-medium;
        letter-spacing: -0.55px;
        margin-bottom: 10px;
        @media screen and (max-width: 1023px) {
          margin-bottom: 0;
        }
      }
      .preview-cart-item__discount {
        margin-left: 6px;
      }
      .preview-cart-item__offPercent {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.48px;
        color: $charcoal;
      }
      &__trigger {
        padding: 10px 0;
        line-height: 24px;
        font-family: $font-regular !important;
        border-top: 1px solid $dark-gray;
      }
      &__contentOuter {
        .Collapsible__contentInner {
          padding: 10px 0 20px 0;
          @media screen and (max-width: 1023px) {
            padding: 10px 0 0 0;
          }
          .kf-react-button,
          .button {
            padding: 0;
            width: 97px;
            float: right;
            margin-left: 15px;
            @media screen and (max-width: 767px) {
              min-width: 97px;
            }
          }
        }
      }
      .is-open {
        .plus {
          &::after {
            transform: rotate(90deg);
          }
        }
      }
      .plus {
        position: relative;
        .line {
          display: none;
        }
        &::after {
          content: "\e92c";
          display: flex;
          font-family: icomoon;
          font-size: 1pc;
          opacity: 1;
          transform: rotate(-90deg);
          transition: all 0.5s ease;
          top: 0px;
          position: absolute;
          color: $charcoal;
        }
      }
    }
  }
}
.address-tile {
  &.--new {
    .shipping-form__set-default-check {
      height: unset;
      margin-top: 30px;
    }
  }
}
