@import "@/styles/mixins/_breakpoint";

.account-settings {
  @media (min-width: 1023px) and (max-width: 1024px) {
    margin-bottom: 0 !important;
  }
  .kf-react-container {
    @include breakpoint(desktop-small) {
      max-width: 1920px !important;
      padding: 0 2.64% !important;
      margin: 0 auto !important;
    }
    @include breakpoint(desktop-large) {
      padding: 0 50px !important;
    }
    @media (min-width: 992px) and (max-width: 1023px) {
      padding: 0 2rem !important;
      flex-direction: column;
    }
    @include breakpoint(mobile-potrait) {
      padding: 0 1.125rem !important;
    }
    .account-tabs {
      &__nav-wrapper {
        @include breakpoint(desktop-large) {
          flex: 0 0 13%;
          max-width: 13%;
        }
        @media (min-width: 992px) and (max-width: 1023px) {
          flex: 0 0 100%;
          max-width: 100%;
          padding-bottom: 0;
        }
        .account-tabs__nav {
          .account-tabs__message {
            @include breakpoint(tab-max) {
              @include text-style($font-light, 1.75rem, 2.5rem, -0.0875rem);
              color: $slate;
            }
          }
        }
      }
      &__nav {
        @include breakpoint(desktop-small) {
          padding: 3.75rem 1.375rem 0 0;
        }
        @include breakpoint(tab-landscape) {
          padding: 2.5rem 2rem;
          margin-right: -2rem;
        }
        @include breakpoint(mobile-potrait) {
          padding: 2.5rem 1.125rem 2.5rem;
          margin: 0 -1.125rem;
        }
        .account-tabs__list {
          margin-top: 2.5rem;
          &-item {
            &:first-child {
              padding-top: 0;
            }
          }
        }
        .account-tabs__sign-out {
          margin-top: 3.125rem;
        }
        .select-dropdown__field {
          padding: 0.3125rem 0.9375rem 0.5rem;
          .select-dropdown__placeholder.--label {
            @include text-style($font-regular, 0.75rem, 1.5rem, -0.0219rem);
            color: $dark-gray;
          }
          .select-dropdown__value {
            bottom: -0.6875rem;
            @include text-style($font-regular, 1.125rem, 1.625rem, -0.0437rem);
            color: $slate;
          }
        }
      }
      &__message {
        font-size: 28px;
      }
      &__list-item {
        &.--active {
          color: $slate;
        }
      }
      &__sign-out {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.3px;
        text-decoration: none;
        border-bottom: 1px solid $slate;
        display: inline-block;
      }
    }
    .account-tabs__wrapper-nav {
      @include breakpoint(desktop-large) {
        flex: 0 0 87%;
        max-width: 87%;
      }
      @media (min-width: 992px) and (max-width: 1023px) {
        flex: 0 0 100%;
        max-width: 100%;
      }
      .account-tabs {
        &__content {
          @include breakpoint(desktop-small) {
            padding: 2.8125rem 0 3.75rem 1.875rem;
          }
          @media (min-width: 992px) and (max-width: 1023px) {
            padding: 2.9375rem 0 0 1.875rem;
          }
          @include breakpoint(tab-landscape) {
            padding: 1.25rem 0 0 0 !important;
          }
          .account-tabs__title {
            @include text-style($font-bold, 1.25rem, 1.8125rem, 0.1138rem);
            text-transform: uppercase;
          }
          .account-address,
          .login-security,
          .account-communication,
          .account-management,
          .account-profile {
            margin: 0;
            padding: 3.75rem 0;
            border-top: 1px solid $color-dark-gray;
            @include breakpoint(tab-landscape) {
              padding: 2.5rem 0;
            }
            .account-settings__section-header {
              border: none;
              .button {
                border: none;
                text-decoration: underline;
                padding: 0;
                width: fit-content;
              }
              .account-settings__section-title {
                @include text-style($font-bold, 1.25rem, normal, 0.1138rem);
                text-transform: uppercase;
                margin: 0;
              }
              .account-profile__edit {
                @include text-style($font-medium, 1rem, 1.5rem, -0.0219rem);
                color: $slate;
                text-underline-offset: 5px;
              }
            }
          }
          .account-address {
            @include breakpoint(mobile-potrait) {
              position: relative;
            }
            .account-address__cta--mobile {
              position: absolute;
              top: 2.8rem;
              right: 0;
              margin-top: 0;
              .kf-react-button {
                @include breakpoint(mobile-potrait) {
                  padding: 0;
                  border: none;
                  text-decoration: underline;
                  height: 100%;
                  align-items: center;
                  font-size: 16px;
                }
              }
            }
            .account-settings__section-header {
              button {
                @include text-style($font-medium, 1rem, 1rem, -0.02rem);
                color: $slate;
                text-underline-offset: 5px;
              }
            }
          }
          .account-profile {
            border: none;
            padding-top: 0px;
            &__item {
              @include breakpoint(tab-max) {
                margin-bottom: 0;
                &:last-child {
                  margin-bottom: 0 !important;
                }
              }
            }
          }
          .account-management {
            @include breakpoint(tab-landscape) {
              padding: 40px 0 0;
            }
            &__desc {
              @include text-style($font-medium, 1rem, 1.5rem, -0.0219rem);
            }
            &__modal-desc {
              font-size: 16px;
              line-height: 24px;
              letter-spacing: -0.55px;
              margin: 15px 0 35px;
            }
            &__modal-cta {
              flex-direction: column;
              .button {
                max-height: 50px;
                line-height: 26px;
                margin-bottom: 20px;
              }
              *:last-child {
                .button {
                  margin-bottom: 0;
                }
              }
            }
          }
          .account-address__tiles {
            width: 84.7835%;
            @include breakpoint(tab-landscape) {
              width: 100%;
              position: relative;
            }
            .account-address-tile {
              @include breakpoint(tab-landscape) {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
              }
              @include breakpoint(mobile-potrait) {
                flex-direction: column;
                align-items: initial;
              }
              &.custom-label-edit {
                border: 1px solid $light-gray;
                border-radius: 5px;
                padding: 20px 20px 22px;
                margin-bottom: 20px;
                &:last-child {
                  margin-bottom: 0;
                }
                @include breakpoint(tab-landscape) {
                  padding: 1.25rem 0.9375rem;
                  margin-bottom: 1.25rem;
                  position: relative;
                  &:last-child {
                    margin-bottom: 0;
                  }
                }
              }
              .account-address-tile__info {
                @include breakpoint(mobile-landscape) {
                  flex: 0 0 100%;
                }
              }
              .account-address-tile__address {
                @include breakpoint(mobile-landscape) {
                  width: 50%;
                }
                @include breakpoint(mobile-potrait) {
                  order: 1;
                  padding-bottom: 1.25rem;
                }
              }
              &-tile {
                @include breakpoint(mobile-potrait) {
                  grid-template-columns: 1fr;
                }
              }
              &__cta {
                @include breakpoint(mobile-landscape) {
                  position: absolute;
                  bottom: 20px;
                  right: 27px;
                  width: 50%;
                  justify-content: end;
                }
                @include breakpoint(mobile-potrait) {
                  order: 3;
                  justify-content: end;
                }
                div {
                  @include text-style(
                    $font-medium,
                    0.875rem,
                    1.375rem,
                    -0.0187rem
                  );
                  color: $slate;
                  text-underline-offset: 5px;
                  &:first-child {
                    margin-right: 20px;
                    @include breakpoint(mobile-landscape) {
                      margin-right: 30px;
                    }
                  }
                  @include breakpoint(mobile-potrait) {
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: -0.35px;
                  }
                }
              }
              &__default {
                @include text-style($font-medium, 0.75rem, 0.75rem, 0.0406rem);
                border: 1px solid $color-dark-gray;
                padding: 0.4063rem 0.7813rem;
                max-width: fit-content;
                @include breakpoint(mobile-landscape) {
                  margin-bottom: 1.25rem;
                }
                @include breakpoint(mobile-potrait) {
                  margin-bottom: 0.625rem;
                }
              }
            }
          }
          .account-tabs {
            &__title {
              @include text-style($font-light, 2.8125rem, 3.5rem, -0.1994rem);
              margin-bottom: 4.6875rem;
              text-transform: capitalize;
            }
            &__content {
              @include breakpoint(tab-landscape) {
                margin-bottom: 3.75rem;
              }
            }
          }
          .account-profile__content {
            width: 88%;
            padding-left: 0.9375rem;
            @include breakpoint(desktop-mid) {
              width: 92%;
            }
            @include breakpoint(tab-landscape) {
              width: 100%;
              margin: 0;
              padding: 0;
            }
            .account-profile__container {
              display: flex;
              flex-direction: row;
              padding: 0;
              gap: 0 80px;
              flex-wrap: wrap;
              @include breakpoint(tab-max) {
                gap: 25px 70px;
                justify-content: space-between;
              }
              @include breakpoint(mobile-potrait) {
                flex-direction: column;
                gap: unset;
              }
              .account-profile__item {
                width: 100%;
                max-width: 287px;
                margin: 0;
                @include breakpoint(mobile-landscape) {
                  max-width: 339px;
                  justify-content: space-between;
                  &:last-child {
                    flex: 0 0 100%;
                  }
                }
                @include breakpoint(mobile-potrait) {
                  &:last-child {
                    margin-bottom: 0;
                    margin-top: 20px;
                  }
                }
                .account-profile__item-title {
                  color: $slate;
                }
              }
            }
            .account-professional {
              display: flex;
              flex-direction: column;
              @include breakpoint(mobile-landscape) {
                flex-direction: row;
                gap: unset;
                justify-content: flex-start;
              }
              .container,
              .profile__item__container {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                flex-wrap: wrap;
                @include breakpoint(mobile-landscape) {
                  flex-direction: row;
                  margin: 0 0;
                  flex: 0 0 100%;
                  gap: 25px;
                }
                .account-profile__item {
                  width: 100%;
                  margin-bottom: 0;
                  flex: 0 0 33.33%;
                  max-width: 100%;
                  @include breakpoint(mobile-landscape) {
                    flex: 0 0 48%;
                  }
                  @include breakpoint(mobile-potrait) {
                    flex: 0 0 100%;
                    margin-top: 25px;
                    &:first-child {
                      margin-top: 0;
                    }
                  }
                  &:nth-child(n + 4) {
                    margin-top: 25px;
                    @include breakpoint(mobile-landscape) {
                      margin-top: 0;
                    }
                  }
                }
              }
              .container {
                @include breakpoint(mobile-potrait) {
                  margin-bottom: 0;
                }
              }
            }
            .professional_account {
              @include text-style($font-medium, 0.75rem, 0.75rem, 0.0406rem);
              border: 1px solid $color-dark-gray;
              padding: 0.4063rem 0.7813rem;
              max-width: fit-content;
              margin-bottom: 30px;
              text-transform: uppercase;
            }
          }
          .login-security {
            &__content {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              width: 33%;
              @include breakpoint(desktop-mid) {
                width: 100%;
              }
              @include breakpoint(tab-landscape) {
                width: 100%;
              }
              @include breakpoint(mobile-potrait) {
                flex-direction: column;
                gap: 25px;
              }
              .login-security {
                &__email,
                &__password {
                  @include breakpoint(mobile-landscape) {
                    width: 100%;
                    max-width: 339px;
                    gap: 0 70px;
                  }
                  .login-security__value {
                    color: $dark-gray;
                  }
                }
                &__key {
                  color: $slate;
                }
              }
            }
            .account-settings__section-header {
              div {
                @include text-style($font-medium, 1rem, 1rem, -0.02rem);
                color: $slate;
                text-underline-offset: 5px;
              }
            }
          }
          .account-communication {
            &__content {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              @include breakpoint(mobile-potrait) {
                flex-direction: column;
              }
            }
            &__desc {
              margin-top: 0.3125rem;
              @include breakpoint(mobile-landscape) {
                max-width: 29.75rem;
              }
            }
            &__cta {
              min-width: 180px;
              @include breakpoint(mobile-potrait) {
                margin-top: 1.25rem;
              }
              .kf-react-button {
                a,
                button {
                  @include text-style(
                    $font-medium,
                    0.875rem,
                    1.5rem,
                    -0.0156rem
                  );
                  color: $slate;
                  border: 1px solid $slate;
                }
              }
            }
            &__title {
              @include text-style($font-medium, 1rem, 1.5rem, -0.0219rem);
              color: $slate;
            }
          }
          .account-management {
            &__content {
              @include breakpoint(mobile-potrait) {
                display: flex;
                flex-direction: column;
              }
              .account-management__desc {
                @include text-style($font-medium, 1rem, 1.5rem, -0.0219rem);
                color: $slate;
              }
            }
            &__cta {
              min-width: 180px;
              @include breakpoint(mobile-potrait) {
                margin-top: 20px;
              }
              .kf-react-button {
                a,
                button {
                  @include text-style(
                    $font-medium,
                    0.875rem,
                    1.5rem,
                    -0.0156rem
                  );
                  color: $slate;
                  border: 1px solid $slate;
                }
              }
            }
            .modal-popup .modal-popup-content {
              @include breakpoint(mobile-potrait) {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }

  .modal-popup {
    .modal-popup-content {
      max-width: 500px;
      padding: 25px 50px 50px;
      width: 100%;
      .close-button-holder {
        position: absolute;
        top: 25px;
        right: 35px;
        width: 17px;
        height: 30px;
        @include breakpoint(tab-landscape) {
          top: 15px;
          right: 32px;
        }
        @include breakpoint(mobile-potrait) {
          right: 18px;
        }
        .close-button {
          top: 0;
          right: 0;
        }
      }
      .modal-popup-body {
        padding: 0;
        .edit-profile-modal,
        .account-address__modal {
          width: 100%;
        }
        .edit-profile__title,
        .add-address__title,
        .edit-address__title,
        .account-settings__modal-title {
          font-size: 20px;
          line-height: normal;
          letter-spacing: 1.818px;
          font-family: $font-bold;
          text-transform: uppercase;
          color: $color-slate;
        }
        .address-form,
        .reset-password {
          .address-form__required-fields,
          .reset-password__message {
            font-family: $font-light;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.55px;
            margin-bottom: 35px;
            color: $color-dark-gray;
          }
          .kf-react-button.flexible {
            a,
            button {
              margin-top: 20px;
              max-height: 50px;
            }
          }
          .input-field__border {
            padding: 8px 15px;
          }
          .input-field__value {
            input {
              line-height: 26px;
              letter-spacing: -0.5px;
            }
            label {
              line-height: 18px;
              letter-spacing: -0.25px;
            }
          }
          &__addaddress {
            margin-bottom: 30px;
            @include breakpoint(tab-max) {
              margin: 30px 0;
              padding: 30px 0;
            }
          }
          .checkbox {
            @include breakpoint(tab-max) {
              margin-top: 10px !important;
            }
          }
        }
        .reset-password .kf-react-button.button {
          margin-top: 30px;
          max-height: 50px;
        }
        .address-form .kf-react-button.edit-profile-modal__submit {
          margin-top: 20px;
        }
      }
    }
  }
  &__modal {
    @include breakpoint(tab-max) {
      max-width: 100% !important;
      width: 100% !important;
    }
  }
}
