.shipping-address {
  &__top-header {
    display: flex;
    justify-content: space-between;
    margin: 0 0 20px 0;
    @media screen and (max-width: 767px) {
      display: block;
    }
  }
  &__shipping-title {
    font-size: 20px !important;
    line-height: 22px !important;
    letter-spacing: 1.82px !important;
    color: $slate !important;
    font-family: $font-bold !important;
    text-transform: uppercase !important;
  }
  &__checkout-fast {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-cta {
      display: flex;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.52px;
      color: $charcoal;
      font-family: $font-regular;
      .checkout-sign-in {
        font-family: $font-medium;
        color: $slate;
        cursor: pointer;
        text-decoration: underline;
        margin: 0 0 0 4px;
      }
    }
  }
  &__message {
    margin-bottom: 50px;
    &.--sales {
      margin-bottom: 25px;
      .shipping-address__signin-mssg {
        margin-bottom: 10px;
      }
    }
  }
  &__signin-mssg {
    margin-bottom: 20px;
    font-family: $font-medium;
    font-size: 20px;
    letter-spacing: -0.4px;
    line-height: 28px;
    color: $color-charcoal;
  }
  &__sales-email {
    margin-bottom: 50px;
    max-width: 380px;
  }
  &__select-address {
    font-family: $font-regular;
    font-size: 20px;
    letter-spacing: -0.65px;
    line-height: 28px;
    color: $color-dark-gray;
  }
  &__address-list {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 20px;
    column-gap: 20px;
  }
  &__submit {
    button {
      min-width: 245px;
      margin-top: 30px;
      height: 50px !important;
    }
  }
  &__submitBtn {
    button {
      width: 100%;
      min-width: 245px;
      height: 50px !important;
    }
  }
  &__note {
    margin-top: 50px;
    &-title {
      font-family: $font-medium;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: -0.35px;
      color: $color-charcoal;
    }
    &-desc {
      margin-top: 5px;
      font-family: $font-regular;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: -0.55px;
      color: $color-dark-gray;
    }
    .textarea__wrapper {
      margin-top: 25px;
    }
  }
  &__signup-success {
    border-radius: 5px;
    background-color: $color-off-white;
    padding: 30px;
    margin-bottom: 50px;

    h1 {
      color: $color-charcoal;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: -0.4px;
      line-height: 28px;
      margin-bottom: 5px;
      font-family: $font-medium;
    }

    p {
      color: $color-slate;
      font-size: 20px;
      letter-spacing: -0.65px;
      line-height: 28px;
    }
  }
  &__process-error,
  &__process-error p {
    text-align: right;
    margin-top: 20px;
    color: $color-error-red;
    font-family: $font-regular;
    font-size: 16px;
    letter-spacing: -0.55px;
    line-height: 22px;
  }
  .instruction {
    max-width: 540px;
    @media screen and (max-width: 767px) {
      max-width: 100%;
    }
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.45px;
    color: $dark-gray;
    margin: 0 0 30px 0;
  }
}

.shipping-form {
  &__required-fields {
    display: inline-block;
    font-family: $font-regular;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.55px;
    color: $color-dark-gray;
    margin-bottom: 20px;
    @media only screen and (min-width: 812px) and (max-width: 1023px) {
      margin-bottom: 40px;
    }
  }
  &__set-default {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .checkbox {
      margin-bottom: 0;
      input:checked ~ span {
        color: $color-charcoal;
      }
    }
    .shipping-form__label-input {
      margin-top: 0;
      margin-bottom: 0;
    }
    &-check {
      display: inline-flex;
      height: 60px;
    }
    &.--hide-label {
      margin-top: 10px;
      .shipping-form__set-default-check {
        height: auto;
      }
    }
  }
  &__toggle {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      color: $color-charcoal;
      font-family: $font-medium;
      font-size: 16px;
      letter-spacing: -0.35px;
      line-height: 24px;
    }
    .switch {
      min-width: 60px;
      margin-bottom: 0;
      @media screen and (min-width: 778px) and (max-width: 991px) {
        min-width: unset;
      }
    }
  }
  &__label-input {
    margin-top: 20px;
  }
  &__submit-button {
    button {
      min-width: 245px;
      margin-top: 10px;
      height: 50px !important;
      @media screen and (max-width: 1023px) {
        margin-top: 8px;
      }
      @media screen and (max-width: 767px) {
        margin-top: 10px;
        min-width: 100%;
      }
    }
  }

  &__auth {
    padding: 30px;
    border-radius: 5px;
    background-color: $color-off-white;
    margin-bottom: 30px;

    &-title {
      font-family: $font-medium;
      font-size: 20px;
      line-height: 1.4;
      letter-spacing: -0.4px;
      color: $color-charcoal;
      margin-bottom: 5px;
    }
    &-desc {
      font-family: $font-regular;
      font-size: 20px;
      line-height: 1.4;
      letter-spacing: -0.65px;
      color: $color-slate;
      margin-bottom: 30px;
    }
    &-cta {
      margin-top: 10px;
      color: $color-slate;
      display: flex;
      .button .ghost {
        color: $color-slate;
      }
    }
    &-cta-create-account {
      margin-right: 20px;
    }
  }
  &__pl10 {
    padding-left: 10px !important;
  }
  &__pr10 {
    padding-right: 10px !important;
  }
  .hidden-state-input {
    z-index: -1;
    position: absolute;
    height: 0;
    width: 0;
    opacity: 0;
  }
}

.check-out__shipping-modal {
  .modal-popup {
    .modal-popup-content {
      .modal-popup-body {
        width: 780px;
        padding: 35px 60px 50px !important;
      }
      @media screen and (max-width: 1023px) {
        width: 100%;
        max-height: 100% !important;
        padding: 20px 32px 50px;
        .modal-popup-body {
          width: 100%;
          min-width: 339px;
          padding: 20px 0 0 0 !important;
        }
      }
      @media screen and (max-width: 767px) {
        padding: 35px 18px 50px;
      }
    }
  }
}
@media screen and (max-width: 1023px) {
  .shipping-address {
    &__message {
      margin-bottom: 60px;
    }
    &__address-list {
      grid-template-columns: 1fr;
      row-gap: 20px;
      column-gap: 0;
    }
    &__submit {
      float: none;
      width: 100%;
    }
  }
  .shipping-form {
    &__submit-button {
      width: 100%;
    }
    &__auth-cta {
      display: block;
      .button {
        width: 100%;
        &:first-child {
          margin-bottom: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .shipping-form {
    &__pl10 {
      padding-left: 15px !important;
    }
    &__pr10 {
      padding-right: 15px !important;
    }
  }
}

@media screen and (max-width: 767px) {
  .shipping-form {
    &__required-fields {
      margin-bottom: 40px;
    }
  }
  .shipping-address {
    &__checkout-fast {
      display: block;
      span {
        line-height: 1.31;
        letter-spacing: -1.55px;
      }
      &-cta {
        margin-top: 20px;
        .button {
          width: 100%;
          &:first-child {
            margin-right: 0;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
  .shipping-form {
    &__auth {
      padding-right: 30px 20px;
    }
  }
}
.shipping-title-complete {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.35px;
  font-family: $font-medium;
  color: $slate;
  margin: 0 0 5px 0;
}
.check-out {
  .step-nav__title,
  .step-nav__step,
  .step-nav .step-nav__number,
  .step-nav__number--mobile {
    display: none !important;
  }
  .step-nav__step--active,
  .step-nav__step--done {
    display: block !important;
  }
  .step-nav__step--done .step-nav__title {
    display: flex !important;
  }
  .step-nav__title h2,
  .delivery-option .option-title {
    font-size: 20px !important;
    line-height: 22px !important;
    letter-spacing: 1.82px !important;
    color: $slate !important;
    font-family: $font-bold !important;
    text-transform: uppercase !important;
  }
}
.delivery-option .option-title {
  font-size: 20px !important;
  line-height: 22px !important;
  letter-spacing: 1.82px !important;
  color: $slate !important;
  font-family: $font-bold !important;
  text-transform: uppercase !important;
}
.check-out__content {
  @media screen and (max-width: 1023px) {
    .step-nav__edit-btn {
      display: block !important;
    }
    .step-nav__component {
      height: unset !important;
    }
    .step-nav__plus,
    .step-nav__edit-btn--mobile {
      display: none !important;
    }
  }
  .step-nav__step--done {
    .step-nav__edit-btn {
      button {
        border: unset !important;
        padding: 0 0 4px 0 !important;
        border-bottom: 1px solid $charcoal !important;
        color: $charcoal !important;
        line-height: 16px !important;
        letter-spacing: -0.28px !important;
        text-decoration: unset !important;
        height: unset !important;
        min-width: unset;
        &:hover {
          background-color: unset !important;
          transform: unset;
        }
      }
    }
  }
}
