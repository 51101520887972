.preview-cart {
  position: fixed;
  z-index: 999;
  display: flex;
  flex-direction: column;
  right: -435px;
  bottom: 0;
  width: 435px;
  height: 100vh;
  padding: 50px 40px 30px 40px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  transition: all 0.5s ease;

  &--open {
    right: 0;
    z-index: 10000000;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px solid $color-dark-gray;
  }
  &__title {
    font-family: $font-light;
    font-size: 26px;
    line-height: 2.125rem;
    letter-spacing: -1.55px;
    color: $color-slate;
    &.--guest {
      font-size: 22px;
    }
  }
  &__welcome {
    color: $color-dark-gray;
    font-family: $font-regular;
    font-size: 14px;
    letter-spacing: -0.65px;
    line-height: 22px;
    margin-top: 10px;
  }
  &__signin {
    cursor: pointer;
    text-decoration: underline;
    color: $color-dark-gray;
    font-family: $font-regular;
    font-size: 14px;
    letter-spacing: -0.45px;
    line-height: 22px;
    margin-top: 10px;
  }
  &__list {
    overflow-y: auto;
    flex-grow: 1;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__empty {
    margin-top: 45px;
    p {
      margin-bottom: 15px;
      font-family: $font-light;
      font-size: 36px;
      line-height: 1.22;
      letter-spacing: -1.7px;
      color: $color-slate;
    }
    span {
      cursor: pointer;
      font-family: $font-regular;
      font-size: 20px;
      line-height: 1.4;
      letter-spacing: -0.65px;
      color: $color-dark-gray;
      text-decoration: underline;
    }
  }
  &__footer {
    box-shadow: 0 -10px 20px 0 rgba(0, 0, 0, 0.07);
    margin: auto -40px;
    padding: 25px 40px 0 40px;
  }
  &__subtotal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    &--label {
      font-family: $font-medium;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: -0.35px;
      color: $color-slate;
    }
    &--value {
      font-family: $font-light;
      font-size: 32px;
      line-height: 1.25;
      letter-spacing: -1.5px;
      color: $color-slate;
    }
  }
  &__cta {
    display: flex;
    .button:first-child {
      margin-right: 18px;
    }
  }
  @media only screen and (max-width: 992px) {
    display: none;
  }
}

.preview-cart-item {
  display: flex;
  border-top: 1px solid $color-dark-gray;
  padding-top: 30px;
  padding-bottom: 20px;

  &:first-child {
    border-top: none;
  }

  &__item-of {
    padding: 15px 0;
    border-top: 1px solid $color-dark-gray;
    color: $color-slate;
    font-family: $font-regular;
    font-size: 14px;
    letter-spacing: -0.45px;
    line-height: 22px;
  }

  &__image {
    width: 100px;
    height: 100px;
    object-fit: scale-down;
    margin-right: 15px;
  }
  &__details {
    flex: 1;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  &__remove {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    span {
      cursor: pointer;
      text-decoration: underline;
      font-family: $font-regular;
      font-size: 14px;
      line-height: 1.57;
      letter-spacing: -0.65px;
      color: $color-charcoal;
      margin-top: 20px;
    }
    &.--loading {
      div {
        cursor: not-allowed;
      }
      span {
        pointer-events: none;
      }
    }
  }
  &__brand {
    font-family: $font-medium;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.25px;
    color: $color-dark-gray;
  }
  &__name {
    font-family: $font-medium;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.55px;
    color: $color-slate;
    margin-bottom: 15px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
  &__finish {
    font-family: $font-light;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.45px;
    color: $color-slate;
    margin-bottom: 20px;
  }
  &__footer {
    display: flex;
    justify-content: space-between;
  }
  &__qty {
    width: 90px;
    &-label {
      font-family: $font-regular;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: -0.55px;
      color: $color-slate;
    }
    .quantity-selector {
      &__count {
        width: 40%;
      }
    }
  }
  &__price-details {
    display: flex;
    flex-direction: column;
    text-align: right;
    margin-right: 2px;
  }
  &__price {
    font-family: $font-bold;
    font-size: 16px;
    line-height: 1.38;
    letter-spacing: -0.8px;
    color: $color-charcoal;
    margin-bottom: 5px;
  }
  &__discount {
    font-family: $font-regular;
    font-size: 16px;
    line-height: 1.38;
    letter-spacing: -0.8px;
    color: $color-dark-gray;
    text-decoration: line-through;
    margin-bottom: 0;
  }
  &__alert {
    display: flex;
    padding-bottom: 30px;
    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
    span {
      color: $color-error-red;
      font-family: $font-regular;
      font-size: 14px;
      letter-spacing: -0.65px;
      line-height: 22px;
    }
  }
  &__discount-container {
    display: flex;
    justify-content: flex-end;
    align-items: unset;
    flex-direction: column-reverse;
  }
  &__offPercent {
    font-family: $font-medium;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.25px;
    color: $color-charcoal;
    padding-right: 0;
    padding-top: 10px;
  }
}

.combo-preview-item {
  padding-left: 30px;
  &__note {
    display: inline-block;
    padding-bottom: 20px;
    color: $color-charcoal;
    font-family: $font-medium;
    font-size: 16px;
    letter-spacing: -0.35px;
    line-height: 24px;
  }
  .preview-cart-item {
    padding-top: 0;
    border-top: none;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1366px) {
  .preview-cart-item {
    &__qty {
      width: 65px;
    }
  }
}
