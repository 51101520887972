@import "@/styles/mixins/_breakpoint";

.myServiceView_wrapper {
  .kf-react-container {
    @include breakpoint(desktop-large) {
      padding: 0 50px !important;
    }
    @include breakpoint(desktop-small) {
      max-width: 1920px !important;
      padding: 0 2.64% !important;
      margin: 0 auto !important;
    }
    @media (min-width: 992px) and (max-width: 1023px) {
      padding: 0 2rem !important;
    }
    .account-tabs {
      &__nav-wrapper {
        @include breakpoint(desktop-large) {
          flex: 0 0 13%;
          max-width: 13%;
        }
        @media (min-width: 992px) and (max-width: 1023px) {
          flex: 0 0 100%;
          max-width: 100%;
          padding-bottom: 0;
        }
      }
      &__nav {
        @include breakpoint(desktop-small) {
          padding: 3.75rem 1.375rem 0 0;
        }
        @include breakpoint(tab-landscape) {
          padding: 2.5rem 2rem;
          margin-right: -2rem;
        }
        @include breakpoint(mobile-potrait) {
          padding: 2.5rem 1.125rem 2.5rem;
          margin: 0 -1.125rem;
        }
        .account-tabs__dropdown {
          .select-dropdown {
            .select-dropdown__placeholder {
              @include breakpoint(tab-landscape) {
                line-height: 1.125rem;
                letter-spacing: -0.0156rem;
              }
            }
          }
        }
        .account-tabs {
          &__list {
            margin-top: 2.5rem;
            &-item {
              &:first-child {
                padding-top: 0;
              }
            }
          }
          &__sign-out {
            margin-top: 3.125rem;
            text-underline-offset: 5px;
            @include text-style($font-medium, 0.875rem, 1rem, -0.0175rem);
          }
          &__message {
            @include text-style($font-light, 1.75rem, 2.25rem, -0.0875rem);
          }
        }
        .select-dropdown__field {
          padding: 8px 15px;
        }
      }
      &__content {
        @include breakpoint(desktop-small) {
          padding: 2.8125rem 0 0 1.875rem;
        }
        @media (min-width: 992px) and (max-width: 1023px) {
          padding: 2.8125rem 0 0 1.875rem;
        }
        @include breakpoint(mobile-landscape) {
          padding: 1.25rem 0 0;
        }
        @include breakpoint(mobile-potrait) {
          padding: 1.25rem 0 0 !important;
        }
      }
    }
    .account-tabs__wrapper-nav {
      @include breakpoint(desktop-large) {
        flex: 0 0 87%;
        max-width: 87%;
      }
      @media (min-width: 992px) and (max-width: 1023px) {
        flex: 0 0 100%;
        max-width: 100%;
      }
      .account-tabs {
        &__content {
          .active-service-sec {
            @include breakpoint(mobile-landscape) {
              margin-bottom: 3.75rem;
            }
            @include breakpoint(mobile-potrait) {
              margin-bottom: 3.75rem;
            }
            div {
              @include breakpoint(desktop-small) {
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
              }
              @media (min-width: 992px) and (max-width: 1023px) {
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
              }
              p {
                @include text-style($font-light, 1.75rem, 2.25rem, -0.0875rem);
                margin-bottom: 1.5625rem;
                color: $slate;
                @include breakpoint(tab-landscape) {
                  @include text-style(
                    $font-light,
                    1.75rem,
                    2.25rem,
                    -0.0875rem
                  );
                }
              }
            }
            button.gbh-data-layer {
              @include text-style($font-medium, 14px, 22px, -0.3px);
              background-color: transparent;
              color: $color-slate;
              border: 1px solid $color-slate;
              padding: 9px 30px;
              width: auto;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              @include breakpoint(desktop-small) {
                max-width: fit-content;
              }
              @include breakpoint(tab-landscape) {
                width: 100%;
                margin-top: 0;
              }
            }
          }
          .my-service__active-service {
            margin-bottom: 3.75rem;
            .my-service {
              &__header {
                position: relative;
                top: unset;
                right: unset;
                transform: unset;
                margin-bottom: 1.875rem;
                @include breakpoint(tab-landscape) {
                  margin-top: 0;
                }
                .my-service__service-title {
                  display: block;
                  @include text-style($font-bold, 1.25rem, normal, 1.818px);
                  color: $color-slate;
                  text-transform: uppercase;
                }
                .sort {
                  margin: 0;
                  &-by-text {
                    @include text-style($font-regular, 1rem, 1.5rem, -0.025rem);
                    color: $color-dark-gray;
                  }
                  &__toggle-icon {
                    margin-left: 0.625rem;
                  }
                }
              }
              &__list-box {
                margin: 0;
                padding: 1.875rem 0;
                @include breakpoint(mobile-potrait) {
                  flex-direction: column;
                }
                &:last-child {
                  border-bottom: 1px solid $color-dark-gray;
                }
              }
            }
          }
          .my-service {
            &__title {
              @include text-style($font-bold, 1.25rem, normal, 1.818px);
              color: $color-slate;
              text-transform: uppercase;
            }
            &__header {
              .sort {
                margin: 0;
                &__toggle {
                  @include text-style($font-regular, 1rem, 1.5rem, -0.4px);
                  color: $color-dark-gray;
                }
                @include breakpoint(mobile-potrait) {
                  padding-top: 15px;
                }
              }
              @include breakpoint(mobile-potrait) {
                flex-direction: column;
              }
            }
            &__product-content {
              .expires {
                display: none;
              }
              .brand {
                @include text-style($font-medium, 1rem, 1.5rem, -0.35px);
                color: $color-dark-gray;
                padding: 0;
                margin-bottom: 5px;
                @include breakpoint(tab-landscape) {
                  @include text-style($font-medium, 0.875rem, 1.375rem, -0.3px);
                }
              }
              .name {
                @include text-style($font-regular, 1rem, 1.5rem, -0.4px);
                color: $color-slate;
                padding: 0;
                margin-bottom: 1.25rem;
              }
              .purchased {
                @include text-style($font-regular, 0.875rem, 1.375rem, -0.3px);
                color: $color-slate;
                padding: 0;
                margin-bottom: 0.625rem;
                @include breakpoint(mobile-potrait) {
                  margin-bottom: 0;
                }
              }
            }
            &__list-box {
              img {
                width: 14.8125rem;
                height: 14.8125rem;
                margin-right: 1.25rem;
                @include breakpoint(mobile-potrait) {
                  margin: 0 0 1.25rem 0;
                }
              }
              &.my-service__past-list-box {
                padding: 1.875rem 0;
                &:last-child {
                  border-bottom: 1px solid $color-dark-gray;
                }
                @include breakpoint(tab-landscape) {
                  margin-bottom: 0;
                }
                &:last-child {
                  margin-bottom: 0;
                }
              }
              @include breakpoint(mobile-potrait) {
                flex-direction: column;
              }
            }
            &__completed-service {
              padding-bottom: 7.5rem;
              @include breakpoint(tab-landscape) {
                padding-bottom: 3.75rem;
              }
            }
          }
        }
      }
      .account-tabs {
        &__title {
          @include text-style($font-light, 2.8125rem, 3.5rem, -0.1994rem);
          margin-bottom: 4.6875rem;
        }
      }
    }
  }
}
