.scroll-lock {
  .product-detail-page__left-sticky,
  .product-detail-page__right-sticky {
    position: relative;
  }
}

.product-detail-page {
  font-family: $font-regular;
  font-stretch: normal;
  font-style: normal;
  margin-bottom: 80px;

  &__print-note {
    display: none;
    h6 {
      font-family: $font-bold;
      margin-bottom: 15px;
    }
    p {
      font-family: $font-regular;
      font-size: 16px;
      margin-bottom: 15px;
    }
  }
  &__required-product-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: solid 1px $color-light-gray;
    font-size: 14px;
    font-family: $font-regular;
    line-height: 22px;
    letter-spacing: -0.45px;
    color: $color-dark-gray;
  }
  &__breadcrumb {
    margin-top: 28px;
    margin-bottom: 23px;
    nav ol .adaopacity {
      opacity: 0;
    }
    nav {
      color: $color-slate;
      font-size: 14px;
      line-height: 1.71;
      ol {
        margin: 0;
        list-style: none;

        li {
          display: inline;
          a {
            cursor: pointer;
            font-family: $font-regular;
            font-size: 14px;
            line-height: 1.71;
            letter-spacing: -0.3px;
            color: $color-dark-gray;
            text-decoration: none;
            &:hover {
              color: $color-slate;
            }
          }
        }
      }
    }
  }

  &__sticky-wrapper {
    position: relative;
    z-index: 11;
  }

  &__toaster {
    position: sticky;
    z-index: 99;
    top: 0;
    &.--sticky {
      top: 140px;
    }
    &.--mobile {
      display: none;
    }
  }
  .product-overview {
    display: grid;
    grid-template-columns: 52.03% 37.08%;
  }
  &__image-gallery {
    .modal-popup {
      .modal-popup-content {
        padding: 90px 0 65px 0;
        @media only screen and (max-width: 1920px) {
          max-height: 100%;
          width: 100vw;
        }
        .close-button {
          right: 31px;
          top: 31px;
          color: $color-charcoal;
          img {
            width: 28px;
            height: 28px;
          }
        }
        .modal-popup-body {
          padding: 0;
        }
      }
    }
  }
  .image-reel {
    overflow: hidden;
    &__image-thumb-view {
      padding-right: 17px;
    }
  }

  &__order-messaging {
    padding-top: 15px;
  }
  &__content-section {
    margin-top: 30px;
    .product-detail-page__sku-id {
      display: block;
      font-family: $font-medium;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.3px;
      color: $color-slate;
      margin-top: 8px;
      margin-bottom: 30px;
    }
    .product-detail-page__title-desc {
      font-size: 45px;
      line-height: 56px;
      letter-spacing: -3.19px;
    }
    .product-detail-page__title {
      font-family: $font-light;
      font-size: 45px;
      line-height: 56px;
      letter-spacing: -3.19px;
      color: $color-slate;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .product-detail-page__description {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.55px;
      color: $color-dark-gray;
      margin-top: 12px;
    }
    .product-detail-page__narrative-description {
      padding-bottom: 30px;
      &--show-more,
      &--none,
      &--show-more-button {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.45px;
        color: $color-dark-gray;
      }
      &--show-more {
        &-button {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
    h6 {
      font-family: $font-medium;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: -0.35px;
      color: $color-charcoal;
    }
  }
  &__badge {
    margin-bottom: 2px;
    display: flex;
    &-new,
    &-discontinued {
      font-family: $font-medium;
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 0.65px;
      text-transform: uppercase;
      margin-bottom: 0px;
      margin-right: 5px;
      padding: 6.5px 8.5px;
      text-align: center;
    }
    &-new {
      background-color: $color-white;
      color: $color-slate;
      border: 1px solid $color-light-gray;
    }
    &-discontinued {
      background-color: $color-slate;
      color: $color-white;
      border: 1px solid $color-slate;
    }
  }
  .price-retail-exclusive {
    display: none;
  }
  &__price {
    display: flex;
    justify-content: flex-start;
    &-value {
      text-align: left;
      font-family: $font-regular;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: -0.48px;
      color: $color-slate;
    }
    &-main {
      padding-bottom: 30px;
    }
  }

  &__info-mssg {
    background-color: $color-off-white;
    margin-bottom: 20px;
    p {
      font-family: $font-medium;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.35px;
      color: $color-charcoal;
      padding: 16px 24px;
    }
  }

  &__discount {
    text-align: left;
  }
  &__discount-price,
  &__percent-off {
    color: $color-dark-gray;
    font-family: $font-regular;
    font-size: 14px;
    letter-spacing: -0.7px;
    line-height: 22px;
  }
  &__discount-price {
    text-decoration: line-through;
    margin-right: 10px;
  }

  &__list-price--consumer {
    padding-top: 40px;
    border-top: 1px solid $color-dark-gray;
    padding-bottom: 40px;
    border-bottom: 1px solid $color-dark-gray;
  }

  &__list-price--associate {
    margin-top: 8px;
  }
  &__affirm-messaging {
    display: block;
    width: 100%;
    margin-top: 15px;
    .affirm-as-low-as,
    .affirm-modal-trigger {
      font-size: 14px;
      letter-spacing: -0.45px;
      line-height: 22px;
      font-family: $font-regular;
      color: $dark-gray;
      .affirm-modal-trigger {
        text-decoration: underline;
      }
      .affirm-ala-price {
        letter-spacing: -0.3px;
        font-family: $font-medium;
        color: $charcoal;
      }
    }
  }
  &__list-view {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: $color-dark-gray;
    font-family: $font-regular;
    font-size: 14px;
    letter-spacing: -0.45px;
    line-height: 22px;
  }

  &__list-text {
    display: flex;
    align-items: center;
    text-decoration: underline;
    margin-left: 5px;
  }

  &__list-value {
    text-align: left;
  }

  &__list-tooltip-wrapper {
    position: absolute;
    z-index: 3;
    top: 10px;
    right: 10px;
  }

  &__list-tooltip--show {
    display: block;
    position: relative;
  }

  &__list-tooltip--hide {
    display: none;
  }

  &__customer-group {
    color: $color-slate;
    font-family: $font-regular;
    font-size: 16px;
    letter-spacing: -0.65px;
    line-height: 24px;
    margin-left: 10px;
    span {
      vertical-align: middle;
    }
  }

  &__finish-sample {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    .product-detail-page {
      &__finish_text,
      &__finish_value,
      &__discontinued .discontinued-text,
      &__discontinued .discontinued-color-value {
        font-family: $font-medium;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.35px;
      }
      &__finish_text,
      &__discontinued .discontinued-text {
        color: $color-slate;
        margin-right: 10px;
      }
      &__finish_value,
      &__discontinued .discontinued-color-value {
        color: $color-dark-gray;
      }
    }
  }

  &__color-swatch {
    width: 100%;
    margin-bottom: 30px;
    display: grid;
    grid-template-columns: repeat(auto-fit, 82px);
    grid-gap: 10px;
    .color-swatch {
      height: 35px;
      input[type="radio"] + label,
      input[type="radio"]:checked + label {
        height: 35px;
      }
    }
  }
  &__promo-section {
    .product-detail-page__promo-desc,
    .product-detail-page__promo-desc * {
      font-family: $font-regular;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.45px;
      color: $color-dark-gray;
    }
    .collapsible-accordion {
      .Collapsible {
        .Collapsible__trigger {
          padding-top: 0;
          .collapsible__header {
            justify-content: flex-start;
            span {
              font-family: $font-medium;
              font-size: 16px;
              line-height: 24px;
              letter-spacing: -0.35px;
              color: $color-slate;
            }
            .arrow {
              display: flex;
              justify-content: center;
              align-items: center;
              flex-shrink: 0;
              align-self: flex-start;
              margin-left: 5px;
              * {
                transition: all 0.5s ease;
              }
              img {
                width: 16px;
                height: 10px;
              }
            }
          }
          &.is-open {
            .down-arrow {
              display: none;
            }
          }
          &.is-closed {
            padding-bottom: 20px;
            .up-arrow {
              display: none;
            }
          }
        }
        .Collapsible__contentInner {
          padding-bottom: 20px;
        }
      }
    }
  }

  .install-requirements {
    display: inline-block;
    margin-bottom: 20px;
    cursor: pointer;
    text-decoration: underline;
    font-family: $font-regular;
    font-size: 14px;
    line-height: 1.57;
    letter-spacing: -0.65px;
    color: $color-dark-gray;
  }
  &__install-item-details {
    padding-bottom: 15px;
    border-bottom: solid 1px $color-light-gray;
    padding-top: 14px;
  }
  &__install-item {
    // padding-top: 50px;
    // border-top: 1px solid $color-light-gray;
    padding-bottom: 40px;
  }
  &__install-item-btn {
    display: flex;
    .button.large {
      font-family: $font-medium;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.25px;
      text-align: center;
      color: $color-dark-gray;
      height: 40px;
    }
  }
  &__tooltip-icon {
    padding-left: 10px;
    padding-top: 5px;
    position: relative;
    img {
      cursor: pointer;
      width: 16px;
      height: 16px;
    }
    .tooltip__container {
      position: absolute;
      z-index: 9;
      right: 12px;
      bottom: 95%;
      top: auto;
      text-align: initial;
    }
  }
  &__install-items-container {
    padding-bottom: 20px;
    .item-details {
      font-family: "Helvetica Now Text W05 Regular";
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.87px;
      padding-right: 10px;
      color: $color-slate;
      margin-bottom: 5px;
    }
    .item-price {
      text-align: right;
      font-family: "Helvetica Now Text W05 Medium";
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.7px;
      color: $color-slate;
      margin-top: 5px;
    }
    .discount-price-item {
      display: flex;
      align-items: baseline;
      justify-content: end;
    }
    .discount-price {
      margin-left: 10px;
      text-align: right;
      color: $color-dark-gray;
      font-family: "Helvetica Now Text W05 Regular";
      font-size: 14px;
      letter-spacing: -0.7px;
      line-height: 22px;
      text-decoration: line-through;
      order: 2;
    }
    .discount-percent {
      text-align: right;
      color: $color-dark-gray;
      font-family: "Helvetica Now Text W05 Regular";
      font-size: 12px;
      letter-spacing: -0.7px;
      line-height: 22px;
    }
    .sub-total {
      text-align: right;
      font-family: "Helvetica Now Text W05 Regular";
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.65px;
      color: $color-slate;
      margin-top: 8px;
    }
    .base-product {
      font-family: "Helvetica Now Text W05 Medium";
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.3px;
      color: $color-slate;
      margin-bottom: 5px;
    }
    .total {
      font-family: "Helvetica Now Text W05 Medium";
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.35px;
      color: $color-slate;
      margin-top: 8px;
    }
    .item-color-details,
    .item-description {
      font-family: "Helvetica Now Text W05 Regular";
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.45px;
      color: $color-dark-gray;
    }
    .remove {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: flex-end;
      justify-content: flex-end;
      padding-top: 20px;
    }
    .remove-link {
      font-family: "Helvetica Now Text W05 Regular";
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.45px;
      color: $color-dark-gray;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__price_spider_title {
    font-family: $font-medium;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.35px;
    color: $color-slate;
    padding-bottom: 10px;
  }

  .more-sec {
    padding-top: 10px;
  }

  &__oversized-msg-section {
    .oversized-msg-tooltip {
      display: grid;

      .oversized-msg-primary-txt {
        font-family: $font-medium;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.35px;
        color: $color-charcoal;
        background-color: $color-off-white;
        padding: 18px 21px;
        margin-bottom: 15px;
        display: flex;
        &:last-child {
          margin-bottom: 20px;
        }
      }
    }
    .oversized-msg-info-icon {
      margin-top: 3px;
      margin-right: 10px;
      width: 20px;
      height: 20px;
    }
  }

  .add-to-compare {
    position: relative;
    .checkbox {
      margin-bottom: 0px;
    }
    .tooltip__container {
      position: absolute;
      z-index: 9;
      right: 0;
    }
    @media (max-width: 480px) {
      display: none;
    }
  }

  .print-container {
    padding: 40px 15px 30px;
  }

  &__details-dimensions {
    border-top: 1px solid $color-light-gray;
    // border-bottom: 1px solid $color-light-gray;
    margin-bottom: 40px;
  }

  &__more-from-collection {
    display: flex;
    justify-content: space-between;
    margin-bottom: 29px;
    a {
      text-decoration: underline;
      font-family: $font-regular;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: -0.35px;
      color: $color-dark-gray;
    }
  }

  &__need-professional {
    padding-bottom: 20px;
    border-bottom: 1px solid $color-light-gray;
    margin-top: 100px;
    h6 {
      color: $color-charcoal;
      margin-bottom: 0;
    }
  }

  &__professional-service-heading {
    font-family: $font-medium;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.35px;
    color: $color-charcoal;
    margin-bottom: 0px;
    margin-top: 100px;
    border-bottom: 1px solid $color-dark-gray;
    padding-bottom: 20px;
  }

  .professional-services,
  .details-container {
    border-bottom: 1px solid $color-light-gray;
  }

  &__need-installation {
    margin-top: 25px;
    h6,
    p {
      font-family: $font-medium;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.35px;
      color: $color-charcoal;
    }
    a {
      font-family: $font-medium;
      color: $color-dark-gray;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.35px;
      &,
      &:hover {
        text-decoration: underline;
      }
      @media (min-width: 992px) {
        text-align: right;
        display: block;
      }
    }
  }
  &__discontinued-txt {
    font-family: $font-light;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: -1.2px;
    color: $color-charcoal;
    padding-bottom: 25px;
  }
  &__available-color-section {
    padding-bottom: 40px;
  }
  &__available-color-btn {
    width: 100%;
    &.button.large {
      height: 50px;
      line-height: 22px;
    }
  }
  &__discontinue-image {
    position: absolute;
    background-color: $color-dark-gray;
    top: 12px;
    left: 35px;
    font-size: 16px;
    color: $color-off-white;
    padding: 10px;
  }
  &__discontinue-image-view {
    width: 100%;
    height: auto;
  }

  .header-section {
    display: flex;
    justify-content: space-between;
    padding: 0;
  }

  @media screen and (orientation: landscape) and (max-width: 1023px) {
    .header-section {
      .product-detail-page {
        &__print-share-container {
          .print-and-share {
            display: none;
          }
        }
      }
    }
  }

  &__print-share-container {
    font-size: 16px;
    font-family: $font-regular;
    color: $color-dark-gray;
    padding-top: 30px;

    .print-and-share {
      display: flex;
      justify-content: space-between;
      & > * + * {
        margin-left: 20px;
      }
      button {
        background: none;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
      }
    }
    .social-share__box {
      right: 0;
    }
    .social-share {
      &__cta {
        display: flex;
        align-items: center;
      }
    }
    .social-share__sites {
      a:focus-visible {
        outline: none !important;
      }
    }

    @media (max-width: 480px) {
      display: none;
    }
  }

  .modal-popup {
    top: 0;
    padding: 0;
    height: 100vh;
    .modal-popup-content {
      height: 100vh;
      .close-button {
        right: 30px;
        top: 25px;
        color: #757575;
      }
    }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1279px) {
    margin-bottom: 60px;
    .product-overview {
      grid-template-columns: 51.95% 41.21%;
      gap: 4.1%;
      &__content-section {
        margin-top: 15px;
      }
    }
    .image-reel__image-thumb-view {
      padding-right: 1px;
    }
    .print-overview {
      margin-left: 0;
      margin-right: 0;
    }
    .print-overview-left {
      padding-right: 0;
      position: relative;
    }
    .print-overview-right {
      padding-right: 0;
      position: relative;
    }
    &__left-sticky,
    &__right-sticky {
      position: sticky;
      top: 0px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .print-overview {
      &-right {
        margin: 0 3.94%;
      }
    }
  }

  @media only screen and (min-width: 1024px) {
    .kf-react-plp-container {
      padding: 0px;
      @media (max-width: 1920px) {
        max-width: 94.8%;
        margin-left: 2.6%;
        margin-right: 2.6%;
      }
    }
  }

  @media only screen and (min-width: 1280px) {
    .product-overview {
      gap: 4.11%;
      &-left {
        position: relative;
      }
      &-right {
        position: relative;
      }
    }
    &__left-sticky,
    &__right-sticky {
      position: sticky;
      top: 48px;
    }
  }

  @media only screen and (max-width: 1023px) {
    margin-bottom: 40px;
    &__breadcrumb {
      margin-bottom: 20px;
      margin-top: 20px;
    }
    .product-overview {
      grid-template-columns: 100%;
    }
    .print-overview {
      &-right {
        margin-top: 30px;
      }
    }
    &__content-section {
      .product-detail-page__title-desc {
        font-size: 28px;
        line-height: 36px;
        letter-spacing: -1.98px;
      }
      .product-detail-page__title {
        font-size: 28px;
        line-height: 36px;
        letter-spacing: -1.98px;
        padding-bottom: 10px;
      }
    }
    &__slider-video-modal {
      .video-modal__content {
        .modal-popup {
          .modal-popup-content {
            background-color: transparent;
            padding: 0;
            .close-button {
              right: 31px;
              top: 31px;
              img {
                width: 28px;
                height: 28px;
              }
            }
            .modal-popup-body {
              margin-top: 30vh;
              width: 100vw;
              height: auto;
              padding: 0;
              iframe {
                aspect-ratio: 1/1;
              }
            }
            @media screen and (orientation: landscape) {
              .close-button {
                top: 20px;
              }
              .modal-popup-body {
                margin-top: 13vh;
                width: 100vw;
                height: 85vh;
                overflow: hidden;
              }
            }
          }
        }
      }
    }
    &__list-tooltip-wrapper {
      right: auto;
    }
    &__toaster {
      &.--mobile {
        display: block;
      }
      &.--sticky {
        .toast-msg {
          padding-left: 18px;
          padding-right: 18px;
        }
      }
      &.toaster-up {
        .toast-msg {
          top: 0px;
        }
      }
      &.toaster-down {
        .toast-msg {
          top: 60px;
        }
      }
    }
    &__color-swatch {
      grid-template-columns: repeat(auto-fit, 77px);
    }
    &__oversized-msg-section {
      .oversized-msg-tooltip {
        .oversized-msg-primary-txt {
          padding: 16px 24px 16px 21px;
          @media screen and (max-width: 767px) {
            padding-right: 31px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 767px) {
    padding: 0;
    &__print-container {
      font-size: 14px;
      padding-top: 18px;
    }
    .print-container {
      padding: 40px 15px 34px;
    }
    .kf-react-plp-container {
      padding: 0px;
      max-width: 90.4%;
      margin-left: 4.8%;
      margin-right: 4.8%;
    }
    .print-overview {
      &-right {
        margin: 30px 4.8% 0;
      }
    }

    &__need-installation {
      h6,
      a {
        display: block;
      }
    }

    &__install-items-modal {
      .modal-popup {
        .modal-popup-content {
          padding: 10px 0px 20px;
          .close-button {
            right: 25px;
            display: block;
            color: $color-charcoal;
            right: 0;
            top: 22px;
          }
        }
      }
    }
    &__install-item-details {
      padding-bottom: 38px;
      &:last-child {
        padding-bottom: 0px;
      }
    }

    &__finish-sample {
      .product-detail-page__finish {
        flex-direction: column;
      }
      .product-detail-page__discontinued {
        display: flex;
        flex-direction: column;
      }
    }

    .modal-popup .modal-popup-content .close-button {
      right: 30px;
      position: absolute;
    }
  }
  @media only screen and (min-width: 1921px) {
    .kf-react-plp-container {
      max-width: 1820px;
      margin: auto;
    }
    .product-overview {
      max-width: 1920px;
      margin: auto;
    }
  }
}
.review {
  .review-container {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 0;
    @media only screen and (max-width: 1023px) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}
@media print {
  .product-detail-page {
    &__print-note {
      display: block;
      h6 {
        font-family: $font-bold;
        margin-bottom: 15px;
      }
      p {
        font-family: $font-regular;
        font-size: 16px;
        margin-bottom: 15px;
      }
    }

    .product-overview.print-overview {
      display: block;
      .pdp-image-reel__image-stack-list li:not(:first-child) {
        display: none;
      }
    }

    &__breadcrumb,
    &__order-sample,
    &__more-from-collection,
    &__collection-carousel,
    &__list-price--consumer,
    &__oversized-msg-section,
    &__need-installation,
    .sticky-header,
    .professional-services,
    .options-selector,
    .quantity-selector,
    .out-of-stock-container,
    .add-cart-container,
    .add-to-compare,
    .favorites,
    .image-reel__image-thumb-view,
    .image-reel__link-more,
    .carousel-collection,
    .details-dimensions__section-view-more,
    .header-section {
      display: none !important;
    }
    .add-to-cart .bottom-section {
      justify-content: flex-start;
      padding-bottom: 20px;
    }
    &__print-share-container {
      .print-and-share {
        display: none;
      }
    }

    &__main,
    &__finish,
    &__color-swatch-main,
    &__price-main,
    &__sticky-wrapper,
    .details-dimensions__section-title,
    .details-dimensions__list,
    .image-reel__image-list {
      display: block;
    }
  }

  .features-technologies__description {
    background-color: #ffffff;
    color: #212121 !important;
  }

  .features-technologies__list-item {
    color: #494949 !important;
  }

  .product-detail-page__image-gallery {
    display: block !important;
    float: left !important;
    max-width: 52.3%;
  }
  .product-detail-page__content-section {
    display: block !important;
    float: right !important;
    max-width: 43%;
  }

  .search-button {
    display: none !important;
  }

  .features-technologies {
    background-color: #ffffff !important;
    color: #212121 !important;
    padding-top: 0 !important;
  }

  .features-technologies .collapsible-accordion:first-child {
    border-top: none !important;
  }

  .features-technologies .collapsible-accordion:last-child {
    border-bottom: none !important;
  }

  .features-technologies__content {
    padding-bottom: 0 !important;
  }

  .features-technologies
    .collapsible-accordion
    .Collapsible
    .Collapsible__trigger
    .collapsible__header
    span {
    color: #212121 !important;
  }
  .product-specifications {
    .collapsible-accordion .Collapsible__trigger .collapsible__header .plus {
      display: none !important;
    }
    .no-print {
      display: none;
    }
    .collapsible-accordion {
      .Collapsible__contentOuter {
        height: auto !important;
      }
      .Collapsible__contentInner {
        opacity: 1 !important;
        visibility: visible !important;
      }
    }
  }
  .pdp-more-from-collection,
  .icon-Hamburger,
  .product-specification,
  .xfpdp,
  .review,
  .embed,
  .mainfooter,
  .pairs-well-with,
  .newslettersignin,
  .additionalfooter,
  .product-detail-page__list-price--consumer {
    display: none !important;
  }
}

@media print {
  .dynamicpdp {
    .desk-header-height {
      display: none !important;
    }
    #main-content {
      display: -webkit-box;
    }
  }
}
