body.compare-open {
  overflow-y: hidden !important;
}
.compare-products__container {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  overflow-y: scroll;
  scroll-behavior: smooth;
  z-index: 1001;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  font-stretch: normal;
  font-style: normal;
  .container {
    max-width: 1380px;
    @media screen and (max-width: 1380px) {
      max-width: 98%;
    }
  }
}

.collapsed-widget-wrapper {
  position: fixed;
  bottom: 100px;
  width: 100%;
  z-index: 99;
  pointer-events: none;
}

.compare-products__sticky-header--mobile {
  display: none;
}

.compare-products__sticky-wrapper {
  position: relative;
  z-index: 999;
}

.compare-products__sticky-header {
  background-color: $color-charcoal;
  padding: 25px 0px;
  font-family: $font-light;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: $color-extra-light-gray;
  &-content {
    display: flex;
    position: relative;
    .compare-products__sticky-collapse {
      position: absolute;
      top: 20px;
      right: 0px;
      cursor: pointer;
    }
  }
  &-differences {
    display: flex;
    align-items: center;
    padding-right: 20px;
    span {
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: -0.55px;
      width: 100px;
      margin-right: 20px;
    }
    .slider {
      width: 60px;
    }
  }
  &-details {
    display: flex;
    .compare-products__sticky-header-product {
      div {
        display: flex;
        flex-direction: column;
        span:first-child {
          font-size: 14px;
          line-height: 1.57;
          letter-spacing: -0.32px;
        }
        span:last-child {
          font-size: 16px;
          line-height: 1.38;
          letter-spacing: -0.4px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }
      &:last-child {
        div {
          span:last-child {
            width: 180px;
          }
        }
      }
    }
  }
}

.compare-products__overlay {
  width: 100%;
  position: absolute;
  bottom: 0px;
  .compare-products {
    padding: 40px 0px 0px;
  }
  &--compared {
    position: relative;
    right: 0px;
    top: 0px;
    height: fit-content;
    .compare-products {
      padding: 40px 0px 30px;
    }
  }
}

.compare-products {
  background: $color-charcoal;
  color: $color-white;
  font-family: $font-light;

  &__expand-container {
    position: relative;
  }
  &__expand-icon {
    z-index: 20;
    padding-right: 30px;
    text-align: right;
    cursor: pointer;
    padding-bottom: 26px;
  }

  &__right-side--compared {
    .product-box {
      margin-top: -70px;
      padding-top: 70px;
      margin-bottom: -50px;
      padding-bottom: 50px;
    }
  }

  .product-box {
    display: inline-block;
    z-index: 6;
    padding-left: 15px !important;
    padding-right: 15px !important;
    &.compare-col-hover {
      background-color: $color-black;
    }
  }

  .place-holder {
    &__box {
      border: 1px solid $color-slate;
      border-radius: 4px;
      width: 92%;
      height: 324px;
      margin-left: auto;
      position: relative;
      font-size: 16px;
      &--compared {
        border: 1px solid $color-slate;
        border-radius: 4px;
        width: 91%;
        height: 324px;
        margin-left: auto;
        position: relative;
        font-size: 16px;
      }
    }

    &__num {
      font-size: 160px;
      font-weight: 300;
      line-height: 0.8;
      background: $color-charcoal;
      text-align: center;
      color: $color-slate;
      position: absolute;
      bottom: -5px;
      padding: 0;
      margin: 0;
      left: -30px;
    }
  }

  &__title-text {
    font-size: 32px;
    font-family: $font-light;
    letter-spacing: -1.5px;
    line-height: 40px;
    display: block;
    margin-bottom: 20px;
  }

  &__button {
    border: 1px solid $color-white;
    background: $color-charcoal;
    color: $color-white;
    text-transform: capitalize;
    width: 85%;
    margin: 40px 0 30px 0;
    padding: 10px;
    font-size: 16px;
    display: block;
    text-align: center;
  }

  &__button-link {
    background: transparent;
    border: none;
    font-family: $font-regular;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.55px;
    color: $color-extra-light-gray;
    text-decoration: underline;
    padding: 0px;
  }

  &__right-side {
    display: flex;
    &--compared {
      display: flex;
    }
  }

  &__highlights {
    display: flex;
    padding-bottom: 30px;
    margin-top: 120px;
    margin-bottom: 30px;
    border-bottom: 1px solid $color-dark-gray;

    .highlights-lable {
      position: relative;
      width: 57%;
      color: $color-extra-light-gray;
      font-size: 16px;
      font-family: $font-regular;
    }

    .hightlights-switch {
      height: 26px;
      margin: auto;
    }
  }

  &__print-share-whislist {
    margin-bottom: 20px;
    .print-share-link {
      font-family: $font-light;
      font-size: 16px;
      color: $color-extra-light-gray;
      display: block;
      text-transform: capitalize;
      cursor: pointer;
      margin-bottom: 16px;
      width: fit-content;
      .label-name {
        display: inline-block;
        color: $color-white;
        font-size: 16px;
        font-family: $font-regular;
      }
      img {
        width: 20px !important;
        height: 20px !important;
        margin-right: 10px;
      }
    }
  }

  &__print-margin {
    margin-top: 20px !important;
  }

  .compare-card-item {
    position: relative;

    &__badge-text {
      font-family: $font-bold;
      font-size: 12px;
      color: $color-kohler-blue;
      position: absolute;
      left: 10px;
      top: 10px;
      background-color: $color-white;
      padding: 6px 12px;
      text-transform: uppercase;
      z-index: 1;
    }

    &__close-icon {
      position: absolute;
      color: #484848;
      right: 18px;
      top: 13px;
      z-index: 1;
      border: none;
      background: transparent;
    }

    &__image-container {
      height: 324px;
      &--compared {
        height: 324px;
      }
    }

    .compare-card-img {
      display: block;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

    &__skuid {
      font-family: $font-regular;
      font-size: 14px;
      color: $color-extra-light-gray;
      line-height: 22px;
      letter-spacing: -0.32px;
      margin: 20px 0 10px 0;
    }

    &__name {
      height: 22px;
      font-family: $font-regular;
      font-size: 16px;
      color: $color-white;
      line-height: 22px;
      margin-bottom: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      .highlight-text {
        font-family: $font-regular;
      }
    }

    &__description {
      height: 44px;
      font-family: $font-regular;
      font-size: 16px;
      color: $color-extra-light-gray;
      line-height: 22px;
      margin-bottom: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      letter-spacing: -0.55px;
    }

    &__price-section {
      min-height: 50px;
    }

    &__list-price {
      font-family: $font-bold;
      color: $color-white;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -1px;
      margin-bottom: 5px;
    }

    &__slashed-price {
      text-decoration: line-through;
      font-family: $font-regular;
      color: $color-extra-light-gray;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.8px;
    }

    &__cart-button {
      margin-top: 50px;
      font-family: $font-regular;
      font-size: 14px;
      letter-spacing: -0.25px;
      height: 40px;
      padding: 8px 31px;
      display: block;
      background-color: transparent;
      border: 1px solid $white;
      color: $white;
      text-align: center;
    }
  }
}

.compare-products__full-screen {
  background-color: $color-white;
  padding-bottom: 50px;
  .collapsible-accordion {
    padding-top: 40px;
    .Collapsible {
      border-bottom: none !important;
      &__trigger {
        border-bottom: 1px solid $color-dark-gray;
      }
      .accordion__header {
        display: flex;
        align-items: center;
        span {
          font-family: $font-light;
          font-size: 32px;
          line-height: 1.25;
          letter-spacing: -1.5px;
          color: $color-slate;
          z-index: 99;
        }
        img {
          transition: all 0.5s ease;
          margin-left: 20px;
          width: 15px;
          height: 10px;
          z-index: 99;
          cursor: pointer;
        }
      }
      &.collapsible--open {
        .accordion__header {
          img {
            transform: rotate(180deg);
          }
        }
      }
      &__contentInner {
        padding-top: 30px;
        padding-bottom: 0px;
      }
    }
    .compare-products__accordion-container {
      &:first-child {
        .compare-products__accordion-content {
          .compare-products__spec-comparison {
            .compare-products__spec-values {
              padding-top: 190px;
              margin-top: -190px;
            }
          }
        }
      }
      &:last-child {
        .compare-products__accordion-content {
          padding-bottom: 10px;
        }
      }
    }
    .compare-products__accordion-content {
      display: flex;
      font-family: $font-light;
      font-size: 20px;
      line-height: 1.4;
      letter-spacing: -0.65px;
      margin-bottom: 20px;
      padding-bottom: 20px;
      padding-top: 20px;

      &--highlighted {
        background: rgba(24, 104, 165, 0.05);
      }

      .compare-products__spec-header {
        padding-right: 20px;
        span {
          color: $color-dark-gray;
        }
      }
      .compare-products__spec-comparison {
        display: flex;
        .compare-products__spec-values {
          word-wrap: break-word;
          &:last-child {
            margin-right: 0;
          }
          padding-top: 60px;
          margin-top: -60px;
          z-index: 9;
          &.compare-col-hover {
            background-color: #1b1b1b07;
          }
          span {
            display: block;
            color: $color-charcoal;
            padding-bottom: 10px;
            a {
              text-decoration: underline;
            }
          }
          .compare-products__dimension-spec {
            padding-bottom: 30px;
            &:last-child {
              padding-bottom: 0px;
            }
          }
          .compare-products__color-finish {
            display: inline-block;
            width: 40px;
            height: 40px;
            border: solid 1px $color-light-gray;
            border-radius: 4px;
            margin-right: 5px;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1280px), screen and (max-height: 580px) {
  .compare-products {
    padding: 55px 0px 50px;
    &__right-side--compared {
      .product-box {
        margin-top: -55px;
        padding-top: 55px;
        margin-bottom: -50px;
        padding-bottom: 50px;
      }
    }
    &__overlay {
      .compare-products {
        padding: 40px 15px 0px !important;
      }
    }
  }
}
@media only screen and (min-width: 1263px) {
  .compare-products {
    padding: 55px 32px 50px !important;
    &__overlay {
      .compare-products {
        padding: 40px 32px 0px !important;
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .compare-products {
    &__left-side {
      &--compared {
        display: none;
      }
    }
  }
}

@media screen and (orientation: landscape) and (max-width: 990px),
  screen and (orientation: portrait) and (min-width: 767px) and (max-width: 1024px) {
  body.compare-open {
    position: fixed;
    left: 0;
    top: 0;
  }

  .compare-products__overlay {
    bottom: 0px;
    width: 100%;
    &--compared {
      top: 0px;
    }
  }

  .compare-products__overlay {
    .compare-products {
      padding: 10px 32px 15px;
    }
    &--compared {
      .compare-products {
        padding: 40px 32px 35px;
      }
    }
  }

  .compare-products__sticky-header--mobile {
    display: block;
    position: sticky;
    position: -webkit-sticky;
    z-index: 999;
    top: -1px;
    background-color: $color-charcoal;
    border-bottom: solid 1px $color-slate;
    padding: 15px 0px;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    color: $color-white;
    &__content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 32px;
    }
    &__left {
      display: flex;
      align-items: center;
    }
    &__title {
      font-family: $font-medium;
      line-height: 1.5;
      letter-spacing: -0.28px;
      margin-right: 30px;
    }
    &__differences {
      display: flex;
      align-items: center;
      padding: 0px 30px;
      margin-right: 30px;
      border-left: solid 1px $color-slate;
      border-right: solid 1px $color-slate;
      span {
        font-family: $font-light;
        letter-spacing: normal;
        margin-right: 10px;
      }
      .switch {
        margin-bottom: 0px;
      }
    }
    img {
      padding-right: 25px;
      &:last-child {
        padding-right: 0px;
      }
    }
    &__right {
      img {
        width: 16px;
        height: 16px;
      }
    }
  }

  .compare-products__sticky-header {
    padding: 10px 13px;
    top: 55px;
    &-content {
      display: block;
      .compare-products__sticky-collapse {
        display: none;
      }
    }
    &-differences {
      display: none;
    }
    &-details {
      max-width: 97%;
      margin: 0px 20px;
      .compare-products__sticky-header-product {
        margin: 0px;
        padding-left: 0px;
        padding-right: 20px;
        &:last-child {
          div {
            span:last-child {
              width: 140px;
            }
          }
        }
        div {
          span:first-child {
            display: none;
          }
          span:last-child {
            letter-spacing: normal;
          }
        }
      }
    }
  }

  .compare-products__button-link {
    text-align: left;
  }
  .compare-products {
    padding: 11px 32px 15px;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all ease 0.3s;
    &__container {
      height: 100%;
    }
    &__expand-container {
      &.--compared {
        display: none;
      }
    }

    &__half-screen-content {
      .compare-products__right-side {
        padding-left: 65px;
      }
    }

    &__expand-icon {
      text-align: center;
      padding-bottom: 20px;
      padding-right: 65px;
    }

    &__title-text {
      font-size: 26px;
      line-height: 32px;
      letter-spacing: -1.15px;
    }

    &__highlights {
      display: none;
    }

    &__print-share-whislist {
      display: none;
    }

    &__button {
      font-family: $font-light;
      font-size: 18px;
      padding: 5px 30px;
      margin: 20px 0;
      height: 42px;
      width: auto;
      line-height: 28px;
    }

    &__half-screen-content {
      &--compared {
        display: block;
      }
    }

    &__left-side {
      &--compared {
        display: none;
      }
    }

    &__right-side {
      .product-box {
        margin: 0px;
        padding: 0px 20px 0px 0px;
      }
      .compare-card-item {
        &__skuid {
          font-size: 10px;
          line-height: 14px;
          letter-spacing: 0.19px;
          margin: 10px 0px 0px;
        }

        &__name {
          font-size: 12px;
          line-height: 18px;
          margin-bottom: 0px;
          letter-spacing: 0px;
          height: 18px;
        }

        &__description {
          letter-spacing: -0.2px;
        }
      }
      &--compared {
        max-width: 92% !important;
        .product-box {
          margin: 0px 20px 0px 0px;
          padding: 0px;
          &:last-child {
            margin-right: 0px;
          }
        }
        .place-holder__box--compared {
          width: 80%;
        }
        &__skuid {
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.26px;
          margin: 10px 0px 15px;
        }

        &__name {
          font-size: 16px;
          line-height: 22px;
          margin-bottom: 5px;
          letter-spacing: 0px;
          height: 18px;
        }

        &__description {
          font-size: 16px;
          line-height: 22px;
          letter-spacing: -0.2px;
        }
      }
    }

    .compare-card-item__close-icon {
      right: 10px;
      top: 2px;
    }
    .product-box {
      &.compare-col-hover {
        background-color: inherit;
      }
    }

    .compare-card-item {
      &__badge-text {
        display: none;
      }

      &__image-container {
        height: 138px;
        &--compared {
          height: 228px;
        }
      }
      .compare-card-img {
        height: 100%;
      }

      &__content {
        display: none;
        &--compared {
          display: block;
        }
      }
      &__cart-button {
        margin-top: 25px;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: -0.2px;
        text-align: center;
      }
    }

    .place-holder {
      &__box {
        height: 138px;
        &--compared {
          height: 228px;
          .place-holder__num {
            font-size: 120px;
            line-height: 0.92;
            letter-spacing: -1.36px;
            left: -35px;
          }
        }
      }
      &__num {
        font-size: 60px;
        line-height: 1;
        letter-spacing: -4.1px;
        bottom: -3px;
        left: -20px;
      }
    }

    .compare-products__mobile-title {
      display: none;
    }
  }

  .compare-products__full-screen {
    padding-bottom: 50px;
    .collapsible-accordion {
      padding-top: 40px;
      .Collapsible {
        .accordion__header {
          span {
            margin-left: 32px;
            font-size: 26px;
            line-height: 1.23;
            letter-spacing: -1.15px;
          }
        }
        &__contentInner {
          padding: 0px;
        }
      }
      .compare-products__accordion-container {
        &:first-child {
          .compare-products__accordion-content {
            .compare-products__spec-comparison {
              .compare-products__spec-values {
                padding-top: 0px;
                margin-top: 0px;
              }
            }
          }
        }
        &:last-child {
          .compare-products__accordion-content {
            padding-bottom: 10px;
          }
        }
      }
      .compare-products__accordion-content {
        display: flex;
        flex-direction: column;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: normal;
        margin: 0px;
        padding-bottom: 30px;
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 0px;

        .compare-products__spec-header {
          max-width: 100%;
          background-color: $color-off-white;
          padding: 5px 32px;
          margin-bottom: 20px;
          span {
            color: $color-dark-gray;
          }
        }
        .compare-products__spec-comparison {
          max-width: 96%;
          margin: 0px 32px;
          .compare-products__spec-values {
            margin: 0px;
            padding-top: 0px;
            padding-left: 0px;
            padding-right: 20px;
            &.compare-col-hover {
              background-color: inherit;
            }
            .compare-products__dimension-spec {
              padding-bottom: 20px;
            }
            .compare-products__color-finish {
              width: 25px;
              height: 25px;
            }
          }
        }
      }
      &__skuid {
        font-size: 12px;
      }
      &__name {
        font-size: 12px;
        margin-bottom: 15px;
      }
      &__description {
        font-size: 13px;
        display: block;
      }
      &__price-section {
        font-size: 14px;
        display: block;
      }
      &__cart-button {
        font-size: 12px;
        display: block;
      }
    }
  }
}

@media screen and (orientation: landscape) and (max-width: 680px) {
  .compare-products__overlay {
    .compare-products {
      padding: 10px 32px 15px;
    }
    &--compared {
      .compare-products {
        padding: 40px 32px 35px;
      }
    }
  }

  .compare-products {
    &__sticky-header--mobile__title {
      margin-right: 0px;
    }

    &__sticky-header--mobile__differences {
      padding: 0px 15px;
      margin-right: 0px;
    }

    &__expand-icon {
      top: 0;
    }

    &__title-text {
      font-size: 18px;
    }

    &__button {
      font-size: 12px;
      padding: 4px 6px;
    }

    .product-box {
      margin-right: 8px;
    }
    .compare-card-item {
      &__image-container {
        height: 120px;
      }
      &__name {
        font-size: 12px;
      }
    }

    .compare-products__half-screen-content .compare-products__right-side {
      padding-left: 50px;
    }

    .place-holder {
      &__box {
        height: 120px;
      }
      &__num {
        font-size: 60px;
        bottom: -3px;
        left: -12px;
      }
    }

    .compare-products__mobile-title {
      display: none;
    }
  }
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .compare-products__container,
  .collapsed-widget-wrapper {
    display: none;
  }
}

@media print {
  .compare-card-item__image-container--compared,
  .compare-card-item__image-container--compared * {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
    color-adjust: exact !important; /*Firefox*/
  }
  .compare-open .root {
    display: none;
  }

  .compare-products__container {
    position: relative;
    height: auto;
    overflow: visible;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
      width: 0 !important;
    }
    .compare-card-item {
      .compare-card-item__cart-button {
        display: none;
      }
    }
  }

  .compare-products {
    padding: 40px;
    background: $color-white;
    &__overlay--compared {
      &.animate__fadeInUp {
        -webkit-animation-duration: initial;
        animation-duration: initial;
      }
    }
  }
  .compare-products .compare-card-item__name,
  .compare-products .compare-card-item__description,
  .compare-products .compare-card-item__list-price,
  .compare-products .compare-products__title,
  .compare-products .compare-card-item__content--compared {
    color: $color-black;
  }
  .compare-products .compare-card-item__skuid {
    color: $color-black;
    margin-top: -60px !important;
  }
  .compare-products .compare-card-item__cart-button {
    background: $color-white;
    color: $color-black;
    border: 1px solid $color-charcoal;
  }
  .compare-products .place-holder__box {
    border: 1px solid $color-slate;
  }
  .compare-products .place-holder__num {
    background: $color-white;
    color: $color-slate;
  }
  .compare-products__full-screen {
    padding: 50px;
  }
  .compare-products .compare-card-item .compare-card-img {
    height: 55% !important;
  }
  .compare-products__accordion-content {
    margin-top: 24px;
  }
  .compare-products__print-share-whislist,
  .compare-card-item__close-icon,
  .compare-products__highlights,
  .compare-products__button-link,
  .compare-products__expand-icon,
  .collapsed-widget,
  .accordion__header > img {
    display: none;
  }
  .compare-col-hover,
  .compare-col-hover > .compare-card-item__cart-button {
    background-color: transparent;
  }
}
