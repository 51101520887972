#main-content {
  opacity: 1 !important;
}

.cart-landing {
  @media screen and (min-width: 1024px) {
    .order-summary-cart {
      width: 21.6875rem;
    }

    &.kf-react-container {
      padding: 0 2.6rem !important;
      max-width: 20in !important;
    }

    .row-space-between {
      justify-content: space-between;
    }
  }

  @media screen and (min-width: 1600px) {
    .order-summary-cart {
      width: 21.6875rem;
      padding-right: 25px;
    }
    &.kf-react-container {
      padding: 0 3.125rem !important;
    }
  }

  @media screen and (min-width: 1920px) {
    .order-summary-cart {
      width: 24.6875rem;
      padding-right: 25px;
    }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1100px) {
    .order-summary-cart {
      width: 100%;
    }

    .cart-item-list-section {
      max-width: 100%;
      width: 100%;
    }
  }

  @media only screen and (min-width: 1100px) and (max-width: 1100px) {
    .order-summary-cart {
      width: 100%;
    }

    .cart-item-list-section {
      max-width: 69%;
    }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1100px) {
    .cart-item-list-section {
      max-width: 100%;
    }
  }

  @media only screen and (min-width: 1101px) and (max-width: 1176px) {
    .cart-item-list-section {
      max-width: 65%;
    }
  }

  @media only screen and (min-width: 1300px) and (max-width: 1599px) {
    .order-summary-cart {
      width: 21.6875rem;
    }

    .cart-item-list-section {
      max-width: 71%;
    }
  }

  margin-top: 55px;

  @media screen and (max-width: 1023px) {
    margin-top: 30px;
  }

  &__content {
    &.no-padding {
      padding: 0;
    }

    margin: 0 0 50px 0;

    @media screen and (max-width: 1060px) {
      margin: 0 0 30px 0;
    }
    @media screen and (min-width: 1600px) {
      padding-left: 10px;
    }
    .cart-items__td {
      font-size: 14px;
      line-height: 16.41px;
      letter-spacing: 1.27px;
      color: $color-slate;
    }
  }

  &__header {
    margin-bottom: 40px;
  }

  &__back {
    cursor: pointer;
    font-family: $font-regular;
    font-size: 20px;
    line-height: 1.4;
    letter-spacing: -0.65px;
    color: $color-dark-gray;
    display: inline-flex;
    align-items: center;

    img {
      margin-right: 20px;
    }
  }

  &__support {
    text-align: left;
    margin-left: 40px;

    div {
      margin-bottom: 10px;
      font-family: $font-light;
      font-size: 26px;
      line-height: 34px;
      letter-spacing: -1.55px;
      color: $color-slate;
    }

    a {
      cursor: pointer;
      font-family: $font-regular;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.55px;
      color: $color-dark-gray;
    }
  }

  @media only screen and (max-width: 1023px) {
    &__content {
      padding-right: 0;
    }
  }
}

.RemoveAbandonedCartConfirmationModal {
  &__wrapper {
    padding-inline: 50px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 500px;
  }

  &__title {
    color: $color-slate;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.818px;
    text-transform: uppercase;
    text-align: center;
  }

  &__ctas {
    display: flex;
    gap: 15px;

    & div {
      width: 100%;
    }
  }
}
