.shimmer-loading {
  &__title {
    height: 40px;
  }
  &__headerTitle {
    height: 110px;
  }

  &__title-h2 {
    height: 35px;
  }
  &__title-h3 {
    height: 30px;
  }
  &__title-h4 {
    height: 25px;
  }
  &__para {
    height: 20px;
    margin-bottom: 10px;
  }
  &__headpara {
    height: 40px;
  }
  &__para30 {
    height: 30px;
  }
  &__button {
    height: 40px;
    margin-bottom: 20px;
  }
  &__input {
    height: 60px;
    margin-bottom: 20px;
  }
  &__image {
    height: 100%;
    margin-bottom: 20px;
  }
  &__mt {
    margin-top: 10px;
  }
}
.mt42 {
  margin-top: 42px;
}
.borderNone {
  border: none !important;
}
.h74 {
  height: 74px;
}
.mt80 {
  margin-top: 80px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb200 {
  margin-bottom: 200px;
}
.mb60 {
  margin-bottom: 60px;
}
.mb15 {
  margin-bottom: 15px;
}
.mb40 {
  margin-bottom: 40px;
}
.mt60 {
  margin-top: 60px;
}
.height-60 {
  height: 60px;
}
.height-400 {
  height: 400px;
}
.height-fit-content {
  height: fit-content;
}
.width-full {
  width: 100%;
}
.width-140 {
  width: 140px;
}
.width-150 {
  width: 150px;
}
.width-200 {
  width: 200px;
}
.width-80 {
  width: 80px;
}
.width-100 {
  width: 100px;
}
.width-170 {
  width: 170px;
}
.width-250 {
  width: 250px;
}
.width-320 {
  width: 320px;
}
.width-300 {
  width: 300px;
}
.width-240 {
  width: 240px;
}
.width-90 {
  width: 90px;
}
.height-full {
  height: 100%;
}
.height-300 {
  height: 300px;
}
.p0 {
  padding: 0px;
}
.p10 {
  padding: 10px;
}
.mr-20 {
  margin-right: 20px;
}
.mb100 {
  margin-bottom: 100px;
}
.loadingHeight {
  height: 90px;
}
.inputBoxLoading {
  height: 150px;
}
.products__controls_shimmer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 65px;
  .width-140 {
    width: 140px;
  }
  .width-80 {
    width: 80px;
  }
}
.cartIcon::after {
  background: url(../../public/icons/cart-icon-empty.svg);
  content: "";
  width: 26px;
  height: 20px;
  top: 13px;
  position: absolute;
  display: inline-block;
}
.productonDisplay {
  border: 1px solid $color-light-gray;
  display: flex;
  padding: 20px;
  margin-top: 20px;
  @media only screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
  .left-side {
    min-width: 210px;
    font-family: $font-regular;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.55px;
    color: $color-dark-gray;
    text-align: left;
    @media only screen and (min-width: 1023px) and (max-width: 1299px) {
      max-width: 150px;
      min-width: 150px;
    }
  }
  .right-side {
    @media only screen and (max-width: 767px) {
      padding-left: 0px;
      padding-top: 10px;
    }
    &__title {
      font-family: $font-regular;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.55px;
      color: $color-dark-gray !important;
      margin-bottom: 10px;
      a {
        color: $color-dark-gray;
      }
    }
    &__address {
      font-family: $font-regular;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.55px;
      color: $color-dark-gray;
      margin-bottom: 10px;
      a {
        color: $color-dark-gray;
        text-decoration: underline;
      }
    }
  }
}
.purchase-design {
  border: 1px solid $color-light-gray;
  display: flex;
  padding: 20px;
  margin-top: 20px;
  @media only screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
  &__left-side {
    @media only screen and (max-width: 767px) {
      width: fit-content;
    }
    @media only screen and (min-width: 1023px) and (max-width: 1299px) {
      min-width: 150px;
    }
    min-width: 210px;
    font-family: $font-regular;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.55px;
    color: $color-dark-gray;
  }
  &__right-side {
    @media only screen and (max-width: 767px) {
      padding-top: 10px;
      padding-right: 10px;
      width: auto;
      padding-left: 0px;
    }
    .design-service {
      @media only screen and (max-width: 767px) {
        padding-bottom: 10px;
      }
      font-family: $font-regular;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.55px;
      color: $color-dark-gray;
      width: fit-content;
      text-decoration: underline;
      a {
        color: $color-dark-gray;
      }
    }
    .expert-design {
      font-family: $font-regular;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.55px;
      color: $color-dark-gray;
      margin-top: 10px;
      a {
        color: $color-dark-gray;
        text-decoration: underline;
      }
    }
  }
}
.add-installation-noservice {
  border: 1px solid $color-light-gray;
  margin-top: 20px;
  display: flex;
  padding: 20px;
  justify-content: space-between;
  @media only screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
  .noservice-title {
    font-family: $font-regular;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.55px;
    color: $color-dark-gray;
    min-width: 210px;
    p {
      color: $color-dark-gray;
    }
    @media screen and (max-width: 767px) {
      width: fit-content;
    }
    @media only screen and (min-width: 1023px) and (max-width: 1299px) {
      min-width: 150px;
    }
  }

  .noservice__right-side {
    @media only screen and (max-width: 767px) {
      padding-top: 10px;
      padding-left: 0px;
    }
    .header {
      font-family: $font-regular;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.55px;
      color: $color-dark-gray;
      margin-bottom: 10px;
      width: fit-content;
      p {
        color: $color-dark-gray;
      }
      a {
        color: $color-dark-gray;
      }
    }
    .desc {
      font-family: $font-regular;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.55px;
      color: $color-dark-gray;
      margin-bottom: 10px;
      p {
        color: $color-dark-gray;
      }
      a {
        color: $color-dark-gray;
      }
    }
  }
}
