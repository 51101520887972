.delivery-option {
  &__shipping-type-row {
    margin-top: 30px;
    @media screen and (max-width: 767px) {
      margin-top: 20px;
    }
  }
  &__shipping-title-heading {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.35px;
    color: $charcoal;
    font-family: $helvetica-medium;
    span {
      font-family: $helvetica-regular;
    }
  }
  &__title {
    font-family: $font-light;
    color: $color-slate;
    font-size: 60px;
    line-height: 1.13;
    letter-spacing: -3.1px;
    margin: 0;
  }
  &__alert-box {
    padding: 20px 25px;
    margin-top: 30px;
    border-radius: 5px;
    background-color: $color-dark-gray;
    &.--general {
      margin-top: 0;
      margin-bottom: 50px;
    }
  }
  &__alert-title {
    color: $color-white;
    font-family: $font-medium;
    font-size: 16px;
    letter-spacing: -0.35px;
    line-height: 24px;
  }
  &__alert-mssg {
    p {
      display: inline-block;
      color: $color-white;
      font-family: $font-regular;
      font-size: 16px;
      letter-spacing: -0.55px;
      line-height: 24px;
    }
    a {
      cursor: pointer;
      text-decoration: underline;
      color: $color-white;
      font-family: $font-regular;
      font-size: 16px;
      letter-spacing: -0.55px;
      line-height: 24px;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
        color: $color-white;
      }
    }
  }
  .option-title {
    padding: 25px 0 20px 0;
    @media screen and (max-width: 1023px) {
      padding: 15px 0 20px 0;
    }
  }
  &__row {
    .option-details {
      margin-bottom: 30px;
      @media screen and (max-width: 990px) {
        margin-bottom: 35px;
      }
      font-family: $font-regular;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.65px;
      color: $color-dark-gray;
    }
    .option-backOrderNote {
      margin: 30px 0 0 0;
      font-family: $font-regular;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.48px;
      color: $color-error-red;
      width: 420px;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }

  .active {
    .delivery-shipping-section {
      .delivery-shipping-type {
        &-name {
          color: $charcoal;
          font-family: $helvetica-medium;
        }
      }
      .delivery-charges {
        .amount {
          font-family: $font-medium;
          color: $charcoal;
        }
      }
    }
  }

  .delivery-shipping-section {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .delivery-shipping-type {
      display: flex;
      align-items: center;
      justify-content: center;
      label {
        margin: 0 10px 0 0;
      }
      &-name {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.3px;
        color: $slate;
        font-family: $helvetica-regular;
      }
      .radio__custom {
        border: 1px solid $dark-gray;
        border-radius: 10px;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        width: 20px;
        height: 20px;
      }
    }
  }
  &__freight-column {
    cursor: default;
    .delivery-date {
      font-family: $font-medium;
      font-size: 20px;
      line-height: 1.4;
      letter-spacing: -0.4px;
      color: $color-charcoal;
      margin-bottom: 10px;
    }

    .delivery-type {
      font-family: $font-regular;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.45px;
      color: $slate;
      margin: 5px 0 0 30px;
      &.no-radio {
        margin-left: 0;
      }
    }
    .fright-delivery-section {
      display: flex;
      justify-content: space-between;
      padding: 30px 0 0 0;
      @media screen and (max-width: 767px) {
        padding: 20px 0 0 0;
      }
      .delivery-charges {
        .amount {
          font-family: $font-medium;
          color: $charcoal;
          text-transform: uppercase;
        }
      }
    }
    .fright-delivery-type {
      &.delivery-type {
        margin: 5px 0 0 0;
        max-width: 33.375rem;
        &.professional-fright-delivery {
          margin: 30px 0 0 0;
          @media screen and (max-width: 767px) {
            margin: 20px 0 0 0;
          }
        }
      }
    }

    .delivery-charges {
      .amount {
        font-family: $font-regular;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.31px;
        color: $slate;
      }
    }
  }
  &__shipping-type-column {
    cursor: pointer;
    margin: 0 0 20px 0;
    &:last-child {
      margin: 0;
    }
    .delivery-date {
      font-family: $font-medium;
      font-size: 20px;
      line-height: 1.4;
      letter-spacing: -0.4px;
      color: $color-charcoal;
      margin-bottom: 10px;
    }

    .delivery-type {
      font-family: $font-regular;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.45px;
      color: $slate;
      margin: 5px 0 0 30px;
      &.no-radio {
        margin-left: 0;
      }
    }

    .delivery-charges {
      .amount {
        font-family: $font-medium;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.31px;
        color: $charcoal;
      }
    }
  }
  &__freight-column {
    width: 100%;
  }
  &__process-error,
  &__process-error p {
    text-align: right;
    margin-top: 20px;
    color: $color-error-red;
    font-family: $font-regular;
    font-size: 16px;
    letter-spacing: -0.55px;
    line-height: 22px;
  }
  &__submit-button {
    button {
      min-width: 251px;
      margin-top: 50px;
      height: 50px !important;
      @media only screen and (max-width: 1023px) {
        margin-top: 15px;
        width: 100%;
      }
      @media only screen and (max-width: 767px) {
        margin-top: 10px;
        width: 100%;
      }
    }
  }
  &__submit-button-sticky {
    button {
      min-width: 251px;
      margin-top: 50px;
      height: 50px !important;
      @media only screen and (max-width: 1023px) {
        margin-top: 15px;
        width: 100%;
      }
      @media only screen and (max-width: 767px) {
        margin-top: 0px;
        width: 100%;
      }
    }
  }
}

.checkout-cart-section {
  border: 1px solid $light-gray;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 20px 20px 30px 20px;
  @media only screen and (max-width: 767px) {
    padding: 15px 15px 25px 15px;
  }
  .delivery-option__row {
    .checkout-cart-section-lists {
      &:last-child .cart-item {
        border: none;
      }
    }
  }
  .combo-cart-items {
    .cart-item {
      border: unset;
      &__info {
        margin-top: 0;
      }
    }
  }
  .cart-item {
    border-top: unset;
    border-color: $light-gray;
    &__discount-price {
      margin: 0;
      letter-spacing: -0.55px;
      line-height: 22px;
    }
    &__display-discount {
      margin-top: 0;
    }
    &__label {
      color: $slate;
      line-height: 22px;
      letter-spacing: -0.55px;
    }
    @media only screen and (min-width: 767px) {
      padding: 25px 0 30px 0 !important;
    }
  }
  &.freight-section-checkout {
    margin: 20px 0;
    @media only screen and (min-width: 1023px) {
      &:last-child {
        margin-bottom: 0;
      }
    }
    @media only screen and (max-width: 1023px) {
      margin: 15px 0;
    }
    @media only screen and (max-width: 767px) {
      margin: 20px 0;
      .cart-item {
        padding: 30px 0 30px 0 !important;
      }
    }
    .cart-item {
      border-bottom: 1px solid $light-gray;
    }
    .option-backOrderNote {
      margin-bottom: 0;
    }
    @media only screen and (min-width: 1024px) and (max-width: 1600px) {
      .cart-item__cta-wrapper {
        margin: 0;
      }
    }
    .cart-item {
      padding: 25px 0 30px 0;
    }
  }
}
.type-info-email {
  font-family: $font-medium;
}
.delivery-option {
  .checkout-cart-section:nth-of-type(2) {
    margin-top: 0;
  }
}
